{
    "site_name": "PomBase",
    "database_name": "PomBase",
    "database_long_name": "The Schizosaccharomyces pombe genome database",
    "database_citation": "PMID:22039153",
    "logo_file_name": "pombase-logo.png",
    "small_logo_file_name": "pombase-logo-small.png",
    "tiny_logo_file_name": "pombase-logo-tiny.png",
    "header_background_file_name": "header-background.jpg",
    "funder": "Wellcome Trust",
    "site_description": "A comprehensive database for the fission yeast Schizosaccharomyces pombe, providing structural and functional annotation, literature curation and access to large-scale data sets",
    "welcome_message": "PomBase is a comprehensive knowledgebase for the fission yeast <i>Schizosaccharomyces pombe</i>, providing literature curation, analysis tools, genome annotation and access to large-scale data sets",
    "footer": {
        "funders": "PomBase is funded by the <a href=\"http://www.wellcome.ac.uk/\">Wellcome Trust</a>, run by a consortium comprising the <a href=\"http://www.cam.ac.uk/\">University of Cambridge</a>, <a href=\"http://www.ucl.ac.uk/\">University College London</a>, and hosted by the <a href=\"http://www.babraham.ac.uk\">Babraham Institute</a>.",
        "show_elixir_and_gbc_message": true,
        "logos": [
            {
                "url": "https://wellcome.ac.uk/",
                "image": "wellcome-logo-64px.png",
                "alt_text": "Wellcome Trust"
            },
            {
                "url": "https://www.sysbiol.cam.ac.uk/",
                "image": "sysbiol_logo-64px.png",
                "alt_text": "Cambridge Systems Biology Centre"
            },
            {
                "url": "https://www.cam.ac.uk/",
                "image": "cambridge-logo-64px.png",
                "alt_text": "University of Cambridge"
            },
            {
                "url": "https://www.ucl.ac.uk/",
                "image": "ucl_logo-64px.png",
                "alt_text": "University College London"
            },
            {
                "url": "https://elixir-europe.org/",
                "image": "elixir-logo-64px.png",
                "alt_text": "ELIXIR"
            },
            {
                "url": "https://globalbiodata.org/scientific-activities/global-core-biodata-resources/",
                "image": "GCBR-Logo-RGB-64px.png",
                "alt_text": "Global Core Biodata Resource"
            }
        ]
    },
    "load_organism_taxonid": 4896,
    "base_url": "https://www.pombase.org",
    "helpdesk_address": "helpdesk@pombase.org",
    "show_names_of_staff_curators": false,
    "show_names_of_staff_file_curators": false,
    "gene_systematic_identifier_re": "^SP[\\w\\d]+\\.\\d+[wc]?$",
    "transcript_systematic_identifier_re": "^SP[\\w\\d]+\\.\\d+[wc]?.\\d+$",
    "details_popup_delay": 500,
    "data_sources": {
        "interactions": [
            {
                "name": "BioGRID",
                "url": "https://thebiogrid.org/"
            }
        ]
    },
    "example_pages": {
        "gene": "SPCC18B5.03",
        "term": "GO:0000070",
        "reference": "PMID:25908789"
    },
    "social_media": {
        "twitter": "PomBase",
        "bluesky": "pombase.bsky.social",
        "mastodon": "https://mstdn.science/@pombase",
        "github": "pombase",
        "slack": "https://spombe.slack.com/",
        "facebook": "pombase",
        "linkedin": "https://www.linkedin.com/groups/5122686"
    },
    "has_disease_annotation": true,
    "has_unknowns_list": true,
    "has_characterisation_status": true,
    "news_on_front_page": true,
    "has_admin_curation": true,
    "no_gene_name_route": "/submit-data/gene-names",
    "ensembl_blast_url": "https://fungi.ensembl.org/Schizosaccharomyces_pombe/Tools/Blast",
    "jackhmmer_search_url": "https://www.ebi.ac.uk/Tools/hmmer/search/jackhmmer",
    "ebi_tools_url": "https://www.ebi.ac.uk/jdispatcher/sss",
    "pdbe_entry_url": "https://www.ebi.ac.uk/pdbe-srv/view/entry",
    "pdbe_image_url": "https://www.ebi.ac.uk/pdbe/static/entry/<<PDB_ID>>_deposited_chain_front_image-200x200.png",
    "rcsb_pdb_entry_url": "https://www.rcsb.org/structure",
    "seq_and_features_download_url": "https://curation.pombase.org/allele_qc/genome_region?systematic_id=<<IDENTIFIER>>&format=<<FORMAT>>&upstream=<<UPSTREAM_BASES>>&downstream=<<DOWNSTREAM_BASES>>",
    "canto_url": "https://curation.pombase.org/pombe",
    "canto_documentation_url": "https://pombase.github.io/canto_tutorial/",
    "community_mailing_list": {
        "url": "https://lists.cam.ac.uk/sympa/info/ucam-pombelist",
        "icon": "pombe_email_icon.png",
        "title": "S. pombe community mailing list"
    },
    "canto_data_config": {
        "browser_track_triage_types": [
            "Browser datasets, to host",
            "Browser datasets, hosted",
            "Transposon related",
            "Mating-type related",
            "DNA replication related",
            "DNA recombination related",
            "Mitochondrial sequence related"
        ]
    },
    "doc_page_aliases": {
        "/browse-curation/fission-yeast-go-slim-terms": "/browse-curation/fission-yeast-bp-go-slim-terms"
    },
    "nav_bar_config": {
        "nav_bar_menus": [
             {
                "header": "Overviews",
                "entries": [
                    {
                        "title": "GO molecular function overview",
                        "url": "/browse-curation/fission-yeast-mf-go-slim-terms"
                    },
                    {
                        "title": "GO biological process overview",
                        "url": "/browse-curation/fission-yeast-bp-go-slim-terms"
                    },
                    {
                        "title": "GO cellular component overview",
                        "url": "/browse-curation/fission-yeast-cc-go-slim-terms"
                    },
                    {
                        "title": "Fission yeast phenotype overview",
                        "url": "/browse-curation/fypo-slim"
                    },
                    {
                        "title": "Disease association overview",
                        "url": "/browse-curation/disease-slim"
                    },
                    {
                        "title": "All curated GO-CAM pathways",
                        "url": "/documentation/go-cam-pathway-models"
                    },
                    {
                        "title": "Drugs with known S. pombe targets",
                        "url": "/browse-curation/drugs-known-pombe-targets"
                    },
                    {
                        "title": "DNA binding site consensus sequences",
                        "url": "/browse-curation/dna-binding-sites"
                    },
                    {
                        "title": "Gene characterisation status",
                        "url": "/status/gene-characterisation"
                    },
                    {
                        "title": "Genome overview",
                        "url": "/status/genome-overview"
                    }
                ]
            },
            {
                "header": "Genome Status",
                "entries": [
                    {
                        "title": "Literature and curation metrics",
                        "url": "/metrics"
                    },
                    {
                        "title": "Genome sequence status",
                        "url": "/status/sequencing-status"
                    },
                    {
                        "title": "Genome updates",
                        "url": "/status/sequencing-updates"
                    },
                    {
                        "title": "Proten coding gene coordinate changes",
                        "url": "/status/gene-coordinate-changes-protein-coding"
                    },
                    {
                        "title": "RNA gene coordinate changes",
                        "url": "/status/gene-coordinate-changes-RNA"
                    },
                    {
                        "title": "New and removed genes",
                        "url": "/status/new-and-removed-genes"
                    },
                    {
                        "title": "Gene characterisation status",
                        "url": "/status/gene-characterisation"
                    },
                    {
                        "title": "Priority unstudied genes",
                        "url": "/status/priority-unstudied-genes"
                    }
                ]
            },
            {
                "header": "Downloads",
                "entries": [
                    {
                        "title": "Datasets",
                        "url": "/datasets"
                    },
                    {
                        "title": "Chado database dumps",
                        "url": "/downloads/chado-database-dumps"
                    }
                ]
            },
            {
                "header": "Community",
                "entries": [
                    {
                        "title": "Mailing list",
                        "url": "https://lists.cam.ac.uk/sympa/info/ucam-pombelist"
                    },
                    {
                        "title": "Community curation project",
                        "url": "/community/fission-yeast-community-curation-project"
                    },
                    {
                        "title": "News archive",
                        "url": "/news"
                    },
                    {
                        "title": "Fission yeast community",
                        "url": "/community"
                    },
                    {
                        "title": "Community curated publications",
                        "url": "/reference_list/community"
                    },
                    {
                        "title": "External resources",
                        "url": "/community/internet-resources"
                    }
                ]
            },
            {
                "header": "About",
                "entries": [
                    {
                        "title": "About PomBase",
                        "url": "/about"
                    },
                    {
                        "title": "Citing PomBase",
                        "url": "/about/citing-pombase"
                    },
                    {
                        "title": "Getting started",
                        "url": "/documentation/getting-started"
                    },
                    {
                        "title": "PomBase publications",
                        "url": "/about/pombase-and-collaborative-publications"
                    },
                    {
                        "title": "Scientific Advisory Board",
                        "url": "/about/pombase-sab"
                    },
                    {
                        "title": "Collaborations",
                        "url": "/about/collaborations"
                    },
                    {
                        "title": "Literature and curation metrics",
                        "url": "/metrics"
                    },
                    {
                        "title": "Community curation stats",
                        "url": "https://curation.pombase.org/pombe/stats/annotation"
                    },
                    {
                        "title": "Terms of use",
                        "url": "/about/terms-of-use"
                    }
                ]
            }
        ]
    },
    "sequence_feature_page": {
        "so_types_to_show": [
            "regional_centromere",
            "regional_centromere_central_core"
        ]
    },
    "default_jbrowse_tracks": [
        {
            "pmed_id": "PMID:11859360",
            "label": "DNA sequence"
        },
        {
            "pmed_id": "PMID:11859360",
            "label": "Forward strand features"
        },
        {
            "pmed_id": "PMID:11859360",
            "label": "Reverse strand features"
        }
    ],
    "reference_page_extra_links": {
        "PMID:25373780": [
            {
                "link": "https://idr.openmicroscopy.org/webclient/?show=screen-3",
                "text": "Access the microscopy data in Image Data Resource."
            }
        ],
        "PMID:36257942": [
            {
                "link": "https://butterlab.imb-mainz.de/SpProtQuant/",
                "text": "View the changes in proteome resulting from deleting a certain coding gene, or the changes in protein levels of a given coding gene in all strains deleted for coding genes."
            }
        ],
        "PMID:31364709": [
            {
                "link": "https://www.pombase.org/jbrowse/?loc=mitochondrial%3A1..19433",
                "text": "View the mitochondrial chromosome sequence in the PomBase genome browser ..."
            }
        ],
        "PMID:34296454": [
            {
                "link": "https://tmak.shinyapps.io/TM_App-Table_EV3/",
                "text": "Time-dependent changes can be viewed using the accompanying application"
            }
        ],
        "PMID:34017941":   [
            {
                "link": "https://data.caltech.edu/records/tfcg1-zdc84",
                "text": "Mitochondrial variant calls for all samples used in the study (vcf format)"
            },
            {
                "link": "https://data.caltech.edu/records/4grkw-d1474",
                "text": "Genotype information and meta-data for all samples used in the study (csv format)"
            }
        ]
    },
    "reference_page_jbrowse_columns": [
        {
            "name": "label",
            "display_name": "Label"
        },
        {
            "name": "growth_phase_or_response",
            "display_name": "Growth phase or response"
        },
        {
            "name": "assayed_gene_product",
            "display_name": "Localized gene product"
        },
        {
            "name": "background",
            "display_name": "Background"
        },
        {
            "name": "mating_type",
            "display_name": "Mating type"
        },
        {
            "name": "conditions",
            "display_name": "Conditions"
        },
        {
            "name": "assay_type",
            "display_name": "Assay type"
        },
        {
            "name": "data_type",
            "display_name": "Data type"
        },
        {
            "name": "alleles",
            "display_name": "WT or mutant"
        },
        {
            "name": "mutants",
            "display_name": "Mutant(s)"
        },
        {
            "name": "comment",
            "display_name": "Comment"
        },
        {
            "name": "source_url",
            "display_name": "",
            "download_link": true
        }
    ],
    "gene_page_extra_links": {},
    "feature_location_warnings": [
        {
            "feature_types": ["lncRNA", "sncRNA"],
            "warning": "Many lncRNAs are cryptic and the functions (if any) are mostly unknown. RNA-seq cannot precisely map transcription start and termination sites, hence features are currently approximate. Most lncRNAs in fission yeast are induced in certain conditions, like non-dividing cells and meiotic differentiation. In proliferating cells, most lncRNAs are suppressed by RNA-processing pathways. For more details on the relevance of a specific RNA please follow the link to the source(s) (adjacent). You can also inspect individual datasets as tracks in JBrowse."
        }
    ],
    "alphafold_max_protein_length": 2700,
    "jbrowse_track_picker_base_url": "/jbrowse/?loc=I:1..5579133&tracks=",
    "search_box_cv_names": [
        "molecular_function",
        "biological_process",
        "cellular_component",
        "fission_yeast_phenotype",
        "sequence",
        "mondo"
    ],
    "search_box_config": {
        "suffixes_to_trim": [
            "\\\\.1",
            "\\\\.1:pep"
        ],
        "ortholog_organisms_to_always_show": [4932, 9606]
    },
    "feature_sub_groups": {
        "rRNA gene": [
            "ncRNA gene"
        ],
        "tRNA gene": [
            "ncRNA gene"
        ],
        "snRNA gene": [
            "ncRNA gene"
        ],
        "snoRNA gene": [
            "ncRNA gene"
        ],
        "lncRNA gene": [
            "ncRNA gene"
        ],
        "sncRNA gene": [
            "ncRNA gene"
        ]
    },
    "query_builder": {
        "node_types": [
            {
                "id": "canned_queries",
                "displayName": "Commonly used queries",
                "nodeType": "canned-queries",
                "topDescription": [
                    {
                        "text": "Shortcuts for commonly used queries"
                    }
                ]
            },
            {
                "id": "gene_list_queries",
                "displayName": "Gene list queries",
                "nodeType": "heading",
                "topDescription": [],
                "subNodes": [
                    {
                        "id": "gene_list",
                        "displayName": "Gene name and IDs",
                        "nodeType": "gene-list",
                        "topDescription": [
                            {
                                "text": "Enter or upload a list of systematic gene IDs or gene names"
                            }
                        ]
                    },
                    {
                        "id": "uniprot_id_list",
                        "displayName": "UniProt accessions",
                        "nodeType": "gene-list",
                        "topDescription": [
                            {
                                "text": "UniProt accession mapping has moved"
                            }
                        ],
                        "extraResultTableColumns": [
                            "uniprot_identifier"
                        ]
                    }
                ]
            },
            {
                "id": "ontology_queries",
                "displayName": "Ontology queries",
                "nodeType": "heading",
                "topDescription": [],
                "subNodes": [
                    {
                        "id": "GO",
                        "displayName": "GO",
                        "nodeType": "ontology",
                        "ontologyName": "GO",
                        "topDescription": [
                            {
                                "text": "Retrieve genes via annotated Gene Ontology terms that describe the activity, process, or location of their products (protein or ncRNA)"
                            }
                        ]
                    },
                    {
                        "id": "FYPO",
                        "displayName": "phenotype",
                        "nodeType": "ontology",
                        "ontologyName": "FYPO",
                        "annotationFeatureType": "genotype",
                        "topDescription": [
                            {
                                "text": "Retrieve genes based on mutant phenotypes annotated using Fission Yeast Phenotype Ontology (FYPO)"
                            }
                        ],
                        "phenotypeConditionNamespace": "fission_yeast_phenotype_condition"
                    },
                    {
                        "id": "disease_associated",
                        "displayName": "disease association",
                        "nodeType": "ontology",
                        "ontologyName": "mondo",
                        "topDescription": [
                            {
                                "text": "Retrieve genes for which the curated human ortholog is associated with a disease. To find all disease gene orthologs, search for \"disease\""
                            }
                        ]
                    }
                ]
            },
            {
                "id": "gene_product_queries",
                "displayName": "Gene product queries",
                "nodeType": "heading",
                "topDescription": [],
                "subNodes": [
                    {
                        "id": "product_type",
                        "displayName": "product type",
                        "nodeType": "subset-select",
                        "subsets": [
                            {
                                "name": "feature_type_mRNA_gene",
                                "displayName": "feature_type:protein"
                            },
                            {
                                "name": "feature_type_pseudogene",
                                "displayName": "feature_type:pseudogene"
                            },
                            {
                                "name": "feature_type_ncRNA_gene",
                                "displayName": "feature_type:ncRNA (all non coding)"
                            },
                            {
                                "name": "feature_type_lncRNA_gene",
                                "displayName": "feature_type:lncRNA"
                            },
                            {
                                "name": "feature_type_rRNA_gene",
                                "displayName": "feature_type:rRNA"
                            },
                            {
                                "name": "feature_type_snRNA_gene",
                                "displayName": "feature_type:snRNA"
                            },
                            {
                                "name": "feature_type_sncRNA_gene",
                                "displayName": "feature_type:sncRNA"
                            },
                            {
                                "name": "feature_type_snoRNA_gene",
                                "displayName": "feature_type:snoRNA"
                            },
                            {
                                "name": "feature_type_tRNA_gene",
                                "displayName": "feature_type:tRNA"
                            }
                        ],
                        "topDescription": [
                            {
                                "text": "Retrieve genes based on product type. Note that \"protein\" includes dubious and transposon protein-coding genes; there is a prepared query that excludes both in the list of commonly used queries"
                            }
                        ]
                    },
                    {
                        "id": "PSI-MOD",
                        "displayName": "protein modification",
                        "nodeType": "ontology",
                        "ontologyName": "PSI-MOD",
                        "placeholder": "e.g phosphorylated residue (MOD:00696)",
                        "topDescription": [
                            {
                                "text": "Retrieve protein-coding genes based on modifications annotated using PSI-MOD ontology terms"
                            }
                        ]
                    },
                    {
                        "id": "domains_and_features",
                        "displayName": "domain, features and motifs",
                        "nodeType": "sub-nodes",
                        "topDescription": [
                            {
                                "text": "Retrieve protein domains, features and motifs"
                            }
                        ],
                        "subNodes": [
                            {
                                "id": "SO_protein",
                                "displayName": "protein features",
                                "nodeType": "ontology",
                                "ontologyName": "SO-protein",
                                "topDescription": [
                                    {
                                        "text": "Retrieve protein-coding genes based on features annotated using the protein sequence feature branch of the Sequence Ontology (SO)"
                                    }
                                ],
                                "extraDescription": "Examples: short motifs such as NLS or KEN box, signal peptide, mitochondrial targeting signal, cleaved region",
                                "extraDescription2": "Use \"sequence feature\" to retrieve all genes with annotated sequence features"
                            },
                            {
                                "id": "all_domains",
                                "displayName": "Domain ID",
                                "nodeType": "subset-input",
                                "subsetPrefix": null,
                                "placeholder": "eg. PF02373 or IPR001138",
                                "topDescription": [
                                    {
                                        "text": "Retrieve protein-coding genes by specific domains present in the products. To retrieve all domain-containing proteins, search for \"IPR*\" or \"PF*\""
                                    }
                                ]
                            },
                            {
                                "id": "tm_domain_count",
                                "displayName": "TM domains",
                                "nodeType": "int-range",
                                "range_minimum": 0,
                                "range_any_and_none": true,
                                "topDescription": [
                                    {
                                        "text": "Retrieve protein-coding genes by number of predicted transmembrane domains in the product"
                                    }
                                ]
                            },
                            {
                                "id": "coiled_coils_count",
                                "displayName": "coiled-coil regions",
                                "nodeType": "int-range",
                                "range_minimum": 0,
                                "range_any_and_none": true,
                                "percent_query_id": "coiled_coils_percent",
                                "percent_display_name": "percentage of residues in coiled coil regions",
                                "topDescription": [
                                    {
                                        "text": "Retrieve protein-coding genes by number of predicted coiled-coil domains in the product or by percentage of residues in coiled-coil regions"
                                    }
                                ]
                            },
                            {
                                "id": "disordered_regions_count",
                                "displayName": "disordered regions",
                                "nodeType": "int-range",
                                "range_minimum": 0,
                                "range_any_and_none": true,
                                "percent_query_id": "disordered_percent",
                                "percent_display_name": "percentage of residues in disordered regions",
                                "topDescription": [
                                    {
                                        "text": "Retrieve protein-coding genes by number of disordered regions in the product or by percentage of residues in disordered regions"
                                    }
                                ]
                            },
                            {
                                "id": "low_complexity_regions_count",
                                "displayName": "low complexity regions",
                                "nodeType": "int-range",
                                "range_minimum": 0,
                                "range_any_and_none": true,
                                "percent_query_id": "low_complexity_percent",
                                "percent_display_name": "percentage of residues in low complexity regions",
                                "topDescription": [
                                    {
                                        "text": "Retrieve protein-coding genes by number of low complexity regions in the product or by percentage of residues in low complexity regions"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": "protein_properties",
                        "displayName": "protein properties",
                        "nodeType": "sub-nodes",
                        "subsetPrefix": null,
                        "topDescription": [
                            {
                                "text": "Retrieve protein properties"
                            }
                        ],
                        "subNodes": [
                            {
                                "id": "protein_length",
                                "displayName": "protein length",
                                "nodeType": "int-range",
                                "range_minimum": 1,
                                "range_any_and_none": false,
                                "topDescription": [
                                    {
                                        "text": "Retrieve protein-coding genes by product length (in amino acids)"
                                    }
                                ]
                            },
                            {
                                "id": "protein_mol_weight",
                                "displayName": "protein mol. weight",
                                "nodeType": "float-range",
                                "topDescription": [
                                    {
                                        "text": "Retrieve protein-coding genes by product molecular weight in kDa"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": "transcript_structure_properties",
                        "displayName": "Transcripts and exons",
                        "nodeType": "sub-nodes",
                        "subsetPrefix": null,
                        "topDescription": [
                            {
                                "text": "Transcript and exons properties"
                            }
                        ],
                        "subNodes": [
                            {
                                "id": "exon_count",
                                "displayName": "number of exons",
                                "nodeType": "int-range",
                                "range_minimum": 1,
                                "range_any_and_none": false,
                                "topDescription": [
                                    {
                                        "text": "Retrieve genes by number of exons in the primary transcript"
                                    }
                                ],
                                "node_options_title": "Consider only",
                                "node_options": [
                                    {
                                        "display_name": "coding exons",
                                        "id": "coding-exons"
                                    },
                                    {
                                        "display_name": "5' UTR exons",
                                        "id": "five-prime-utr-exons"
                                    },
                                    {
                                        "display_name": "3' UTR exons",
                                        "id": "three-prime-utr-exons"
                                    }
                                ]
                            },
                            {
                                "id": "transcript_count",
                                "displayName": "Number of transcripts",
                                "nodeType": "int-range",
                                "range_minimum": 1,
                                "range_any_and_none": false,
                                "topDescription": [
                                    {
                                        "text": "Retrieve genes by number of transcript isoforms"
                                    }
                                ]
                            },
                            {
                                "id": "spliced_rna_length",
                                "displayName": "Spliced RNA length",
                                "nodeType": "int-range",
                                "range_minimum": 1,
                                "range_any_and_none": false,
                                "topDescription": [
                                    {
                                        "text": "Retrieve genes by spliced RNA length in nucleotides (not including UTRs for protein coding genes)"
                                    }
                                ]
                            },
                            {
                                "id": "unspliced_rna_length",
                                "displayName": "Unspliced RNA length",
                                "nodeType": "int-range",
                                "range_minimum": 1,
                                "range_any_and_none": false,
                                "topDescription": [
                                    {
                                        "text": "Retrieve genes by un-spliced RNA length in nucleotides (not including UTRs for protein coding genes)"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": "genome_overview_queries",
                "displayName": "Genome overview queries",
                "nodeType": "heading",
                "subNodes": [
                    {
                        "id": "genome_range",
                        "displayName": "Genome location",
                        "nodeType": "genome-range",
                        "topDescription": [
                            {
                                "text": "Retrieve genes in a specified region of a chromosome"
                            }
                        ]
                    },
                    {
                        "id": "orthologs-and-conservation",
                        "nodeType": "sub-nodes",
                        "displayName": "Orthologs and conservation",
                        "topDescription": [
                            {
                                "text": "Orthologs and conservation"
                            }
                        ],
                        "subNodes": [
                            {
                                "id": "taxonomic_conservation",
                                "displayName": "taxonomic conservation",
                                "nodeType": "small-ontology",
                                "terms": [
                                    {
                                        "name": "Schizosaccharomyces pombe specific",
                                        "termid": "PBO:0016878"
                                    },
                                    {
                                        "name": "Schizosaccharomyces specific",
                                        "termid": "PBO:0016885"
                                    },
                                    {
                                        "name": "conserved in archaea",
                                        "termid": "PBO:0011072"
                                    },
                                    {
                                        "name": "conserved in bacteria",
                                        "termid": "PBO:0011067"
                                    },
                                    {
                                        "name": "conserved in eukaryotes",
                                        "termid": "PBO:0011065"
                                    },
                                    {
                                        "name": "conserved in eukaryotes only",
                                        "termid": "PBO:0011071"
                                    },
                                    {
                                        "name": "conserved in fungi",
                                        "termid": "PBO:0011064"
                                    },
                                    {
                                        "name": "conserved in fungi only",
                                        "termid": "PBO:0011063"
                                    },
                                    {
                                        "name": "conserved in metazoa",
                                        "termid": "PBO:0011069"
                                    },
                                    {
                                        "name": "conserved in vertebrates",
                                        "termid": "PBO:0011070"
                                    },
                                    {
                                        "name": "faster evolving duplicate",
                                        "termid": "PBO:0009798"
                                    },
                                    {
                                        "name": "no apparent S. cerevisiae ortholog",
                                        "termid": "PBO:0000055"
                                    },
                                    {
                                        "name": "orthologs cannot be distinguished",
                                        "termid": "PBO:0000110"
                                    },
                                    {
                                        "name": "predominantly single copy (one to one)",
                                        "termid": "PBO:0006222"
                                    }
                                ],
                                "topDescription": [
                                    {
                                        "text": "Retrieve protein-coding genes based on broad categories of taxonomic conservation"
                                    }
                                ]
                            },
                            {
                                "id": "has_ortholog",
                                "displayName": "protein with orthologs",
                                "nodeType": "has-ortholog",
                                "topDescription": [
                                    {
                                        "text": "Retrieve proteins that have an ortholog in"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": "characterisation_status",
                        "displayName": "characterisation status",
                        "nodeType": "subset-select",
                        "subsets": [
                            {
                                "name": "characterisation_status_biological_role_published",
                                "displayName": "characterisation_status:biological role published"
                            },
                            {
                                "name": "characterisation_status_transposon",
                                "displayName": "characterisation_status:transposon"
                            },
                            {
                                "name": "characterisation_status_dubious",
                                "displayName": "characterisation_status:dubious"
                            },
                            {
                                "name": "characterisation_status_Schizosaccharomyces_specific_protein_uncharacterized",
                                "displayName": "characterisation_status:Schizo. specific protein, uncharacterized"
                            },
                            {
                                "name": "characterisation_status_Schizosaccharomyces_pombe_specific_protein_uncharacterized",
                                "displayName": "characterisation_status:S. pombe specific protein, uncharacterized"
                            },
                            {
                                "name": "characterisation_status_biological_role_inferred",
                                "displayName": "characterisation_status:biological role inferred"
                            },
                            {
                                "name": "characterisation_status_conserved_unknown",
                                "displayName": "characterisation_status:conserved unknown"
                            }
                        ],
                        "topDescription": [
                            {
                                "text": "Retrieve protein-coding genes based on "
                            },
                            {
                                "link": {
                                    "link_text": "characterisation status",
                                    "route": "/status/gene-characterisation"
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "organisms": [
        {
            "taxonid": 4896,
            "genus": "Schizosaccharomyces",
            "species": "pombe",
            "common_name": "S. pombe",
            "alternative_names": [
                "fission yeast"
            ],
            "assembly_version": "ASM294v2",
            "example_gene_identifiers": [
                "cdc11",
                "SPCC18B5.03"
            ]
        },
        {
            "taxonid": 4932,
            "genus": "Saccharomyces",
            "species": "cerevisiae",
            "alternative_names": [
                "brewer's yeast",
                "baker's yeast",
                "budding yeast"
            ],
            "common_name": "S. cerevisiae",
            "example_gene_identifiers": [
                "CDC28",
                "YBR160W"
            ],
            "database_id_prefix": "SGD"
        },
        {
            "taxonid": 4897,
            "genus": "Schizosaccharomyces",
            "species": "japonicus",
            "alternative_names": [],
            "common_name": "S. japonicus",
            "example_gene_identifiers": [
                " mid1",
                "SJAG_01143"
            ],
            "database_id_prefix": "JaponicusDB"
        },
        {
            "taxonid": 9606,
            "genus": "Homo",
            "species": "sapiens",
            "alternative_names": [],
            "common_name": "human",
            "example_gene_identifiers": [
                "CDK1",
                "HGNC:1722"
            ],
            "database_id_prefix": "HGNC"
        }
    ],
    "ortholog_taxonids": [
        4932,
        4897,
        9606
    ],
    "front_page_panels": [
        {
            "title": "Rodríguez-López et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-10-04-PMID37787768.png"
            ],
            "content": "Broad functional profiling of fission yeast proteins using phenomics and machine learning.\n\nOriginally published in <a href='https://doi.org/10.7554/eLife.88229'>Elife</a>.",
            "reference_id": "PMID:37787768",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-10-04",
            "show_on_front_page": false
        },
        {
            "title": "Bellingham-Johnstun et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-07-04-PMID37099380.png"
            ],
            "content": "Microtubule competition and cell growth recenter the nucleus after anaphase in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1091/mbc.E23-01-0034'>Mol Biol Cell</a>.",
            "reference_id": "PMID:37099380",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-07-04",
            "show_on_front_page": false
        },
        {
            "title": "Bellingham-Johnstun et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-07-03-PMID36980258.png"
            ],
            "content": "Actin-Microtubule Crosstalk Imparts Stiffness to the Contractile Ring in Fission Yeast.\n\nOriginally published in <a href='https://doi.org/10.3390/cells12060917'>Cells</a>.",
            "reference_id": "PMID:36980258",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-07-03",
            "show_on_front_page": false
        },
        {
            "title": "Takenaka et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-05-15-PMID37052630.png"
            ],
            "content": "Tfs1, transcription elongation factor TFIIS, has an impact on chromosome segregation affected by pka1 deletion in Schizosaccharomyces pombe.\n\nOriginally published in <a href='https://doi.org/10.1007/s00294-023-01268-0'>Curr Genet</a>.",
            "reference_id": "PMID:37052630",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-05-15",
            "show_on_front_page": false
        },
        {
            "title": "Sanchez et al, 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-04-18-PMID30355770.png"
            ],
            "content": "RNA polymerase II CTD interactome with 3' processing and termination factors in fission yeast and its impact on phosphate homeostasis.\n\nOriginally published in <a href='https://doi.org/10.1073/pnas.1810711115'>Proc Natl Acad Sci U S A</a>.",
            "reference_id": "PMID:30355770",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-04-18",
            "show_on_front_page": false
        },
        {
            "title": "Sanchez et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-04-18-PMID36882296.png"
            ],
            "content": "Duf89 abets lncRNA control of fission yeast phosphate homeostasis via its antagonism of precocious lncRNA transcription termination.\n\nOriginally published in <a href='https://doi.org/10.1261/rna.079595.123'>RNA</a>.",
            "reference_id": "PMID:36882296",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-04-18",
            "show_on_front_page": false
        },
        {
            "title": "Murase et al, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-04-18-PMID36435910.png"
            ],
            "content": "Fission yeast Dis1 is an unconventional TOG/XMAP215 that induces microtubule catastrophe to drive chromosome pulling.\n\nOriginally published in <a href='https://doi.org/10.1038/s42003-022-04271-2'>Commun Biol</a>.",
            "reference_id": "PMID:36435910",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-04-18",
            "show_on_front_page": false
        },
        {
            "title": "Sanchez et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-04-18-PMID36820394.png"
            ],
            "content": "Inorganic polyphosphate abets silencing of a sub-telomeric gene cluster in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.17912/micropub.biology.000744'>MicroPubl Biol</a>.",
            "reference_id": "PMID:36820394",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-04-18",
            "show_on_front_page": false
        },
        {
            "title": "Bhattacharjee et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-03-29-PMID36749320.png"
            ],
            "content": "Multiple polarity kinases inhibit phase separation of F-BAR protein Cdc15 and antagonize cytokinetic ring assembly in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.7554/eLife.83062'>Elife</a>.",
            "reference_id": "PMID:36749320",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-03-29",
            "show_on_front_page": false
        },
        {
            "title": "Andric et al, 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-03-27-PMID33536434.png"
            ],
            "content": "A scaffold lncRNA shapes the mitosis to meiosis switch.\n\nOriginally published in <a href='https://doi.org/10.1038/s41467-021-21032-7'>Nat Commun</a>.",
            "reference_id": "PMID:33536434",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-03-27",
            "show_on_front_page": false
        },
        {
            "title": "Mahrik et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-03-08-PMID36793083.png"
            ],
            "content": "The SAGA histone acetyltransferase module targets SMC5/6 to specific genes.\n\nOriginally published in <a href='https://doi.org/10.1186/s13072-023-00480-z'>Epigenetics Chromatin</a>.",
            "reference_id": "PMID:36793083",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-03-08",
            "show_on_front_page": false
        },
        {
            "title": "Pham et al., 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-02-08-Pham-palmitoylation.png"
            ],
            "content": "Protein S-palmitoylation regulates different stages of meiosis in Schizosaccharomyces pombe.\n\nOriginally published in <a href='https://doi.org/10.26508/lsa.202201755'>Life Science Alliance</a>.",
            "reference_id": "PMID:36650056",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-02-08",
            "show_on_front_page": false
        },
        {
            "title": "Chacko et al., 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-02-08-Chacko-mitochondria-michrotubule.png"
            ],
            "content": "Microtubule-mitochondrial attachment facilitates cell division symmetry and mitochondrial partitioning in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1242/jcs.260705'>Journal of cell Science</a>.",
            "reference_id": "PMID:36633091",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-02-08",
            "show_on_front_page": false
        },
        {
            "title": "Ohtsuka et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-07-14-Hokuto-Tschimganine.png"
            ],
            "content": "Tschimganine has different targets for chronological lifespan extension and growth inhibition in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1093/bbb/zbac051'>Bioscience, Biotechnology, and Biochemistry</a>.",
            "reference_id": "PMID:35416247",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-07-14",
            "show_on_front_page": false
        },
        {
            "title": "McDonald et al., 2017",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "/media/2017-10-06-gould.mp4"
            ],
            "content": "Contractile ring structure at nanoscale resolution. Image from [eLife 2017;6:e28865](https://elifesciences.org/articles/28865#video1), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:28914606",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2017-10-06",
            "show_on_front_page": false
        },
        {
            "title": "Bestul et al., 2017",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-01-22-jaspersen-centrosome.png"
            ],
            "content": "Molecular model of fission yeast centrosome assembly determined by superresolution imaging.\n\n Originally published in [Journal of Cell Biology](http://jcb.rupress.org/content/216/8/2409).",
            "reference_id": "PMID:28619713",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-01-22",
            "show_on_front_page": false
        },
        {
            "title": "Kim et al., 2017",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "/media/2018-01-24-j.k.kim.mp4"
            ],
            "content": "Structural Basis for Shelterin Bridge Assembly.\n\n Originally published in [Molecular Cell](http://www.cell.com/molecular-cell/fulltext/S1097-2765%2817%2930809-2).",
            "reference_id": "PMID:29149597",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-01-24",
            "show_on_front_page": false
        },
        {
            "title": "Murayama et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-04-20-murayama-cohesin-establishment.png"
            ],
            "content": "Study shows second ssDNA capture by the SMC complex during establishment of sister chromatid cohesion.\n\n Originally published in <a href='http://www.cell.com/cell/fulltext/S0092-8674(17)31501-5'>Cell</a>.",
            "reference_id": "PMID:29358048",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-04-20",
            "show_on_front_page": false
        },
        {
            "title": "Yukawa et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-06-13-toda-kinesin-14.png"
            ],
            "content": "Model illustrating collaborative inward force generation by kinesin-14s.\n\n Originally published in <a href='http://jcs.biologists.org/content/131/1/jcs210740.long'>Journal of Cell Science</a>.",
            "reference_id": "PMID:29167352",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-06-13",
            "show_on_front_page": false
        },
        {
            "title": "Thodberg et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-06-14-thodberg-cage.png"
            ],
            "content": "CAGE-defined Transcription Start Sites across 5 different conditions.\n\n Originally published in the <a href='https://academic.oup.com/nar/article/47/4/1671/5253058'>Nucleic Acids Research</a>.",
            "link": "https://www.pombase.org/jbrowse/?loc=II%3A2002310..2015112&tracks=PomBase%20forward%20strand%20features%2CDNA%20sequence%2CPomBase%20reverse%20strand%20features%2CConsensus%20transcription%20start%20sites%20-%20Thodberg%20et%20al.%20(2018)%2CTranscription%20start%20sites%20in%20YES%20(forward%20strand)%20-%20Thodberg%20et%20al.%20(2018)%2CTranscription%20start%20sites%20during%20oxidative%20stress%20(forward%20strand)%20-%20Thodberg%20et%20al.%20(2018)%2CTranscription%20start%20sites%20during%20heat%20shock%20(reverse%20strand)%20-%20Thodberg%20et%20al.%20(2018)%2CTranscription%20start%20sites%20during%20nitrogen%20starvation%20(forward%20strand)%20-%20Thodberg%20et%20al.%20(2018)%2CTranscription%20start%20sites%20during%20nitrogen%20starvation%20(reverse%20strand)%20-%20Thodberg%20et%20al.%20(2018)%2CTranscription%20start%20sites%20during%20heat%20shock%20(forward%20strand)%20-%20Thodberg%20et%20al.%20(2018)%2CTranscription%20start%20sites%20during%20oxidative%20stress%20(reverse%20strand)%20-%20Thodberg%20et%20al.%20(2018)%2CTranscription%20start%20sites%20in%20YES%20(reverse%20strand)%20-%20Thodberg%20et%20al.%20(2018)%2CTranscription%20start%20sites%20in%20glucose%20minimal%20medium%20(forward%20strand)%20-%20Thodberg%20et%20al.%20(2018)%2CTranscription%20start%20sites%20in%20glucose%20minimal%20medium%20(reverse%20strand)%20-%20Thodberg%20et%20al.%20(2018)&highlight=",
            "link_label": "View in JBrowse ...",
            "date_added": "2018-06-14",
            "show_on_front_page": false
        },
        {
            "title": "Curto et al., 2017",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-06-26-curto-dni2.png"
            ],
            "content": "The ancient claudin Dni2 facilitates cell fusion at the shmoo tip.\n\n Originally published in <a href='https://link.springer.com/article/10.1007%2Fs00018-017-2709-4'>Cellular and Molecular Life Sciences</a>.",
            "reference_id": "PMID:29134248",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-06-26",
            "show_on_front_page": false
        },
        {
            "title": "MacKay et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-07-03-mackay-GrapHi-C.png"
            ],
            "content": "Graph-based visualization of Hi-C datasets.\n\n Originally published in <a href='https://bmcresnotes.biomedcentral.com/articles/10.1186/s13104-018-3507-2'>BMC Research Notes</a>.",
            "link": null,
            "date_added": "2018-07-03",
            "show_on_front_page": false
        },
        {
            "title": "Allard et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-07-04-allard-unified-model-of-cell-size.png"
            ],
            "content": "A unified model for cell size control.\n\n Originally published in <a href='http://jcb.rupress.org/content/217/5/1589'>Journal of Cell Biology</a>. Image from a <a href='http://jcb.rupress.org/content/217/5/1559'>Commentary</a> by Gerganova and Martin. ",
            "reference_id": "PMID:29514920",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-07-04",
            "show_on_front_page": false
        },
        {
            "title": "Parua et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-07-18-parua-cdk9-pp1-switch.png"
            ],
            "content": "A Cdk9-PP1 switch for RNA polymerase II elongation-termination.\n\n Originally published in <a href='https://www.nature.com/articles/s41586-018-0214-z'>Nature</a>. ",
            "reference_id": "PMID:29899453",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-07-18",
            "show_on_front_page": false
        },
        {
            "title": "Swaffer et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-07-19-swaffer-CCDP.png"
            ],
            "content": "Quantitative phosphoproteomics reveals the signaling dynamics of cell-cycle kinases; ~10K phosphorylation sites now in PomBase.\n\n Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(18)30946-X'>Cell Reports</a>.",
            "reference_id": "PMID:29996109",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-07-19",
            "show_on_front_page": false
        },
        {
            "title": "Meadows et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-08-13-mad2-anaphase-switch.png"
            ],
            "content": "Sharpening the anaphase switch.\n\n Originally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S2211-1247(17)30069-4'>Cell Reports</a>.",
            "reference_id": "PMID:28178520",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-08-13",
            "show_on_front_page": false
        },
        {
            "title": "Tay et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-08-14-tay-portli.png"
            ],
            "content": "Microtubule depolymerization in scd1&Delta; cells leads to PORTLI growth. \n\nOriginally published in the <a href='http://jcs.biologists.org/content/131/14/jcs216580'>Journal of Cell Science</a>. ",
            "reference_id": "PMID:29930085",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-08-14",
            "show_on_front_page": false
        },
        {
            "title": "Sajiki et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-08-27-yanagida-mitotic-restart.png"
            ],
            "content": "Essential genes for quiescence to cell cycle switching.\n\nOriginally published in <a href='http://advances.sciencemag.org/content/4/8/eaat5685'>Science Advances</a>. ",
            "reference_id": "PMID:30116786",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-08-27",
            "show_on_front_page": false
        },
        {
            "title": "Ganguly et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-09-20-russell-chromium.png"
            ],
            "content": "Genome-wide phenotypic analysis of chromate toxicity.\n\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1007595'>PLoS Genetics</a>. ",
            "reference_id": "PMID:30148840",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2018-09-20",
            "show_on_front_page": false
        },
        {
            "title": "Bravo Núñez et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-01-16-bravo-nunez-meiotic-drive.png"
            ],
            "content": "*wtf* genes in poison&ndash;antidote meiotic drive and its suppression.\n\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1007836'>PLoS Genetics</a>. ",
            "reference_id": "PMID:30475921",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-01-16",
            "show_on_front_page": false
        },
        {
            "title": "Webster et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-01-24-webster-deadenylation.png"
            ],
            "content": "RNA-binding proteins distinguish between similar sequence motifs to promote targeted deadenylation by Ccr4-Not.\n\nOriginally published in <a href='https://elifesciences.org/articles/40670'>eLife</a>. Image from [eLife 2019;8:e40670](https://elifesciences.org/articles/40670#fig7), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:30601114",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-01-24",
            "show_on_front_page": false
        },
        {
            "title": "von Loeffelholz et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-02-04-von-loeffelholz-kinesin-microtubule.png"
            ],
            "content": "Cryo-EM structure of kinesin-5-microtubule complex reveals a distinct binding footprint and mechanism of drug resistance.\n\nOriginally published in <a href='https://www.sciencedirect.com/science/article/pii/S0022283619300105'>JMB</a>. ",
            "reference_id": "PMID:30659798",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-02-04",
            "show_on_front_page": false
        },
        {
            "title": "Shukla et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-02-06-shukla-cenp-a.png"
            ],
            "content": "Centromere DNA destabilizes H3 nucleosomes to promote CENP-A deposition during the cell cycle.\n\nOriginally published in <a href='https://www.sciencedirect.com/science/article/pii/S0960982218314167'>Current Biology</a>. ",
            "reference_id": "PMID:30503616",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-02-06",
            "show_on_front_page": false
        },
        {
            "title": "Nakazawa et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-02-07-nakazawa-topo-2.png"
            ],
            "content": "DNA topoisomerase II phosphorylation in fission yeast reveals anticancer mechanism.\n\nOriginally published in <a href='http://www.jbc.org/content/early/2019/01/11/jbc.RA118.004955.long'>Journal of Biological Chemistry</a>. ",
            "reference_id": "PMID:30635402",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-02-07",
            "show_on_front_page": false
        },
        {
            "title": "Choi et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-02-11-choi-zinc-transporters.png"
            ],
            "content": "Zinc transporters belonging to the Cation Diffusion Facilitator (CDF) family have complementary roles in transporting zinc out of the cytosol.\n\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1007262'>PLoS Genetics</a>. ",
            "reference_id": "PMID:29529046",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-02-11",
            "show_on_front_page": false
        },
        {
            "title": "Li et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-03-05-li-essential-gene-bypass.png"
            ],
            "content": "Systematic analysis reveals the prevalence and principles of bypassable gene essentiality.\n\nOriginally published in <a href='https://www.nature.com/articles/s41467-019-08928-1'>Nature Communications</a>. ",
            "reference_id": "PMID:30824696",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-03-05",
            "show_on_front_page": false
        },
        {
            "title": "Lu et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-03-07-rad51-dissociation.png"
            ],
            "content": "Swi5-Sfr1 stimulates Rad51 recombinase filament assembly by modulating Rad51 dissociation.\n\nOriginally published in <a href='https://www.pnas.org/content/115/43/E10059.long'>PNAS</a>. ",
            "reference_id": "PMID:30297419",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-03-07",
            "show_on_front_page": false
        },
        {
            "title": "Cortés et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-03-07-septum-ingression.png"
            ],
            "content": "Specific primary septum detection reveals septum and cleavage furrow ingression during early anaphase independent of mitosis completion.\n\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1007388'>PLoS Genetics</a>. ",
            "reference_id": "PMID:29813053",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-03-07",
            "show_on_front_page": false
        },
        {
            "title": "Martín-García et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-03-07-paxillin-calcineurin.png"
            ],
            "content": "Paxillin-mediated recruitment of calcineurin to the contractile ring is required for cytokinesis.\n\nOriginally published in <a href='https://www.sciencedirect.com/science/article/pii/S2211124718315079?via%3Dihub'>Cell Reports</a>. ",
            "reference_id": "PMID:30332655",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-03-07",
            "show_on_front_page": false
        },
        {
            "title": "Laboucarié et al., 2017",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-04-05-Taf12-phosphorylation-starvation.png"
            ],
            "content": "Taf12 phosphorylation inhibits sexual differentiation downstream of TORC2.\nOriginally published in <a href='http://embor.embopress.org/content/18/12/2197.long'>EMBO reports</a>.",
            "reference_id": "PMID:29079657",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-04-05",
            "show_on_front_page": false
        },
        {
            "title": "Longmuir et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-04-17-Longmuir-CRISPR-Cas9.png"
            ],
            "content": "Unexpected insertion of carrier DNA during CRISPR-Cas9, and \"unknowns\" remain \"unknown\"\nOriginally published in <a href='https://bmcresnotes.biomedcentral.com/articles/10.1186/s13104-019-4228-x'>BMC Research Notes</a>.",
            "reference_id": "PMID:30925937",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-04-17",
            "show_on_front_page": false
        },
        {
            "title": "Willet et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-04-20-willet-NDR-Kinase-Sid2.png"
            ],
            "content": "NDR Kinase Sid2 Drives Anillin-like Mid1 from the Membrane to Promote Cytokinesis.\nOriginally published in <a href='https://www.cell.com/current-biology/fulltext/S0960-9822(19)30134-4'>Current Biology</a>.",
            "reference_id": "PMID:30853434",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-04-20",
            "show_on_front_page": false
        },
        {
            "title": "Shen et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-04-20-shen-Set7-H3K37-Methyltransferase.png"
            ],
            "content": "Set7, a histone methyltransferase for the uncharted histone H3-37 mark.\nOriginally published in <a href='https://www.cell.com/structure/fulltext/S0969-2126(19)30011-5'>Structure</a>.",
            "reference_id": "PMID:30773398",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-04-20",
            "show_on_front_page": false
        },
        {
            "title": "Reid et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-04-24-reid-tRNA-nucleotidyltransferases.png"
            ],
            "content": "Fission yeast contains separate CC- and A-adding tRNA nucleotidyltransferases.\nOriginally published in <a href='https://www.sciencedirect.com/science/article/pii/S0006291X18325634'>Biochemical and Biophysical Research Communications</a>.",
            "reference_id": "PMID:30528393",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-04-24",
            "show_on_front_page": false
        },
        {
            "title": "Okada et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-05-02-okada-myoII-isoforms.png"
            ],
            "content": "Distinct roles of myosin-II isoforms in cytokinesis under normal and stressed conditions.\nOriginally published in <a href='https://doi.org/10.1016/j.isci.2019.03.014'>iScience</a>.",
            "reference_id": "PMID:30928696",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-05-03",
            "show_on_front_page": false
        },
        {
            "title": "Zhu et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-05-16-30044717-Zhu-Ync13-cytokinesis-role.png"
            ],
            "content": "Previously uncharacterized protein, Ync13, has a role during late stages of cytokinesis.\nOriginally published in <a href='https://www.molbiolcell.org/doi/full/10.1091/mbc.E18-04-0225'>Molecular Biology of the Cell</a>.",
            "reference_id": "PMID:30044717",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-05-16",
            "show_on_front_page": false
        },
        {
            "title": "Tang et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-05-21-31042107-Tang-temperature-sensitive-mutations.png"
            ],
            "content": "Simple procedure for the generation of temperature-sensitive mutations with error-prone PCR.\nOriginally published in <a href='https://www.tandfonline.com/doi/abs/10.1080/09168451.2019.1611414'>Bioscience, Biotechnology, and Biochemistry</a>.",
            "reference_id": "PMID:31042107",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-05-21",
            "show_on_front_page": false
        },
        {
            "title": "Xu et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-05-21-30914423-mutants-condensin-hinge.png"
            ],
            "content": "Isolation of single amino acid t-s mutants in condensin hinge domain.\nOriginally published in <a href='https://www.g3journal.org/content/9/5/1777.long'>G3: Genes, Genomes, Genetics</a>.",
            "reference_id": "PMID:30914423",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-05-21",
            "show_on_front_page": false
        },
        {
            "title": "Xu et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-05-27-31072933-kleisin-hinge-interaction.png"
            ],
            "content": "Suppressor screening reveals common kleisin-hinge interaction in condensin and cohesin.\nOriginally published in <a href='https://www.pnas.org/content/early/2019/05/09/1902699116.long'>PNAS</a>.",
            "reference_id": "PMID:31072933",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-05-27",
            "show_on_front_page": false
        },
        {
            "title": "Xu et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-05-27-29352077-suppressor-dna.png"
            ],
            "content": "Sequencing of suppressor DNA mixtures identifies pathways that compensate for chromosome segregation defects.\nOriginally published in <a href='http://www.g3journal.org/cgi/pmidlookup?view=long&amp;pmid=29352077'>G3: Genes, Genomes, Genetics</a>.",
            "reference_id": "PMID:29352077",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-05-27",
            "show_on_front_page": false
        },
        {
            "title": "Xu et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-05-30-29735656-xu-ring-model-v-hold-release.png"
            ],
            "content": "A. Prevailing ring model vs. B. hold and release model; Suppressor mutation analysis combined with 3D modeling proposes a different mechanism for cohesin.\nOriginally published in <a href='https://www.pnas.org/content/115/21/E4833.long'>PNAS</a>.",
            "reference_id": "PMID:29735656",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-05-30",
            "show_on_front_page": false
        },
        {
            "title": "Morris et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-05-31-31116668-chen-pkd2-separation-defects.png"
            ],
            "content": "Fission yeast TRP channel Pkd2p localizes to the cleavage furrow during cytokinesis.\nOriginally published in <a href='https://www.molbiolcell.org/doi/abs/10.1091/mbc.E18-04-0270'>Molecular Biology of the Cell</a>.",
            "reference_id": "PMID:31116668",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-06-02",
            "show_on_front_page": false
        },
        {
            "title": "Shen et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-06-03-30427751-Shen-J-domain-co-chaperone-Rsp1.png"
            ],
            "content": "The J-domain co-chaperone Rsp1 interacts with Mto1 to organize non-centrosomal microtubule assembly.\nOriginally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E18-05-0279'>Molecular Biology of the Cell</a>.",
            "reference_id": "PMID:30427751",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-06-03",
            "show_on_front_page": false
        },
        {
            "title": "Tamang et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-06-13-31149897-Tamang-PCNA-unloader-Elg1.png"
            ],
            "content": "The PCNA unloader Elg1 promotes recombination at collapsed replication forks.\nOriginally published in <a href='https://elifesciences.org/articles/47277'>eLife</a>. Image from [eLife 2019;8:e47277](https://elifesciences.org/articles/47277#fig7), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:31149897",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-06-13",
            "show_on_front_page": false
        },
        {
            "title": "Yukawa et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-06-18-30463883-Yukawa-MAPs-microtubule-dynamics.png"
            ],
            "content": "MAPs and Microtubule Dynamics Balance with the Cut7/Kinesin-5 Motor for Mitotic Spindle Assembly.\nOriginally published in <a href='https://www.g3journal.org/content/9/1/269.long'>G3: Genes, Genomes, Genetics</a>.",
            "reference_id": "PMID:30463883",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-06-18",
            "show_on_front_page": false
        },
        {
            "title": "Kume et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "/media/2019-06-21-31015410-Kume-nuclear-blebbing-lem2-mutants.mp4"
            ],
            "content": "Nuclear \"blebbing\" in Lem2 mutants from \"Nuclear membrane protein Lem2 regulates nuclear size through membrane flow\".\nOriginally published in <a href='https://www.nature.com/articles/s41467-019-09623-x'>Nature Communications</a>.",
            "reference_id": "PMID:31015410",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-06-21",
            "show_on_front_page": false
        },
        {
            "title": "Nakazawa et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2018-06-22-31201205-Nakazawa-putative-ceramide-conjugation.png"
            ],
            "content": "Coordinated Roles of the Putative Ceramide-Conjugation Protein, Cwh43, and a Mn 2+ -Transporting, P-Type ATPase, Pmr1.\nOriginally published in <a href='https://www.g3journal.org/content/early/2019/06/14/g3.119.400281.long'>G3: Genes, Genomes, Genetics</a>.",
            "reference_id": "PMID:31201205",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-06-24",
            "show_on_front_page": false
        },
        {
            "title": "Bao et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-06-28-30573453-Bao-Epe1-associates-with-SAGA.png"
            ],
            "content": "Epe1 associates with the SAGA complex to promote transcription of repeats for heterochromatin assembly.\nOriginally published in <a href='http://genesdev.cshlp.org/content/33/1-2/116.long'>Genes &amp; Development</a>.",
            "reference_id": "PMID:30573453",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-06-29",
            "show_on_front_page": false
        },
        {
            "title": "Liu et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-07-02-30840879-Liu-F-BAR-domain-Rga7.png"
            ],
            "content": "The F-BAR Domain of Rga7 Relies on a Cooperative Mechanism of Membrane Binding with Rng10.\nOriginally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(19)30149-4'>Cell Reports</a>.",
            "reference_id": "PMID:30840879",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-07-02",
            "show_on_front_page": false
        },
        {
            "title": "Grech et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-07-02-31077324-Grech-pombe-fitness-landscape.png"
            ],
            "content": "The fitness landscape of the fission yeast genome.\n\nOriginally published in <a href='https://doi.org/10.1093/molbev/msz113'>Mol Biol Evol</a>.\n\n<a href='/jbrowse/?tracks=DNA%20sequence%2CPomBase%20forward%20strand%20features%2CPomBase%20reverse%20strand%20features%2CConservation%20of%20sites%20over%20the%20phylogeny%20of%20S.%20pombe%E2%80%9A%20S.%20octosporus%E2%80%9A%20S.%20japonicus%E2%80%9A%20and%20S.%20cryophilus%20(higher%20values%20are%20associated%20with%20higher%20degree%20of%20conservation)-%20Grech%20et%20al.%20(2019)%2CHDE%20units%E2%80%9A%20continuous%20runs%20of%20states%20S1-S3%20-%20Grech%20et%20al.%20(2019)%2CTransposon%20insertion%20sites%20-%20Grech%20et%20al.%20(2019)%2CTransposon%20insertion%20sites%20smoothed%20to%20states%20S1-S5%20(lower%20states%20have%20fewer%20insertions)%20-%20Grech%20et%20al.%20(2019)&amp;loc=III%3A804712..899614&amp;highlight='>View datasets in the genome browser ...</a>",
            "reference_id": "PMID:31077324",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-07-02",
            "show_on_front_page": false
        },
        {
            "title": "Onwubiko et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-07-03-30709916-onwubiko-gef1-cdc15-ring.png"
            ],
            "content": "A Cdc42 GEF, Gef1, organizes F-BAR Cdc15 along the actomyosin ring.\nOriginally published in <a href='https://jcs.biologists.org/content/132/5/jcs223776.long'>Journal of Cell Science</a>. ",
            "reference_id": "PMID:30709916",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-07-03",
            "show_on_front_page": false
        },
        {
            "title": "Gallo et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-07-31-30389790-Gallo-human-MOGS-CDG-disorder.png"
            ],
            "content": "Abrogation of glucosidase I-mediated glycoprotein deglucosylation results in a sick phenotype in fission yeasts: Model for the human MOGS-CDG disorder.\nOriginally published in <a href='http://www.jbc.org/content/293/52/19957.long'>Journal of Biological Chemistry</a>.",
            "reference_id": "PMID:30389790",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-07-31",
            "show_on_front_page": false
        },
        {
            "title": "Jalan et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-08-02-30667359-Jalan-template-switch.png"
            ],
            "content": "Factors affecting template switch recombination associated with restarted DNA replication.\nOriginally published in <a href='https://elifesciences.org/articles/41697'>eLife</a>. Image from [eLife 2019;8:e41697](https://elifesciences.org/articles/41697#fig7), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:30667359",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-08-02",
            "show_on_front_page": false
        },
        {
            "title": "Wang et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-07-31-31285271-Asc1-stabilizes-the-interaction-between-eIF3a-and-Rps0A-uS2-for-protein-synthesis.png"
            ],
            "content": "Asc1 stabilizes the interaction between eIF3a and Rps0A/uS2 for protein synthesis.\nOriginally published in <a href='https://mcb.asm.org/content/early/2019/07/03/MCB.00161-19.long'>Molecular and Cellular Biology</a>.",
            "reference_id": "PMID:31285271",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-08-03",
            "show_on_front_page": false
        },
        {
            "title": "Oya et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-10-05-31468675-Oya-H3K14-ubiquitylation.png"
            ],
            "content": "H3K14 ubiquitylation promotes H3K9 methylation for heterochromatin assembly.\nOriginally published in <a href='https://www.embopress.org/doi/full/10.15252/embr.201948111'>EMBO reports</a>.",
            "reference_id": "PMID:31468675",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-10-04",
            "show_on_front_page": false
        },
        {
            "title": "Hirano et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-10-06-29292846-Lem2-nuclear-envelope.png"
            ],
            "content": "Lem2 is retained at the nuclear envelope through its interaction with Bqt4 in fission yeast.  LEM domain directly binds to DNA.\nOriginally published in <a href='https://onlinelibrary.wiley.com/doi/full/10.1111/gtc.12557'>Genes Cells</a>.",
            "reference_id": "PMID:29292846",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-10-06",
            "show_on_front_page": false
        },
        {
            "title": "Wilson et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-10-07-31515876-new-binding-site-zinc-repression.png"
            ],
            "content": "A new TF binding site (Loz1 response element) for zinc repression.\nOriginally published in <a href='https://onlinelibrary.wiley.com/doi/abs/10.1111/mmi.14384'>Molecular Microbiology</a>.",
            "reference_id": "PMID:31515876",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-10-15",
            "show_on_front_page": false
        },
        {
            "title": "Shirasugi et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-10-06-31532702-kinetochore-mediated-outward-force.png"
            ],
            "content": "Kinetochore-mediated outward force promotes spindle pole separation.\nOriginally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E19-07-0366'>Molecular Biology of the Cell</a>.",
            "reference_id": "PMID:31532702",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-10-15",
            "show_on_front_page": false
        },
        {
            "title": "Billault-Chaumartin et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-10-14-31495586-Billault-Chaumartin-ACP-fusion-focus.png"
            ],
            "content": "Capping Protein Insulates Arp2/3-Assembled Actin Patches from Formins.\nOriginally published in <a href='https://www.sciencedirect.com/science/article/pii/S0960982219310127'>Current Biology</a>.",
            "reference_id": "PMID:31495586",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-10-15",
            "show_on_front_page": false
        },
        {
            "title": "Kakui et al., 2013",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-10-24-23770679-Kakui-Microtubules-and-Alp7-Alp14.png"
            ],
            "content": "Microtubules and Alp7-Alp14 (TACC-TOG) reposition meiotic chromosomes.\nOriginally published in <a href='https://www.nature.com/articles/ncb2782'>Nature Cell Biology</a>.",
            "reference_id": "PMID:23770679",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-10-24",
            "show_on_front_page": false
        },
        {
            "title": "Hercyk et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-10-28-31591131-Hercyk-cdc15-cdc42.png"
            ],
            "content": "F-BAR Cdc15 Promotes Cdc42 Activation During Cytokinesis and Cell Polarization in *Schizosaccharomyces pombe*.\nOriginally published in <a href='https://www.genetics.org/content/early/2019/10/07/genetics.119.302649.long'>Genetics</a>.",
            "reference_id": "PMID:31591131",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-10-28",
            "show_on_front_page": false
        },
        {
            "title": "Xu et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-10-28-31332096-Xu-TTT-complex-Rad3-telomeres.png"
            ],
            "content": "Tel2-Tti1-Tti2 complex destabilization eliminates Rad3-ATR kinase signaling in the DNA replication checkpoint and leads to telomere shortening.\nOriginally published in <a href='https://mcb.asm.org/content/39/20/e00175-19'>Molecular and Cell Biology</a>.",
            "reference_id": "PMID:31332096",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-10-28",
            "show_on_front_page": false
        },
        {
            "title": "Ebina et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-11-14-31615768-Ebina-CLASP-microtubule-bundling.png"
            ],
            "content": "CLASP promotes microtubule bundling in metaphase spindle independently of Ase1.\nOriginally published in <a href='https://bio.biologists.org/content/8/10/bio045716'>Biology Open</a>.",
            "reference_id": "PMID:31615768",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-11-14",
            "show_on_front_page": false
        },
        {
            "title": "Leontiou et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-11-15-31257143-Leontiou-Bub1-TPR-Mad3-spindle-checkpoint-arrest.png"
            ],
            "content": "The Bub1-TPR domain interacts directly with Mad3 to generate robust spindle checkpoint arrest.\nOriginally published in <a href='https://www.cell.com/current-biology/fulltext/S0960-9822(19)30704-3'>Current Biology</a>.",
            "reference_id": "PMID:31257143",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-11-15",
            "show_on_front_page": false
        },
        {
            "title": "Zhurinsky et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-12-19-31483748-Zhurinsky-microtubule-nucleator-Mto1.png"
            ],
            "content": "Effects of the microtubule nucleator Mto1 on chromosome movement, DNA repair and sister chromatid cohesion.\nOriginally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E19-05-0301'>Molecular Biology of the Cell</a>.",
            "reference_id": "PMID:31483748",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-12-19",
            "show_on_front_page": false
        },
        {
            "title": "Mehta et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2019-12-30-30602572-Mehta-mitochondria-microtubules-mitochondrial-fission.png"
            ],
            "content": "Association of mitochondria with microtubules inhibits mitochondrial fission.\nOriginally published in <a href='http://www.jbc.org/content/294/10/3385'>Journal of Biological Chemistry</a>.",
            "reference_id": "PMID:30602572",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2019-12-31",
            "show_on_front_page": false
        },
        {
            "title": "Vještica et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-01-07-31801797-Vjestica-stable-integration-vectors.png"
            ],
            "content": "A highly modular toolbox to introduce heterologous sequences and includes antibiotic resistance markers, promoters, fluorescent tags, and terminators.\nOriginally published in <a href='https://jcs.biologists.org/content/early/2019/11/29/jcs.240754.long'>Journal of Cell Science</a>.",
            "reference_id": "PMID:31801797",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-01-07",
            "show_on_front_page": false
        },
        {
            "title": "Vo et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-01-08-31269446-Vo-CBF-heterochromatin.png"
            ],
            "content": "Role for cleavage and polyadenylation factor (CPF) in RNAi-independent heterochromatin domain assembly.\nOriginally published in <a href='https://www.sciencedirect.com/science/article/pii/S2211124719307545?via%3Dihub'>Cell Reports</a>.",
            "reference_id": "PMID:31269446",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-01-08",
            "show_on_front_page": false
        },
        {
            "title": "González-Medina et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-01-08-31260531-Gonzalez-Medina-gcn5-MBF.png"
            ],
            "content": "Gcn5-mediated acetylation at MBF-regulated promoters induces the G1/S transcriptional wave.\nOriginally published in <a href='https://academic.oup.com/nar/article/47/16/8439/5526704'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:31260531",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-01-08",
            "show_on_front_page": false
        },
        {
            "title": "Taglini et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-01-08-31822915-Bayne-mkt1-heterochromatin.png"
            ],
            "content": "Mkt1 is required for RNAi-mediated silencing and establishment of heterochromatin in fission yeast.\nOriginally published in <a href='https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkz1157/5673137'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:31822915",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-01-08",
            "show_on_front_page": false
        },
        {
            "title": "Fowler et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-02-12-30217891-Fowler-meiotic-chromosome-communication.png"
            ],
            "content": "Physical basis for long-distance communication along meiotic chromosomes.\nOriginally published in <a href='https://www.pnas.org/content/115/40/E9333.long'>PNAS</a>.",
            "reference_id": "PMID:30217891",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-02-12",
            "show_on_front_page": false
        },
        {
            "title": "Hercyk et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-02-12-31719163-Hercyk-Cdc42-GEFs-cell-polarity-cytokinesis.png"
            ],
            "content": "A novel interplay between GEFs orchestrates Cdc42 activity during cell polarity and cytokinesis in fission yeast.\nOriginally published in <a href='https://jcs.biologists.org/content/132/23/jcs236018.long'>Journal of Cell Science</a>.",
            "reference_id": "PMID:31719163",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-02-12",
            "show_on_front_page": false
        },
        {
            "title": "Tay et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-02-19-30726745-Tay-orb6-exocyst.png"
            ],
            "content": "Fission Yeast NDR/LATS Kinase Orb6 Regulates Exocytosis via Phosphorylation of the Exocyst Complex.\nOriginally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(19)30036-1'>Cell Reports</a>.",
            "reference_id": "PMID:30726745",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-02-19",
            "show_on_front_page": false
        },
        {
            "title": "Jiao et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-02-29-31178220-Tam41.png"
            ],
            "content": "Structures of the Mitochondrial CDP-DAG Synthase Tam41 Suggest a Potential Lipid Substrate Pathway from Membrane to the Active Site.\nOriginally published in <a href='https://www.cell.com/structure/fulltext/S0969-2126(19)30162-5'>Structure</a>.",
            "reference_id": "PMID:31178220",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-02-29",
            "show_on_front_page": false
        },
        {
            "title": "Yu et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-02-29-31941401-Du-autophagy.png"
            ],
            "content": "Atg38-Atg8 Interaction in Fission Yeast Establishes a Positive Feedback Loop to Promote Autophagy\nOriginally published in <a href='https://www.tandfonline.com/doi/full/10.1080/15548627.2020.1713644'>Autophagy</a>.",
            "reference_id": "PMID:31941401",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-02-29",
            "show_on_front_page": false
        },
        {
            "title": "Ng et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-02-29-32023460-Scs2-Pil1-interactions.png"
            ],
            "content": "Plasma Membrane Furrows Control Plasticity of ER-PM Contacts.\nOriginally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(19)31763-2'>Cell Reports</a>.",
            "reference_id": "PMID:32023460",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-02-29",
            "show_on_front_page": false
        },
        {
            "title": "Ait-Saada et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-03-04-31575705-Ait-Saada-Chromatin-remodeler-Fft3.png"
            ],
            "content": "Chromatin remodeler Fft3 plays a dual role at blocked DNA replication forks.\nOriginally published in [Life Science Alliance](https://www.life-science-alliance.org/content/2/5/e201900433).",
            "reference_id": "PMID:31575705",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-03-04",
            "show_on_front_page": false
        },
        {
            "title": "Chacko et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "/media/2020-03-25-31582398-Cortical-tethering-Mcp5.mp4"
            ],
            "content": "Cortical tethering of mitochondria by the anchor protein Mcp5 enables uniparental inheritance.\nOriginally published in <a href='https://rupress.org/jcb/article-lookup/doi/10.1083/jcb.201901108'>Journal of Cell Biology</a>.",
            "reference_id": "PMID:31582398",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-03-25",
            "show_on_front_page": false
        },
        {
            "title": "Inoue et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-03-37-31131414-Inoue-CK2-Rap1-telomere.png"
            ],
            "content": "Casein kinase 2 regulates telomere protein complex formation through Rap1 phosphorylation.\nOriginally published in <a href='https://academic.oup.com/nar/article/47/13/6871/5498753'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:31131414",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-03-27",
            "show_on_front_page": false
        },
        {
            "title": "Kim et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-03-30-31719112-Kim-checkpoint-regulation-nuclear-tos4-arrest.png"
            ],
            "content": "Checkpoint Regulation of Nuclear Tos4 Defines S Phase Arrest in Fission Yeast.\nOriginally published in <a href='https://www.g3journal.org/content/10/1/255'>G3: Genes, Genomes, Genetics</a>.",
            "reference_id": "PMID:31719112",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-03-20",
            "show_on_front_page": false
        },
        {
            "title": "Noguchi et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-04-09-30992049-Noguchi-NuA4-H4-acetylation.png"
            ],
            "content": "The NuA4 acetyltransferase and histone H4 acetylation promote replication recovery after topoisomerase I-poisoning.\nOriginally published in <a href='https://epigeneticsandchromatin.biomedcentral.com/articles/10.1186/s13072-019-0271-z'>Epigenetics &amp; Chromatin</a>.",
            "reference_id": "PMID:30992049",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-04-09",
            "show_on_front_page": false
        },
        {
            "title": "Bravo Núñez et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-04-16-32032353-bravo-nunez-meiotic-drivers.png"
            ],
            "content": "Dramatically diverse 'Schizosaccharomyces pombe wtf* meiotic drivers all display high gamete-killing efficiency.\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1008350'>PLoS Genetics</a>.",
            "reference_id": "PMID:32032353",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-04-16",
            "show_on_front_page": false
        },
        {
            "title": "Anandarajan et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-04-20-32034465-Anandarajan-formaldehyde-DNA-damage.png"
            ],
            "content": "Genetic investigation of formaldehyde-induced DNA damage response in *Schizosaccharomyces pombe*.\nOriginally published in <a href='https://link.springer.com/article/10.1007%2Fs00294-020-01057-z'>Current Genetics</a>.",
            "reference_id": "PMID:32034465",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-04-20",
            "show_on_front_page": false
        },
        {
            "title": "Shetty et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-04-21-31833215-Shetty-Maf1-tRNA-transcription-lifespan.png"
            ],
            "content": "Maf1-dependent transcriptional regulation of tRNAs prevents genomic instability and is associated with extended lifespan.\nOriginally published in <a href='https://onlinelibrary.wiley.com/doi/full/10.1111/acel.13068'>Aging Cell</a>.",
            "reference_id": "PMID:31833215",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-04-21",
            "show_on_front_page": false
        },
        {
            "title": "Argunhan et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-04-28-32204793-Argunhan-Rad51-Swi5-Sfr1.png"
            ],
            "content": "Cooperative interactions facilitate stimulation of Rad51 by the Swi5-Sfr1 auxiliary factor complex.\nOriginally published in <a href='https://elifesciences.org/articles/52566'>eLife</a>. Image: left panel from [eLife 2020;9:52566](https://elifesciences.org/articles/52566#fig7), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms; right panel B. Argunhan pers. comm.",
            "reference_id": "PMID:32204793",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-04-28",
            "show_on_front_page": false
        },
        {
            "title": "Cabrera et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-04-28-32075773-Cabera-chaperone-facilitated-aggregation.png"
            ],
            "content": "Chaperone-Facilitated Aggregation of Thermo-Sensitive Proteins Shields Them from Degradation during Heat Stress.\nOriginally published in [Cell Reports](http://dx.doi.org/10.1016/j.celrep.2020.01.077).",
            "reference_id": "PMID:32075773",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-04-28",
            "show_on_front_page": false
        },
        {
            "title": "Andres et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-05-05-30626735-Andres-Ctp1-DNA-bridging-DSB-repair.png"
            ],
            "content": "Ctp1 protein-DNA filaments promote DNA bridging and DNA double-strand break repair.\nOriginally published in [Journal of Biological Chemistry](https://www.jbc.org/content/294/9/3312).",
            "reference_id": "PMID:30626735",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-05-05",
            "show_on_front_page": false
        },
        {
            "title": "Ding et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-05-05-31811152-chr-associated-rna-protein-meiosis.png"
            ],
            "content": "Chromosome-associated RNA-protein complexes promote pairing of homologous chromosomes during meiosis.\nOriginally published in [Nature Communications](https://www.nature.com/articles/s41467-019-13609-0).",
            "reference_id": "PMID:31811152",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-05-05",
            "show_on_front_page": false
        },
        {
            "title": "Kampmeyer et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-05-06-32142608-Kampmeyer-heavy-water.png"
            ],
            "content": "Mutations in a single signaling pathway allow cell growth in heavy water.\nImage reprinted with permission from <a href='https://pubs.acs.org/doi/full/10.1021/acssynbio.9b00376'>ACS Synthetic Biology 2020 9 (4), 733-748</a>. Copyright 2020 American Chemical Society.",
            "reference_id": "PMID:32142608",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-05-06",
            "show_on_front_page": false
        },
        {
            "title": "Kamrad et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-05-07-32319721-Kamrad-pyruvate-kinase-variant.png"
            ],
            "content": "Pyruvate kinase variant of fission yeast tunes carbon metabolism, cell regulation, growth and stress resistance.\nOriginally published in <a href='https://www.embopress.org/doi/full/10.15252/msb.20199270'>Molecular Systems Biology</a>.",
            "reference_id": "PMID:32319721",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-05-07",
            "show_on_front_page": false
        },
        {
            "title": "Muñoz et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-05-07-32277274-Munoz-chromatin-remodellers-cohesin-loading.png"
            ],
            "content": "Conserved roles of chromatin remodellers in cohesin loading onto chromatin.\nOriginally published in <a href='https://link.springer.com/article/10.1007%2Fs00294-020-01075-x'>Current Genetics</a>.",
            "reference_id": "PMID:32277274",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-05-07",
            "show_on_front_page": false
        },
        {
            "title": "Sewart and Hauf, 2017",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-06-11-28366743-Sewart-Cdc20.png"
            ],
            "content": "Different Functionality of Cdc20 Binding Sites within the Mitotic Checkpoint Complex. \nOriginally published in <a href='https://doi.org/10.1016/j.cub.2017.03.007'>Curr.Biol.</a>",
            "reference_id": "PMID:28366743",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-06-10",
            "show_on_front_page": false
        },
        {
            "title": "Kim et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-06-12-32341083-Kim-replication-checkpoint-genome-instability.png"
            ],
            "content": "Active replication checkpoint drives genome instability in fission yeast mcm4 mutant.\nOriginally published in <a href='https://mcb.asm.org/content/early/2020/04/21/MCB.00033-20'>Molecular and Cellular Biology</a>.",
            "reference_id": "PMID:32341083",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-06-12",
            "show_on_front_page": false
        },
        {
            "title": "Chen et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-06-12-32366382-Chen-Spt5-phosphorylation-Rtf1.png"
            ],
            "content": "Spt5 phosphorylation and the Rtf1 Plus3 domain promote Rtf1 function through distinct mechanisms.\nOriginally published in <a href='https://mcb.asm.org/content/early/2020/05/01/MCB.00150-20'>Molecular and Cellular Biology</a>.",
            "reference_id": "PMID:32366382",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-06-12",
            "show_on_front_page": false
        },
        {
            "title": "Tanabe et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-06-12-32441227-Tanabe-pka1-glucose-limitation-mal3.png"
            ],
            "content": "Glucose limitation and pka1 deletion rescue aberrant mitotic spindle formation induced by Mal3 overexpression in Schizosaccharomyces pombe.\nOriginally published in <a href='https://www.tandfonline.com/doi/full/10.1080/09168451.2020.1763157'>Bioscience, Biotechnology, and Biochemistry</a>.",
            "reference_id": "PMID:32441227",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-06-12",
            "show_on_front_page": false
        },
        {
            "title": "Tsubouchi et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-06-16-32414915-Tsubouchi-Dmc1-auxiliary-factors.png"
            ],
            "content": "Two Auxiliary Factors Promote Dmc1-driven DNA Strand Exchange via Stepwise Mechanisms .\nOriginally published in <a href='https://www.pnas.org/content/117/22/12062'>PNAS</a>.",
            "reference_id": "PMID:32414915",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-06-16",
            "show_on_front_page": false
        },
        {
            "title": "Bhattacharjee et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-06-11-32101481-Bhattacharjee-pom1.png"
            ],
            "content": "DYRK kinase Pom1 drives F-BAR protein Cdc15 from the membrane to promote medial division.\nOriginally published in <a href='https://doi.org/10.1091/mbc.E20-01-0026'>Molecular Biology of the Cell</a>.",
            "reference_id": "PMID:32101481",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-06-17",
            "show_on_front_page": false
        },
        {
            "title": "Yague-Sanz et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-06-24-32499400-Yague-Sanz-RNA-pol-II-elongation-rate.png"
            ],
            "content": "Nutrient-dependent control of RNA polymerase II elongation rate regulates specific gene expression programs by alternative polyadenylation.\nOriginally published in <a href='http://genesdev.cshlp.org/content/early/2020/06/03/gad.337212.120'>Genes &amp; Development</a>.",
            "reference_id": "PMID:32499400",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-06-24",
            "show_on_front_page": false
        },
        {
            "title": "Onaka et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-07-01-32355220-Onaka-DNA-replication-chromosomal-rearrangement.png"
            ],
            "content": "DNA replication machinery prevents Rad52-dependent single-strand annealing that leads to gross chromosomal rearrangements at centromeres.\nOriginally published in <a href='https://www.nature.com/articles/s42003-020-0934-0'>Communications Biology</a>.",
            "reference_id": "PMID:32355220",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-07-01",
            "show_on_front_page": false
        },
        {
            "title": "Maestroni et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-04-06-31980821-Maestroni-telomeres.png"
            ],
            "content": " Nuclear Envelope Attachment of Telomeres Limits TERRA and Telomeric Rearrangements in Quiescent Fission Yeast Cells.\nOriginally published in <a href='https://academic.oup.com/nar/article/48/6/3029/5715813'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:31980821",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-07-06",
            "show_on_front_page": false
        },
        {
            "title": "Parua et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-07-22-32496538-Parua-Cdk9-H2Bub1-transcription-elongation.png"
            ],
            "content": "Cdk9 and H2Bub1 signal to Clr6-CII/Rpd3S to suppress aberrant antisense transcription.\nOriginally published in <a href='https://academic.oup.com/nar/article/48/13/7154/5851387'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:32496538",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-07-22",
            "show_on_front_page": false
        },
        {
            "title": "Stott et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-07-23-31553675-Stott-Sds23-cell-division-symmetry.png"
            ],
            "content": "The phosphatase inhibitor Sds23 regulates cell division symmetry in fission yeast.\nOriginally published in <a href='https://www.molbiolcell.org/doi/full/10.1091/mbc.E19-05-0254'>Molecular Biology of the Cell</a>.",
            "reference_id": "PMID:31553675",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-07-23",
            "show_on_front_page": false
        },
        {
            "title": "Vondrova et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-07-27-32546830-nse4.png"
            ],
            "content": "A role of the Nse4 kleisin and Nse1/Nse3 KITE subunits in the ATPase cycle of SMC5/6.\nOriginally published in <a href='https://www.nature.com/articles/s41598-020-66647-w'>Scientific Reports</a>.",
            "reference_id": "PMID:32546830",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-07-27",
            "show_on_front_page": false
        },
        {
            "title": "Marek et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-08-05-32320462-Marek-Ltc1-dependent-sterol-flow.png"
            ],
            "content": "Sterol biosensor reveals LAM-family Ltc1-dependent sterol flow to endosomes upon Arp2/3 inhibition.\nOriginally published in <a href='https://rupress.org/jcb/article/219/6/e202001147/151694/Sterol-biosensor-reveals-LAM-family-Ltc1-dependent'>Journal of Cell Biology</a>.",
            "reference_id": "PMID:32320462",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-08-05",
            "show_on_front_page": false
        },
        {
            "title": "Zhao et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-08-02-32735772-Zhao-ER-phagy.png"
            ],
            "content": "A UPR-Induced Soluble ER-Phagy Receptor Acts with VAPs to Confer ER Stress Resistance.\nOriginally published in <a href='https://www.sciencedirect.com/science/article/abs/pii/S1097276520305104?via%3Dihub'>Molecular Cell</a>.",
            "reference_id": "PMID:32735772",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-08-13",
            "show_on_front_page": false
        },
        {
            "title": "Zhang et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-09-03-32692737-Zhang-CRL4Cdt2-XPF-nucleases-Pxd1-degradation.png"
            ],
            "content": "CRL4Cdt2 ubiquitin ligase regulates Dna2 and Rad16 (XPF) nucleases by targeting Pxd1 for degradation.\n\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1008933'>PLoS Genetics</a>. ",
            "reference_id": "PMID:32692737",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-09-03",
            "show_on_front_page": false
        },
        {
            "title": "Lee et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-09-11-32101745-Lee-transposon-integration-heterochromatin.png"
            ],
            "content": "Dense Transposon Integration Reveals Essential Cleavage and Polyadenylation Factors Promote Heterochromatin Formation.\nOriginally published in [Cell Reports](http://dx.doi.org/10.1016/j.celrep.2020.01.094).",
            "reference_id": "PMID:32101745",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-09-11",
            "show_on_front_page": false
        },
        {
            "title": "Dey et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-09-14-32848252-Dey-les1.png"
            ],
            "content": "Closed mitosis requires local disassembly of the nuclear envelope.\nOriginally published in <a href='https://www.nature.com/articles/s41586-020-2648-3'>Nature</a>.",
            "reference_id": "PMID:32848252",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-09-14",
            "show_on_front_page": false
        },
        {
            "title": "Antoine et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-09-17-31927482-Antoine-Smn1-profilin-splicing.png"
            ],
            "content": "Splicing Defects of the Profilin Gene Alter Actin Dynamics in an S. pombe SMN Mutant.\nOriginally published in <a href='http://dx.doi.org/10.1016/j.isci.2019.100809'>iScience</a>.",
            "reference_id": "PMID:31927482",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-09-17",
            "show_on_front_page": false
        },
        {
            "title": "Bravo Núñez et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-09-22-32790622-Bravo-Nunez-atypical-meiosis-wtf.png"
            ],
            "content": "Atypical meiosis can be adaptive in outcrossed Schizosaccharomyces pombe due to wtf meiotic drivers.\nOriginally published in <a href='https://elifesciences.org/articles/57936'>eLife</a>. Image from [eLife 2020;9:e57936](https://elifesciences.org/articles/57936#fig7), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:32790622",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-09-22",
            "show_on_front_page": false
        },
        {
            "title": "Tsuyuzaki et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-09-22-32152323-Tsuyuzaki-histone-H3-spore-germination.png"
            ],
            "content": "Time-lapse single-cell transcriptomics reveals modulation of histone H3 for dormancy breaking in fission yeast.\nOriginally published in <a href='https://www.nature.com/articles/s41467-020-15060-y'>Nature Communications</a>.",
            "reference_id": "PMID:32152323",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-09-22",
            "show_on_front_page": false
        },
        {
            "title": "Nakazawa et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-09-24-31615333-Yanagida-condensin.png"
            ],
            "content": "Condensin locates at transcriptional termination sites in mitosis, possibly releasing mitotic transcripts.\nOriginally published in <a href='https://royalsocietypublishing.org/doi/10.1098/rsob.190125'>Open Biology</a>.",
            "reference_id": "PMID:31615333",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-09-23",
            "show_on_front_page": false
        },
        {
            "title": "Pan et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-09-28-32909946-Pan-Atg1.png"
            ],
            "content": "Atg1 kinase in fission yeast is activated by Atg11-mediated dimerization and cis-autophosphorylation.\nOriginally published in <a href='https://elifesciences.org/articles/58073'>eLife</a>. Image from [eLife 2020;9:e58073](https://elifesciences.org/articles/58073#fig4), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:32909946",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-09-28",
            "show_on_front_page": false
        },
        {
            "title": "Marayati et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-10-01-32295063-Marayati-Lsd1-Lsd2-heterochromatin.png"
            ],
            "content": "The Catalytic-Dependent and -Independent Roles of Lsd1 and Lsd2 Lysine Demethylases in Heterochromatin Formation in *Schizosaccharomyces pombe*.\nOriginally published in <a href='https://www.mdpi.com/2073-4409/9/4/955'>Cells</a>.",
            "reference_id": "PMID:32295063",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-10-01",
            "show_on_front_page": false
        },
        {
            "title": "Grallert et al., 2013",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-10-03-23333317-Grallert.png"
            ],
            "content": "Removal of centrosomal PP1 by NIMA kinase unlocks the MPF feedback loop to promote mitotic commitment in S. pombe.\nOriginally published in <a href='https://www.cell.com/current-biology/fulltext/S0960-9822(12)01512-6?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS0960982212015126%3Fshowall%3Dtrue'>Current Biology</a>.",
            "reference_id": "PMID:23333317",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-10-02",
            "show_on_front_page": false
        },
        {
            "title": "Stonyte et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-10-07-32361273-par1_LopezAviles.png"
            ],
            "content": "Requirement of PP2A-B56 Par1 for the Stabilization of the CDK Inhibitor Rum1 and Activation of APC/C Ste9 during Pre-Start G1 in S. pombe.\nOriginally published in <a href='https://www.cell.com/iscience/fulltext/S2589-0042(20)30248-0?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS2589004220302480%3Fshowall%3Dtrue'>iScience</a>.",
            "reference_id": "PMID:32361273",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-10-07",
            "show_on_front_page": false
        },
        {
            "title": "Sjölander et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-10-09-31932483-Sjolander-Wis1-redox-sensitive-thiol.png"
            ],
            "content": "A Redox-Sensitive Thiol in Wis1 Modulates the Fission Yeast Mitogen-Activated Protein Kinase Response to H2O2 and Is the Target of a Small Molecule.\nOriginally published in <a href='https://mcb.asm.org/content/40/7/e00346-19'>Molecular and Cellular Biology</a>.",
            "reference_id": "PMID:31932483",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-10-09",
            "show_on_front_page": false
        },
        {
            "title": "Prieto-Ruiz et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-10-15-31911490_rnc1_cansado.png"
            ],
            "content": "RNA-Binding Protein Rnc1 Regulates Cell Length at Division and Acute Stress Response in Fission Yeast through Negative Feedback Modulation of the Stress-Activated Mitogen-Activated Protein Kinase Pathway.\nOriginally published in <a href='https://mbio.asm.org/content/11/1/e02815-19'>mBio</a>.",
            "reference_id": "PMID:31911490",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-10-15",
            "show_on_front_page": false
        },
        {
            "title": "Ohashi et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-10-21-32918581-Ohashi-Vps74-glycosyltransferase-Golgi.png"
            ],
            "content": "Golgi localization of glycosyltransferases requires Gpp74p in Schizosaccharomyces pombe.\nOriginally published in <a href='https://link.springer.com/article/10.1007/s00253-020-10881-9'>Applied Microbiology and Biotechnology</a>.",
            "reference_id": "PMID:32918581",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-10-20",
            "show_on_front_page": false
        },
        {
            "title": "Gómez-Gil et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-10-26-32913139-for3-Cansando.png"
            ],
            "content": "Stress-activated MAPK signalling controls fission yeast actomyosin ring integrity by modulating formin For3 levels.\nOriginally published in <a href='https://elifesciences.org/articles/57951'>eLife</a>. Image from [eLife 2020;9:e57951](https://elifesciences.org/articles/58073#fig5), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:32915139",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-10-26",
            "show_on_front_page": false
        },
        {
            "title": "Birot et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-11-02-31895039-pef1-mis4-javerzat.png"
            ],
            "content": "The CDK Pef1 and PP4 (Ppe2) oppose each other for regulating cohesin binding to chromosomes.\nOriginally published in <a href='https://elifesciences.org/articles/50556'>eLife</a>. Image from [eLife 2020;9:e50556](https://elifesciences.org/articles/50556#fig8), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:31895039",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-11-02",
            "show_on_front_page": false
        },
        {
            "title": "Ohtsuka et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-11-02-33064911-CLS-Ohtsuka.png"
            ],
            "content": "A review of genes involved in extension of chronological lifespan, and the identification of 4 new CLS genes.\nOriginally published in <a href='https://onlinelibrary.wiley.com/doi/10.1111/mmi.14627'>Molecular Microbiology</a>.",
            "reference_id": "PMID:33064911",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-11-02",
            "show_on_front_page": false
        },
        {
            "title": "Kakui et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-11-18-33153481-Kakui-condensin-interphase-chromatin-organization.png"
            ],
            "content": "Fission yeast condensin contributes to interphase chromatin organization and prevents transcription-coupled DNA damage.\nOriginally published in <a href='https://genomebiology.biomedcentral.com/articles/10.1186/s13059-020-02183-0'>Genome Biology</a>.",
            "reference_id": "PMID:33153481",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-11-18",
            "show_on_front_page": false
        },
        {
            "title": "Kecman et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-12-01-30282034-Kecman-elongation-termination-factor-exchange.png"
            ],
            "content": "Elongation/Termination Factor Exchange Mediated by PP1 Phosphatase Orchestrates Transcription Termination.\nOriginally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(18)31422-0'>Cell Reports</a>.",
            "reference_id": "PMID:30282034",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-12-01",
            "show_on_front_page": false
        },
        {
            "title": "Morita et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-12-01-32892625-Morita-alpha-actinin-Ain1-actin-binding-mechanism.png"
            ],
            "content": "Molecular Mechanism for the Actin-Binding Domain of α-Actinin Ain1 Elucidated by Molecular Dynamics Simulations and Mutagenesis Experiments.\nOriginally published in <a href='https://pubs.acs.org/doi/full/10.1021/acs.jpcb.0c04623'>The Journal of Physical Chemistry</a>.",
            "reference_id": "PMID:32892625",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-12-01",
            "show_on_front_page": false
        },
        {
            "title": "Kramarz et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-12-01-33159083-Kramarz-SUMO-replication-fork-NPC.png"
            ],
            "content": "The nuclear pore primes recombination-dependent DNA synthesis at arrested forks by promoting SUMO removal.\nOriginally published in <a href='https://www.nature.com/articles/s41467-020-19516-z'>Nature Communications</a>.",
            "reference_id": "PMID:33159083",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-12-01",
            "show_on_front_page": false
        },
        {
            "title": "Expósito-Serrano et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-12-12-32502403-Exposito-Serrano-NPC.png"
            ],
            "content": "Selective Nuclear Pore Complex Removal Drives Nuclear Envelope Division in Fission Yeast.\nOriginally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S0960982220307533'>Current Biology</a>.",
            "reference_id": "PMID:32502403",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-12-02",
            "show_on_front_page": false
        },
        {
            "title": "Zahedi et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-12-14-33260998-Zahedi-HTP-flow-cytometry-cell-quiescence.png"
            ],
            "content": "High-Throughput Flow Cytometry Combined with Genetic Analysis Brings New Insights into the Understanding of Chromatin Regulation of Cellular Quiescence.\nOriginally published in <a href='https://www.mdpi.com/1422-0067/21/23/9022/htm'>International Journal of Molecular Sciences</a>.",
            "reference_id": "PMID:33260998",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-12-14",
            "show_on_front_page": false
        },
        {
            "title": "Malecki et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-12-14-32896087-Malecki-mitochondrial-respiration.png"
            ],
            "content": "Mitochondrial respiration is required to provide amino acids during fermentative proliferation of fission yeast.\nOriginally published in <a href='https://www.embopress.org/doi/full/10.15252/embr.202050845'>EMBO Reports</a>.",
            "reference_id": "PMID:32896087",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-12-14",
            "show_on_front_page": false
        },
        {
            "title": "Lee et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-12-15-33223513-Lee-Tif302-terbinafine.png"
            ],
            "content": "Systematic Target Screening Revealed That Tif302 Could Be an Off-Target of the Antifungal Terbinafine in Fission Yeast.\nOriginally published in <a href='http://www.biomolther.org/journal/view.html?uid=1272&vmd=Full'>Biomolecules & Therapeutics</a>.",
            "reference_id": "PMID:33223513",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-12-16",
            "show_on_front_page": false
        },
        {
            "title": "Kanou et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-12-18-32313168-Kanou-serine-catabolism-ROS.png"
            ],
            "content": "Serine catabolism produces ROS, sensitizes cells to actin dysfunction, and suppresses cell growth in fission yeast.\nOriginally published in <a href='https://www.nature.com/articles/s41429-020-0305-6'>The Journal of Antibiotics</a>.",
            "reference_id": "PMID:32313168",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2020-12-18",
            "show_on_front_page": false
        },
        {
            "title": "Wu et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-09-32062975-Wu-dsk1.png"
            ],
            "content": "Phosphoproteomics Reveals Novel Targets and Phosphoprotein Networks in Cell Cycle Mediated by Dsk1.\nOriginally published in <a href='https://pubs.acs.org/doi/10.1021/acs.jproteome.0c00027'>Journal of Proteome Research</a>.",
            "reference_id": "PMID:32062975",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-01-09",
            "show_on_front_page": false
        },
        {
            "title": "Snider et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-09-33357436-Snider-cdc15.png"
            ],
            "content": "Opposite Surfaces of the Cdc15 F-BAR Domain Create a Membrane Platform That Coordinates Cytoskeletal and Signaling Components for Cytokinesis.\nOriginally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(20)31515-1'>Cell Reports</a>.",
            "reference_id": "PMID:33357436",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-01-09",
            "show_on_front_page": false
        },
        {
            "title": "Fukuda et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-09-33138913-Fukuda-atg43.png"
            ],
            "content": "Atg43 tethers isolation membranes to mitochondria to promote starvation-induced mitophagy in fission yeast.\nOriginally published in <a href='https://elifesciences.org/articles/61245'>eLife</a>. Image from [eLife 2020;9:e61245](https://elifesciences.org/articles/61245#fig7), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:33138913",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-01-13",
            "show_on_front_page": false
        },
        {
            "title": "Chesnel et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-15-33137104-Chesnel-prefoldin-stabilizes-VHL.png"
            ],
            "content": "The prefoldin complex stabilizes the von Hippel-Lindau protein against aggregation and degradation.\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009183'>PLoS Genetics</a>.",
            "reference_id": "PMID:33137104",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-01-15",
            "show_on_front_page": false
        },
        {
            "title": "Zhang et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-21-33202882-Zhang-meu5-outer-prospore-membrane-breakdown.png"
            ],
            "content": "The Fission Yeast RNA-Binding Protein Meu5 Is Involved in Outer Forespore Membrane Breakdown during Spore Formation.\nOriginally published in <a href='https://www.mdpi.com/2309-608X/6/4/284'>Journal of Fungi</a>.",
            "reference_id": "PMID:33202882",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-01-21",
            "show_on_front_page": false
        },
        {
            "title": "Hazra et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-21-31974447-Hazra-Erh1-homodimer-meiosis.png"
            ],
            "content": "Formation of S. pombe Erh1 homodimer mediates gametogenic gene silencing and meiosis progression.\nOriginally published in <a href='https://www.nature.com/articles/s41598-020-57872-4'>Scientific Reports</a>.",
            "reference_id": "PMID:31974447",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-01-21",
            "show_on_front_page": false
        },
        {
            "title": "De Zoysa et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-22-32841241-De-Zoysa-hypomodified-tRNA.png"
            ],
            "content": "Hypomodified tRNA in evolutionarily distant yeasts can trigger rapid tRNA decay to activate the general amino acid control response, but with different consequences.\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1008893'>PLoS Genetics</a>.",
            "reference_id": "PMID:32841241",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-01-25",
            "show_on_front_page": false
        },
        {
            "title": "Boronat et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-28-33225241-Boronat-Mas5-protein-quality-control-Pyp1.png"
            ],
            "content": "The Hsp40 Mas5 Connects Protein Quality Control and the General Stress Response through the Thermo-sensitive Pyp1.\nOriginally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S2589-0042(20)30922-6'>iScience</a>.",
            "reference_id": "PMID:33225241",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-01-28",
            "show_on_front_page": false
        },
        {
            "title": "Wang et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-29-33410907-Wang-pin1-ssu72.png"
            ],
            "content": "The fission yeast Pin1 peptidyl-prolyl isomerase promotes dissociation of Sty1 MAPK from RNA polymerase II and recruits Ssu72 phosphatase to facilitate oxidative stress induced transcription.\nOriginally published in <a href='https://academic.oup.com/nar/article/49/2/805/6067400'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:33410907",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-01-28",
            "show_on_front_page": false
        },
        {
            "title": "Paulo et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-30-31626996-Paulo-carbon-source-proteomic-profiling.png"
            ],
            "content": "Multiplexed proteome profiling of carbon source perturbations in two yeast species with SL-SP3-TMT..\nOriginally published in <a href='https://www.sciencedirect.com/science/article/pii/S1874391919303033'>Journal of Proteomics</a>.",
            "reference_id": "PMID:31626996",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-01-28",
            "show_on_front_page": false
        },
        {
            "title": "Gerguri et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-02-11-33434270-Gerguri-condensin-extrusion.png"
            ],
            "content": "Comparison of loop extrusion and diffusion capture as mitotic chromosome formation pathways in fission yeast.\nOriginally published in <a href='https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkaa1270/6090303'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:33434270",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-02-11",
            "show_on_front_page": false
        },
        {
            "title": "Yukawa et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-01-11-33506191-Yukawa-escape-from-catastrophe.png"
            ],
            "content": "Escape from mitotic catastrophe by actin-dependent nuclear displacement in fission yeast..\nOriginally published in <a href='https://www.cell.com/iscience/fulltext/S2589-0042(20)31228-1'>iScience</a>.",
            "reference_id": "PMID:33506191",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-02-11",
            "show_on_front_page": false
        },
        {
            "title": "Roche et al., 2016",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-02-11-27738016-Roche-RNAi-quiescence.png"
            ],
            "content": "RNA interference is essential for cellular quiescence.\nOriginally published in <a href='https://science.sciencemag.org/content/354/6313/aah5651.long'>Science</a>.",
            "reference_id": "PMID:27738016",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-02-11",
            "show_on_front_page": false
        },
        {
            "title": "Charlton et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-02-19-32817556-Charlton-heterochromatin-boundary.png"
            ],
            "content": "Integrity of a heterochromatic domain ensured by its boundary elements.\nOriginally published in <a href='https://www.pnas.org/content/117/35/21504'>PNAS</a>.",
            "reference_id": "PMID:32817556",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-02-19",
            "show_on_front_page": false
        },
        {
            "title": "Rubio et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-02-19-33313903-Rubio-ribosome-stalling-trp-codons.png"
            ],
            "content": "Ribosome profiling reveals ribosome stalling on tryptophan codons and ribosome queuing upon oxidative stress in fission yeast..\nOriginally published in <a href='https://academic.oup.com/nar/article/49/1/383/6031432'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:33313903",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-02-19",
            "show_on_front_page": false
        },
        {
            "title": "Dong et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-02-24-32269268-Dong-Abo1-heterochromatin.png"
            ],
            "content": "Abo1 is required for the H3K9me2 to H3K9me3 transition in heterochromatin.\nOriginally published in <a href='https://www.nature.com/articles/s41598-020-63209-y'>Scientific Reports</a>.",
            "reference_id": "PMID:32269268",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-02-24",
            "show_on_front_page": false
        },
        {
            "title": "Misova et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-02-24-33511417-Misova-homologous-recombination-HIRA.png"
            ],
            "content": "Repression of a large number of genes requires interplay between homologous recombination and HIRA.\nOriginally published in <a href='https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkab027/6123375'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:33511417",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-02-24",
            "show_on_front_page": false
        },
        {
            "title": "Basu et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-02-26-32084401-Basu-cdc13-HP.png"
            ],
            "content": "The Hydrophobic Patch Directs Cyclin B to Centrosomes to Promote Global CDK Phosphorylation at Mitosis.\nOriginally published in <a href='https://doi.org/10.1016/j.cub.2019.12.053'>Current Biology</a>.",
            "reference_id": "PMID:32084401",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-02-26",
            "show_on_front_page": false
        },
        {
            "title": "Naiman et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-03-06-33568651-Naiman-recombination-dependent-replication-fork-dynamics.png"
            ],
            "content": "Replication dynamics of recombination-dependent replication forks.\nOriginally published in <a href='https://www.nature.com/articles/s41467-021-21198-0'>Nature Communications</a>.",
            "reference_id": "PMID:33568651",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-03-16",
            "show_on_front_page": false
        },
        {
            "title": "Wei et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-03-18-33574613-Wei-TOR-MTREC.png"
            ],
            "content": "TOR targets an RNA processing network to regulate facultative heterochromatin, developmental gene expression and cell proliferation.\nOriginally published in <a href='https://www.nature.com/articles/s41556-021-00631-y'>Nature Cell Biology</a>.",
            "reference_id": "PMID:33574613",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-03-18",
            "show_on_front_page": false
        },
        {
            "title": "Weigt et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-03-19-33468217-Weigt-Rbm10-heterochromatin.png"
            ],
            "content": "Rbm10 facilitates heterochromatin assembly via the Clr6 HDAC complex.\nOriginally published in <a href='https://epigeneticsandchromatin.biomedcentral.com/articles/10.1186/s13072-021-00382-y'>Epigenetics & Chromatin</a>.",
            "reference_id": "PMID:33468217",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-03-19",
            "show_on_front_page": false
        },
        {
            "title": "Gallo Castro et al., 2018",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-03-31-30279276-Gallo-Castro-Cdc42-GAPs.png"
            ],
            "content": "Differential GAP requirement for Cdc42-GTP polarization during proliferation and sexual reproduction..\nOriginally published in <a href='https://rupress.org/jcb/article/217/12/4215/120308/Differential-GAP-requirement-for-Cdc42-GTP'>Journal of Cell Biology</a>.",
            "reference_id": "PMID:30279276",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-03-31",
            "show_on_front_page": false
        },
        {
            "title": "Sun et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-03-31-32059768-Sun-gene-expression-scaling-cell-size.png"
            ],
            "content": "Size-Dependent Increase in RNA Polymerase II Initiation Rates Mediates Gene Expression Scaling with Cell Size.\nOriginally published in <a href='https://www.cell.com/current-biology/fulltext/S0960-9822(20)30097-X'>Current Biology</a>.",
            "reference_id": "PMID:32059768",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-03-31",
            "show_on_front_page": false
        },
        {
            "title": "Li et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-03-31-33723569-Li-Mst1-DSB-resection-repair.png"
            ],
            "content": "Schizosaccharomyces pombe KAT5 contributes to resection and repair of a DNA double strand break.\nOriginally published in <a href='https://academic.oup.com/genetics/advance-article/doi/10.1093/genetics/iyab042/6173406'>Genetics</a>.",
            "reference_id": "PMID:33723569",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-03-31",
            "show_on_front_page": false
        },
        {
            "title": "Torres-Garcia et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-04-13-32908306-Torres-Garcia-epigenetic-silencing.png"
            ],
            "content": "Epigenetic gene silencing by heterochromatin primes fungal resistance.\nOriginally published in <a href='https://www.nature.com/articles/s41586-020-2706-x'>Nature</a>.",
            "reference_id": "PMID:32908306",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-04-13",
            "show_on_front_page": false
        },
        {
            "title": "Veepaschit, Viswanathan et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-04-13-33754639-Veepaschit-SMN-complex.png"
            ],
            "content": "Identification and structural analysis of the Schizosaccharomyces pombe SMN complex.\nOriginally published in <a href='https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkab158/6179931'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:33754639",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-04-13",
            "show_on_front_page": false
        },
        {
            "title": "Dong et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-04-14-33670267-Dong-Hrp3-nucleosome-remodeling.png"
            ],
            "content": "The Role of Non-Catalytic Domains of Hrp3 in Nucleosome Remodeling.\nOriginally published in <a href='https://www.mdpi.com/1422-0067/22/4/1793'>International Journal of Molecular Sciences</a>.",
            "reference_id": "PMID:33670267",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-04-14",
            "show_on_front_page": false
        },
        {
            "title": "Yanguas et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-04-20-33788833-Yanguas-Stx8-retromer-sorting-motif.png"
            ],
            "content": "Analysis of the SNARE Stx8 recycling reveals that the retromer-sorting motif has undergone evolutionary divergence.\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009463'>PLoS Genetics</a>.",
            "reference_id": "PMID:33788833",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-04-20",
            "show_on_front_page": false
        },
        {
            "title": "Pataki et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-04-21-33137119-Pataki-TOR.png"
            ],
            "content": "TOR Complex 2- independent mutations in the regulatory PIF pocket of Gad8AKT1/SGK1 define separate branches of the stress response mechanisms in fission yeast.\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009196'>PLoS Genetics</a>.",
            "reference_id": "PMID:33137119",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-04-15",
            "show_on_front_page": false
        },
        {
            "title": "Rasul et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-04-21-33483504-Rasul-ERMES-foci.png"
            ],
            "content": "Emr1 regulates the number of foci of the endoplasmic reticulum-mitochondria encounter structure complex.\nOriginally published in <a href='https://www.nature.com/articles/s41467-020-20866-x'>Nature Communications</a>.",
            "reference_id": "PMID:33483504",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-04-13",
            "show_on_front_page": false
        },
        {
            "title": "Uehara et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-04-27-33823662-Uehara-mitochondrial-protein-nutritional-phenotypes.png"
            ],
            "content": "Multiple nutritional phenotypes of fission yeast mutants defective in genes encoding essential mitochondrial proteins.\nOriginally published in <a href='https://royalsocietypublishing.org/doi/10.1098/rsob.200369'>Open Biology</a>.",
            "reference_id": "PMID:33823662",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-04-27",
            "show_on_front_page": false
        },
        {
            "title": "Gallardo et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-04-27-33176152-Gallardo-heat-stress-nucleolar-ring.png"
            ],
            "content": "Acute Heat Stress Leads to Reversible Aggregation of Nuclear Proteins into Nucleolar Rings in Fission Yeast.\nOriginally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(20)31366-8'>Cell Reports</a>.",
            "reference_id": "PMID:33176152",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-04-27",
            "show_on_front_page": false
        },
        {
            "title": "Rivosecchi et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-05-02-33771877-Rivosecchi-RNA-polymerase-condensin.png"
            ],
            "content": "RNA polymerase backtracking results in the accumulation of fission yeast condensin at active genes.\nOriginally published in <a href='https://www.life-science-alliance.org/content/4/6/e202101046'>Life Science Alliance</a>.",
            "reference_id": "PMID:33771877",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-05-02",
            "show_on_front_page": false
        },
        {
            "title": "Ma et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-05-09-33888556-Ma-cohesion_protection.png"
            ],
            "content": "Meikin synergizes with shugoshin to protect cohesin Rec8 during meiosis I.\nOriginally published in <a href='http://genesdev.cshlp.org/content/35/9-10/692'>Genes & Development</a>.",
            "reference_id": "PMID:33888556",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-05-09",
            "show_on_front_page": false
        },
        {
            "title": "Nuckolls et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-05-04-33108274-Nuckolls-wtf4-protein-aggregation.png"
            ],
            "content": "The wtf4 meiotic driver utilizes controlled protein aggregation to generate selective cell death.\nOriginally published in <a href='https://elifesciences.org/articles/55694'>eLife</a>. Image from [eLife 2020;9:e55694](https://elifesciences.org/articles/55694#fig6), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:33108274",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-05-04",
            "show_on_front_page": false
        },
        {
            "title": "Rallis et al., 2017",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-05-11-28281664-Rallis-gsk3.png"
            ],
            "content": "Genetic interactions and functional analyses of the fission yeast gsk3 and amk2 single and double mutants defective in TORC1-dependent processes.\nOriginally published in <a href='https://www.nature.com/articles/srep44257'>Scientific Reports</a>.",
            "reference_id": "PMID:28281664",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-05-11",
            "show_on_front_page": false
        },
        {
            "title": "Ban et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-05-12-33693625-Ban-Dri1-heterochromatin-assembly.png"
            ],
            "content": "Dri1 mediates heterochromatin assembly via RNAi and histone deacetylation.\nOriginally published in <a href='https://doi.org/10.1093/genetics/iyab032'>Genetics</a>.",
            "reference_id": "PMID:33693625",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-05-12",
            "show_on_front_page": false
        },
        {
            "title": "Bhardwaj et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-05-18-33775921-Bhardwaj-Rpb9-deletion-phenotypes.png"
            ],
            "content": "Deletion of the non-essential Rpb9 subunit of RNA polymerase II results in pleiotropic phenotypes in Schizosaccharomyces pombe.\nOriginally published in <a href='https://www.sciencedirect.com/science/article/pii/S1570963921000601'>Biochimica et Biophysica Acta</a>.",
            "reference_id": "PMID:33775921",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-05-18",
            "show_on_front_page": false
        },
        {
            "title": "Fukunaga et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-05-18-33909078-Fukunaga-Gmh1-Otg1-galactosyltransferase-specificity.png"
            ],
            "content": "Substrate specificities of α1,2- and α1,3-galactosyltransferases and characterization of Gmh1p and Otg1p in Schizosaccharomyces pombe.\nOriginally published in <a href='https://doi.org/10.1093/glycob/cwab028'>Glycobiology</a>.",
            "reference_id": "PMID:33909078",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-05-18",
            "show_on_front_page": false
        },
        {
            "title": "Zach et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-05-19-33796794-Zach-pol-delta-overexpression-replication.png"
            ],
            "content": "Increased expression of Polδ does not alter the canonical replication program in vivo.\nOriginally published in <a href='https://wellcomeopenresearch.org/articles/6-44/v2'>Wellcome Open Research</a>.",
            "reference_id": "PMID:33796794",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-05-19",
            "show_on_front_page": false
        },
        {
            "title": "Minagawa et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-05-21-33925026-Minagawa-benzimidazole-resistant-beta-tubulin.png"
            ],
            "content": "Dual Impact of a Benzimidazole Resistant β-Tubulin on Microtubule Behavior in Fission Yeast.\nOriginally published in <a href='https://www.mdpi.com/2073-4409/10/5/1042/htm'>Cells</a>.",
            "reference_id": "PMID:33925026",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-05-21",
            "show_on_front_page": false
        },
        {
            "title": "Shan et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-06-10-34010645-Shan-H3K9M-H3K14ub-Clr4-heterochromatin.png"
            ],
            "content": "The histone H3K9M mutation synergizes with H3K14 ubiquitylation to selectively sequester histone H3K9 methyltransferase Clr4 at heterochromatin.\nOriginally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(21)00476-9'>Cell Reports</a>.",
            "reference_id": "PMID:34010645",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-06-10",
            "show_on_front_page": false
        },
        {
            "title": "Ohtsuka et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-06-11-33970532-Ohtsuka-ecl1.png"
            ],
            "content": "Magnesium depletion extends fission yeast lifespan via general amino acid control activation.\nOriginally published in <a href='https://onlinelibrary.wiley.com/doi/10.1002/mbo3.1176'>MicrobiologyOpen</a>.",
            "reference_id": "PMID:33970532",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-06-11",
            "show_on_front_page": false
        },
        {
            "title": "Yukawa et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-06-11-33946513-Yukawa-dri1.png"
            ],
            "content": "The Putative RNA-Binding Protein Dri1 Promotes the Loading of Kinesin-14/Klp2 to the Mitotic Spindle and Is Sequestered into Heat-Induced Protein Aggregates in Fission Yeast.\nOriginally published in <a href='https://www.mdpi.com/1422-0067/22/9/4795'>International Journal of Molecular Sciences</a>.",
            "reference_id": "PMID:33946513",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-06-11",
            "show_on_front_page": false
        },
        {
            "title": "Ding et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-06-15-33825974-Ding-linear-elements.png"
            ],
            "content": "Linear elements are stable structures along the chromosome axis in fission yeast meiosis.\nOriginally published in <a href='https://link.springer.com/article/10.1007%2Fs00412-021-00757-w'>Chromosoma</a>.",
            "reference_id": "PMID:33825974",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-06-15",
            "show_on_front_page": false
        },
        {
            "title": "Kurauchi et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-06-17-34114004-Kurauchi-sur2-lifespan.png"
            ],
            "content": "Identification of sur2 mutation affecting the lifespan of fission yeast.\nOriginally published in <a href='https://doi.org/10.1093/femsle/fnab070'>FEMS Microbiology Letters</a>.",
            "reference_id": "PMID:34114004",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-06-17",
            "show_on_front_page": false
        },
        {
            "title": "Zdravković et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-06-18-33836577-Zdravkovic-Ctp1-Mre11.png"
            ],
            "content": "A conserved Ctp1/CtIP C-terminal peptide stimulates Mre11 endonuclease activity.\nOriginally published in <a href='https://www.pnas.org/content/118/11/e2016287118'>PNAS</a>.",
            "reference_id": "PMID:33836577",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-06-18",
            "show_on_front_page": false
        },
        {
            "title": "Inamura et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-06-24-34086083-Inamura-rst2-mug14-expression.png"
            ],
            "content": "Expression of Mug14 is regulated by the transcription factor Rst2 through the cAMP-dependent protein kinase pathway in Schizosaccharomyces pombe.\nOriginally published in <a href='https://link.springer.com/article/10.1007%2Fs00294-021-01194-z'>Current Genetics</a>.",
            "reference_id": "PMID:34086083",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-06-24",
            "show_on_front_page": false
        },
        {
            "title": "Liu et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-06-30-34108240-Liu-S-phase-checkpoint-stalled-replisomes.png"
            ],
            "content": "The intra-S phase checkpoint directly regulates replication elongation to preserve the integrity of stalled replisomes.\nOriginally published in <a href='https://www.pnas.org/content/118/24/e2019183118'>PNAS</a>.",
            "reference_id": "PMID:34108240",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-06-30",
            "show_on_front_page": false
        },
        {
            "title": "Toyoda et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-06-28-34028542-Toyoda-aly3.png"
            ],
            "content": "TORC2 inhibition of α-arrestin Aly3 mediates cell surface persistence of S. pombe Ght5 glucose transporter in low glucose.\nOriginally published in <a href='https://journals.biologists.com/jcs/article-abstract/134/10/jcs257485/268339/TORC2-inhibition-of-arrestin-Aly3-mediates-cell?redirectedFrom=fulltext'>Journal of Cell Science</a>.",
            "reference_id": "PMID:34028542",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-06-28",
            "show_on_front_page": false
        },
        {
            "title": "Kang et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-07-06-33658433-Kang-Abo1-histone-loading-mechanism.png"
            ],
            "content": "Single-Molecule Imaging Reveals the Mechanism Underlying Histone Loading of Schizosaccharomyces pombe AAA+ ATPase Abo1. (Image from [Cho et al. 2019](https://www.pombase.org/reference/PMID:31848341)).\nOriginally published in <a href='http://www.molcells.org/journal/view.html?doi=10.14348/molcells.2021.2242'>Molecules and Cells</a>.",
            "reference_id": "PMID:33658433",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-07-06",
            "show_on_front_page": false
        },
        {
            "title": "Boronat et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-07-07-33386485-Boronat-misfolded-protein-sequestration.png"
            ],
            "content": "Spatial sequestration of misfolded proteins as an active chaperone-mediated process during heat stress.\nOriginally published in <a href='https://link.springer.com/article/10.1007%2Fs00294-020-01135-2'>Current Genetics</a>.",
            "reference_id": "PMID:33386485",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-07-07",
            "show_on_front_page": false
        },
        {
            "title": "Sawada et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-07-07-34147496-Sawada-polyphosphate-vacuolar-proteolysis.png"
            ],
            "content": "Regulation of inorganic polyphosphate is required for proper vacuolar proteolysis in fission yeast.\nOriginally published in <a href='https://www.jbc.org/article/S0021-9258(21)00691-8/fulltext'>Journal of Biological Chemistry</a>.",
            "reference_id": "PMID:34147496",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-07-07",
            "show_on_front_page": false
        },
        {
            "title": "Fukuda et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-07-09-33534698-Fukuda-TOR.png"
            ],
            "content": "Tripartite suppression of fission yeast TORC1 signaling by the GATOR1-Sea3 complex, the TSC complex, and Gcn2 kinase.\nOriginally published in <a href='https://elifesciences.org/articles/60969'>eLife</a>. Image from [eLife 2021;10:e60969](https://elifesciences.org/articles/60969#fig6), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:33534698",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-07-09",
            "show_on_front_page": false
        },
        {
            "title": "Sevcovicova et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-07-15-34208949-Sevcovicova-Swi5-Sfr1-phosphorylation.png"
            ],
            "content": "Mapping and Analysis of Swi5 and Sfr1 Phosphorylation Sites.\nOriginally published in <a href='https://www.mdpi.com/2073-4425/12/7/1014/htm'>Genes</a>.",
            "reference_id": "PMID:34208949",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-07-15",
            "show_on_front_page": false
        },
        {
            "title": "Wang et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-07-27-34169534-Wang-Nbr1-cargo-selection.png"
            ],
            "content": "Molecular and structural mechanisms of ZZ domain-mediated cargo selection by Nbr1.\nOriginally published in <a href='https://doi.org/10.15252/embj.2020107497'>The EMBO Journal</a>.",
            "reference_id": "PMID:34169534",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-07-27",
            "show_on_front_page": false
        },
        {
            "title": "Bellingham-Johnstun et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-07-27-33496728-la-plante.png"
            ],
            "content": "Molecular organization of cytokinesis node predicts the constriction rate of the contractile ring..\nOriginally published in <a href='https://rupress.org/jcb/article-abstract/220/3/e202008032/211718/Molecular-organization-of-cytokinesis-node?redirectedFrom=fulltext'>Journal of Cell Biology</a>.",
            "reference_id": "PMID:33496728",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-07-27",
            "show_on_front_page": false
        },
        {
            "title": "Willet et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-07-28-34279633-willet-imp2.png"
            ],
            "content": "Phosphorylation in the intrinsically disordered region of F-BAR protein Imp2 regulates its contractile ring recruitment.\nOriginally published in <a href='https://journals.biologists.com/jcs/article-abstract/doi/10.1242/jcs.258645/270972/Phosphorylation-in-the-intrinsically-disordered?redirectedFrom=fulltext'>Journal of Cell Science</a>.",
            "reference_id": "PMID:34402513",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-07-28",
            "show_on_front_page": false
        },
        {
            "title": "Mikolaskova et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-07-07-34209806-Mikolaskova-Nrt1.png"
            ],
            "content": "Identification of Nrl1 Domains Responsible for Interactions with RNA-Processing Factors and Regulation of Nrl1 Function by Phosphorylation.\nOriginally published in <a href='https://www.mdpi.com/1422-0067/22/13/7011'>International Journal of Molecular Sciences</a>.",
            "reference_id": "PMID:34209806",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-07-30",
            "show_on_front_page": false
        },
        {
            "title": "Shan et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-08-05-33378674-Shan-INO80.png"
            ],
            "content": "The INO80 Complex Regulates Epigenetic Inheritance of Heterochromatin.\nOriginally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(20)31550-3'>Cell Reports</a>.",
            "reference_id": "PMID:33378674",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-08-05",
            "show_on_front_page": false
        },
        {
            "title": "Liu, Hu, et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-08-05-34255844-Liu-shelterin.png"
            ],
            "content": "The cooperative assembly of shelterin bridge provides a kinetic gateway that controls telomere length homeostasis.\nOriginally published in <a href='https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkab550/6320402'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:34255844",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-08-05",
            "show_on_front_page": false
        },
        {
            "title": "Su et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-08-11-34292936-Su-Rad8-PCNA.png"
            ],
            "content": "Fission yeast Rad8/HLTF facilitates Rad52-dependent chromosomal rearrangements through PCNA lysine 107 ubiquitination.\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009671'>PLoS Genetics</a>.",
            "reference_id": "PMID:34292936",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-08-11",
            "show_on_front_page": false
        },
        {
            "title": "Soriano et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-09-14-34228709-Soriano-DNA-polymerase-epsilon.png"
            ],
            "content": "Expression of the cancer-associated DNA polymerase ε P286R in fission yeast leads to translesion synthesis polymerase dependent hypermutation and defective DNA replication.\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009526'>PLoS Genetics</a>.",
            "reference_id": "PMID:34228709",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-09-14",
            "show_on_front_page": false
        },
        {
            "title": "Matsui et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-09-22-34534388-Matsui-ksg1-chronological-lifespan.png"
            ],
            "content": "Identification of ksg1 mutation showing long-lived phenotype in fission yeast.\nOriginally published in <a href='https://onlinelibrary.wiley.com/doi/epdf/10.1111/gtc.12897'>Genes to Cells</a>.",
            "reference_id": "PMID:34534388",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-09-22",
            "show_on_front_page": false
        },
        {
            "title": "Morozumi et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-09-23-34499159-Morozumi-TOS.png"
            ],
            "content": "Fission yeast TOR complex 1 phosphorylates Psk1 through evolutionarily conserved interaction mediated by the TOS motif.\nOriginally published in <a href='https://journals.biologists.com/jcs/article/doi/10.1242/jcs.258865/272125/Fission-yeast-TOR-complex-1-phosphorylates-Psk1'>Journal of Cell Science</a>.",
            "reference_id": "PMID:34499159",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-09-23",
            "show_on_front_page": false
        },
        {
            "title": "Pineda-Santaella et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-09-23-34346498-Pineda-Santaella-kinesin8.png"
            ],
            "content": "Loss of kinesin-8 improves the robustness of the self-assembled spindle in Schizosaccharomyces pombe.\nOriginally published in <a href='https://journals.biologists.com/jcs/article/134/16/jcs253799/271911/Loss-of-kinesin-8-improves-the-robustness-of-the'>Journal of Cell Science</a>.",
            "reference_id": "PMID:34346498",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-09-23",
            "show_on_front_page": false
        },
        {
            "title": "Yu et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-10-25-34499173-Yu-Pul1-co-tethering-assay.png"
            ],
            "content": "Visual detection of binary, ternary and quaternary protein interactions in fission yeast using a Pil1 co-tethering assay.\nOriginally published in <a href='https://journals.biologists.com/jcs/article-abstract/134/19/jcs258774/272452/Visual-detection-of-binary-ternary-and-quaternary'>Journal of Cell Science</a>.",
            "reference_id": "PMID:34499173",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-10-25",
            "show_on_front_page": false
        },
        {
            "title": "Mak et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-11-08-34296454-Mak-TOR.png"
            ],
            "content": "The TOR-dependent phosphoproteome and regulation of cellular protein synthesis..\nOriginally published in <a href='https://www.embopress.org/doi/full/10.15252/embj.2021107911'>EMBO Journal</a>.",
            "reference_id": "PMID:34296454",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-11-08",
            "show_on_front_page": false
        },
        {
            "title": "Yague-Sanz et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-11-08-34352089-Yague-Sanz-RNA-cleavage-termination.png"
            ],
            "content": "Co-transcriptional RNA cleavage by Drosha homolog Pac1 triggers transcription termination in fission yeast.\nOriginally published in Nucleic Acids Research <a href='https://academic.oup.com/nar/article/49/15/8610/6342457'></a>.",
            "reference_id": "PMID:34352089",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-11-08",
            "show_on_front_page": false
        },
        {
            "title": "Stirpe et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-11-08-34524082-Stirpe-Clr4.png"
            ],
            "content": "SUV39 SET domains mediate crosstalk of heterochromatic histone marks.\nOriginally published in <a href='https://elifesciences.org/articles/62682'>eLife</a>. Image from [eLife 2021;10:e62682](https://elifesciences.org/articles/62682#fig2), used under [CC-BY](https://creativecommons.org/licenses/by/4.0/) license terms.",
            "reference_id": "PMID:34524082",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-11-08",
            "show_on_front_page": false
        },
        {
            "title": "Hu et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-11-17-33378677-Hu-telomerase-RNA-folding.png"
            ],
            "content": "Quality-Control Mechanism for Telomerase RNA Folding in the Cell.\nOriginally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(20)31557-6'>Cell Reports</a>.",
            "reference_id": "PMID:33378677",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-11-17",
            "show_on_front_page": false
        },
        {
            "title": "Malla et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-11-30-34613787-Malla-Chen.png"
            ],
            "content": "Counting actin in contractile rings reveals novel contributions of cofilin and type II myosins to fission yeast cytokinesis..\nOriginally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E21-08-0376'>Mol Biol Cell</a>.",
            "reference_id": "PMID:34613787",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-11-30",
            "show_on_front_page": false
        },
        {
            "title": "Murawska  et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-11-30-34731638-Murawska-Braun-FACT.png"
            ],
            "content": "The histone chaperone FACT facilitates heterochromatin spreading by regulating histone turnover and H3K9 methylation states.\nOriginally published in <a href='https://www.sciencedirect.com/science/article/pii/S2211124721014170'>Cell Rep</a>.",
            "reference_id": "PMID:34731638",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-11-30",
            "show_on_front_page": false
        },
        {
            "title": "Finet et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-12-08-34798057-Finet-mRNA-dihydrouridylation.png"
            ],
            "content": "Transcription-wide mapping of dihydrouridine reveals that mRNA dihydrouridylation is required for meiotic chromosome segregation.\nOriginally published in <a href='https://www.sciencedirect.com/science/article/pii/S1097276521009539?via%3Dihub'>Mol Cell.</a>.",
            "reference_id": "PMID:34798057",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-12-08",
            "show_on_front_page": false
        },
        {
            "title": "Wintrebert et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2021-12-10-Smith-33526714-rec-NLS.png"
            ],
            "content": "Activation of meiotic recombination by nuclear import of the DNA break hotspot-determining complex.\nOriginally published in <a href='https://journals.biologists.com/jcs/article/134/4/jcs253518/237380/Activation-of-meiotic-recombination-by-nuclear'>J Cell Sci</a>.",
            "reference_id": "PMID:33526714",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2021-12-10",
            "show_on_front_page": false
        },
        {
            "title": "Schwer et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-01-18-Schwer-34389684-seb1.png"
            ],
            "content": "Genetic screen for suppression of transcriptional interference identifies a gain-of-function mutation in Pol2 termination factor Seb1.\nOriginally published in <a href='https://www.pnas.org/content/118/33/e2108105118'>PNAS</a>.",
            "reference_id": "PMID:34389684",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-01-18",
            "show_on_front_page": false
        },
        {
            "title": "Garg et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-01-18-Garg-2022-01-18-pho7.png"
            ],
            "content": "Structure of Fission Yeast Transcription Factor Pho7 Bound to pho1 Promoter DNA and Effect of Pho7 Mutations on DNA Binding and Phosphate Homeostasis.\nOriginally published in <a href='https://journals.asm.org/doi/10.1128/MCB.00132-19'>Mol. Cell. Biol.</a>.",
            "reference_id": "PMID:31010807",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-01-18",
            "show_on_front_page": false
        },
        {
            "title": "Calvo et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-01-18-34805795-motamedi-flcn.png"
            ],
            "content": "The fission yeast FLCN/FNIP complex augments TORC1 repression or activation in response to amino acid (AA) availability.\nOriginally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S2589004221013079'>iScience</a>.",
            "reference_id": "PMID:34805795",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-01-19",
            "show_on_front_page": false
        },
        {
            "title": "Flor-Parra et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-01-18-35008733-lucena-lac1.png"
            ],
            "content": "The Ceramide Synthase Subunit Lac1 Regulates Cell Growth and Size in Fission Yeast.\nOriginally published in <a href='https://www.mdpi.com/1422-0067/23/1/303'>Int J Mol Sci</a>.",
            "reference_id": "PMID:35008733",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-01-19",
            "show_on_front_page": false
        },
        {
            "title": "Ellis et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-01-29-34464389-ellis-bahler-D-loop.png"
            ],
            "content": "R-loops and regulatory changes in chronologically ageing fission yeast cells drive non-random patterns of genome rearrangements.\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009784'>PLoS Genet</a>.",
            "reference_id": "PMID:34464389",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-01-29",
            "show_on_front_page": false
        },
        {
            "title": "Keifenheim et al., 2017",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-10-29-28479325-Keifenheim-cdc25.png"
            ],
            "content": "Size-Dependent Expression of the Mitotic Activator Cdc25 Suggests a Mechanism of Size Control in Fission Yeast.\nOriginally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S0960982217304256'>Curr. Biol.</a>.",
            "reference_id": "PMID:28479325",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-02-15",
            "show_on_front_page": false
        },
        {
            "title": "Halova et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-03-10-33823663-Halova-Petersen-TOR-phosphosites.png"
            ],
            "content": "A TOR (target of rapamycin) and nutritional phosphoproteome of fission yeast reveals novel targets in networks conserved in humans.\nOriginally published in <a href='https://royalsocietypublishing.org/doi/10.1098/rsob.200405'>Open Biol</a>.",
            "reference_id": "PMID:33823663",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-03-10",
            "show_on_front_page": false
        },
        {
            "title": "Cohen et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-03-10-35157728-Cohen-Weisman-TOR-gcn5.png"
            ],
            "content": "TOR complex 2 contributes to regulation of gene expression via inhibiting Gcn5 recruitment to subtelomeric and DNA replication stress genes.\nOriginally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1010061'>PLoS Genet</a>.",
            "reference_id": "PMID:35157728",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-03-10",
            "show_on_front_page": false
        },
        {
            "title": "Gutbrod et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-03-10-35194019-Gutbrod_Martienssen.png"
            ],
            "content": "Dicer promotes genome stability via the bromodomain transcriptional co-activator BRD4.\nOriginally published in <a href='https://www.nature.com/articles/s41467-022-28554-8'>Nat Commun</a>.",
            "reference_id": "PMID:35194019",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-03-10",
            "show_on_front_page": false
        },
        {
            "title": "Longo et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-03-15-PMID-34910579-Longo-smi1.png"
            ],
            "content": "Involvement of Smi1 in cell wall integrity and glucan synthase Bgs4 localization during fission yeast cytokinesis..\nOriginally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E21-04-0214'>Mol Biol Cell </a>.",
            "reference_id": "PMID:34910579",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-03-15",
            "show_on_front_page": false
        },
        {
            "title": "Yang et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-03-25-35300005-li-lin-can1.png"
            ],
            "content": "Canavanine resistance mutation can1-1 in Schizosaccharomyces pombe is a missense mutation in the ubiquitin ligase adaptor gene any1.\nOriginally published in <a href='https://www.micropublication.org/journals/biology/micropub-biology-000538'>MicroPubl Biol </a>.",
            "reference_id": "PMID:35300005",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-03-25",
            "show_on_front_page": false
        },
        {
            "title": "Vijayakumari et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-03-27-35320724-hauf-cdc48.png"
            ],
            "content": "Cdc48 influence on separase levels is independent of mitosis and suggests translational sensitivity of separase.\nOriginally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S2211124722002984'>Cell Rep.</a>.",
            "reference_id": "PMID:35320724",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-03-27",
            "show_on_front_page": false
        },
        {
            "title": "Sakuno et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-05-08-35333350-Sakuno-rec8.png"
            ],
            "content": "Rec8 Cohesin-mediated Axis-loop chromatin architecture is required for meiotic recombination.\nOriginally published in <a href='https://academic.oup.com/nar/article/50/7/3799/6554166'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:35333350",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-05-08",
            "show_on_front_page": false
        },
        {
            "title": "Lera-Ramirez et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-05-18-35293864-Ramirez-microtubule-rescue.png"
            ],
            "content": "Microtubule rescue at midzone edges promotes overlap stability and prevents spindle collapse during anaphase B.\nOriginally published in <a href='https://elifesciences.org/articles/72630'>Elife</a>.",
            "reference_id": "PMID:35293864",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-05-17",
            "show_on_front_page": false
        },
        {
            "title": "Billault-Chaumartin et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-07-19-PMID-35673994-fus1-billault-chaumartin.png"
            ],
            "content": "The actin assembly requirements of the formin Fus1 to build the fusion focus.\nOriginally published in <a href='https://doi.org/10.1242/jcs.260289'>Journal of Cell Science</a>.",
            "reference_id": "PMID:35673994",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-07-19",
            "show_on_front_page": false
        },
        {
            "title": "Porat et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-05-30-PMID-35277511-Porat.png"
            ],
            "content": "The methyl phosphate capping enzyme Bmc1/Bin3 is a stable component of the fission yeast telomerase holoenzyme.\nOriginally published in <a href='https://www.nature.com/articles/s41467-022-28985-3'>Nature Communications</a>.",
            "reference_id": "PMID:35277511",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-05-30",
            "show_on_front_page": false
        },
        {
            "title": "Kim et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-05-31-PMID-35286199-Kim.png"
            ],
            "content": "Mitotic spindle formation in the absence of Polo kinase.\nOriginally published in <a href='https://www.pnas.org/doi/full/10.1073/pnas.2114429119'>PNAS</a>.",
            "reference_id": "PMID:35286199",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-05-31",
            "show_on_front_page": false
        },
        {
            "title": "Harris et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-03-30-PMID-35100366-GEN-MOD-pombase.png"
            ],
            "content": "Fission stories: using PomBase to understand Schizosaccharomyces pombe biology.\nOriginally published in <a href='https://academic.oup.com/genetics/advance-article/doi/10.1093/genetics/iyab222/6481557'>Genetics</a>.",
            "reference_id": "PMID:35100366",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-04-04",
            "show_on_front_page": false
        },
        {
            "title": "Ren and Berrro, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-06-30-35024575-THATCH.png"
            ],
            "content": "Isolated THATCH domain of End4 is unable to bind F-actin independently in the fission yeast Schizosaccharomyces pombe.\nOriginally published in <a href='https://www.micropublication.org/journals/biology/micropub-biology-000508'>MicroPubl</a>.",
            "reference_id": "PMID:35024575",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-06-30",
            "show_on_front_page": false
        },
        {
            "title": "Ait Saada et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-06-30-35639710-any1.png"
            ],
            "content": "Characterization of canavanine-resistance of cat1 and vhc1 deletions and a dominant any1 mutation in fission yeast.\nOriginally published in <a href='https://www.pombase.org/reference/PMID:35639710'>PLoS One</a>.",
            "reference_id": "PMID:35639710",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-06-30",
            "show_on_front_page": false
        },
        {
            "title": "Opalko  et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-06-30-arf6-PMID-34958661-mosely.png"
            ],
            "content": "Arf6 anchors Cdr2 nodes at the cell cortex to control cell size at division..\nOriginally published in <a href='https://rupress.org/jcb/article/221/2/e202109152/212942/Arf6-anchors-Cdr2-nodes-at-the-cell-cortex-to'>JCB</a>.",
            "reference_id": "PMID:34958661",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-06-30",
            "show_on_front_page": false
        },
        {
            "title": "Zhang and See, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-06-30-PMID-35609605-eisosome-zhang.png"
            ],
            "content": "Coordinated cortical ER remodeling facilitates actomyosin ring assembly.\nOriginally published in <a href='https://www.cell.com/current-biology/fulltext/S0960-9822(22)00722-9?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS0960982222007229%3Fshowall%3Dtrue'>Current Biology</a>.",
            "reference_id": "PMID:35609605",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-06-30",
            "show_on_front_page": false
        },
        {
            "title": "Neeli-Venkata et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-06-30-PMID-34666001-Esc1-Minc.png"
            ],
            "content": "Detection of surface forces by the cell-wall mechanosensor Wsc1 in yeast.\nOriginally published in <a href='https://www.cell.com/developmental-cell/fulltext/S1534-5807(21)00768-1?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS1534580721007681%3Fshowall%3Dtrue'>Dev Cell</a>.",
            "reference_id": "PMID:34666001",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-06-30",
            "show_on_front_page": false
        },
        {
            "title": "Dong Q et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2022-06-30-PMID-34810257-CENP-T.png"
            ],
            "content": "Ccp1-Ndc80 switch at the N terminus of CENP-T regulates kinetochore assembly.\nOriginally published in <a href='https://www.pnas.org/doi/full/10.1073/pnas.2104459118'>Proc Natl Acad Sci</a>.",
            "reference_id": "PMID:34810257",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-06-30",
            "show_on_front_page": false
        },
        {
            "title": "Patel et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-08-18-PMID-35940128-qng1.-E-Murray.png"],
            "content": "Queuosine salvage in fission yeast by Qng1-mediated hydrolysis to queuine.\nOriginally published in <a href='https://www.sciencedirect.com/science/article/abs/pii/S0006291X22010919?via%3Dihub'>Biochem Biophys Res Commun</a>.",
            "reference_id": "PMID:35940128",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-08-18",
            "show_on_front_page": false
        },
        {
            "title": "Müller et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-08-18-30715423-queuine-micronutrient-E-Murray.png"],
            "content": "Queuine links translational control in eukaryotes to a micronutrient from bacteria.\nOriginally published in <a href='https://academic.oup.com/nar/article/47/7/3711/5305261'>Nucleic Acids Res.</a>.",
            "reference_id": "PMID:30715423",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-08-18",
            "show_on_front_page": false
        },
        {
            "title": "Oravcová  et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-09-09-30348841-oravcova-brc1.png"],
            "content": "Brc1 Promotes the Focal Accumulation and SUMO Ligase Activity of Smc5-Smc6 during Replication Stress.\nOriginally published in <a href='https://journals.asm.org/doi/10.1128/MCB.00271-18'>Mol. Cell. Biol.</a>.",
            "reference_id": "PMID:30348841",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-09-09",
            "show_on_front_page": false
        },
        {
            "title": "Hirai  et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-10-06-35970865.png"],
            "content": "The Mis6 inner kinetochore subcomplex maintains CENP-A nucleosomes against centromeric non-coding transcription during mitosis.\nOriginally published in <a href='https://www.nature.com/articles/s42003-022-03786-y'>Commun Biol</a>.",
            "reference_id": "PMID:35970865",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-10-19",
            "show_on_front_page": false
        },
        {
            "title": "Kolesar et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-10-17-35011726-nse1.png"],
            "content": "Role of Nse1 Subunit of SMC5/6 Complex as a Ubiquitin Ligase.\nOriginally published in <a href='https://www.mdpi.com/2073-4409/11/1/165'>Cell</a>.",
            "reference_id": "PMID:35011726",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-10-19",
            "show_on_front_page": false
        },
        {
            "title": "Schwer et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-10-26-PMID-32546512-erh.png"],
            "content": "Inactivation of fission yeast Erh1 de-represses pho1 expression: evidence that Erh1 is a negative regulator of prt lncRNA termination\nOriginally published in <a href='https://rnajournal.cshlp.org/content/26/10/1334'>RNA</a>.",
            "reference_id": "PMID:32546512",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-10-26",
            "show_on_front_page": false
        },
        {
            "title": "Sanchez et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-10-25-PMID-35314193-duf89.png"],
            "content": "Fission yeast Duf89 and Duf8901 are cobalt/nickel-dependent phosphatase-pyrophosphatases that act via a covalent aspartyl-phosphate intermediate.\nOriginally published in <a href='https://www.jbc.org/article/S0021-9258(22)00291-5/fulltext'>J Biol Chem</a>.",
            "reference_id": "PMID:35314193",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-10-25",
            "show_on_front_page": false
        },
        {
            "title": "Sanchez et al., 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-10-28-PMID-31276588-IP8.png"],
            "content": "Inositol pyrophosphates impact phosphate homeostasis via modulation of RNA 3' processing and transcription termination.\nOriginally published in <a href='https://academic.oup.com/nar/article/47/16/8452/5528736'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:31276588",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-10-27",
            "show_on_front_page": false
        },
        {
            "title": "Garg et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-10-27-34967420_rad24.png"],
            "content": "Genetic screen for suppression of transcriptional interference reveals fission yeast 14-3-3 protein Rad24 as an antagonist of precocious Pol2 transcription termination.\nOriginally published in <a href='https://academic.oup.com/nar/article/50/2/803/6489952'>Nucleic Acids Research</a>.",
            "reference_id": "PMID:34967420",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-10-27",
            "show_on_front_page": false
        },
        {
            "title": "Garg et al., 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-11-21-33579781-CTD-profiling.png"],
            "content": "Transcriptional profiling of fission yeast RNA polymerase II CTD mutants.\nOriginally published in <a href='https://rnajournal.cshlp.org/content/27/5/560'>RNA</a>.",
            "reference_id": "PMID:33579781",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-11-18",
            "show_on_front_page": false
        },
        {
            "title": "Sanchez et al., 2020",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-12-01-32282918-pin1-sanchez.png"],
            "content": "Genetic interactions and transcriptomics implicate fission yeast CTD prolyl isomerase Pin1 as an agent of RNA 3' processing and transcription termination that functions via its effects on CTD phosphatase Ssu72.\nOriginally published in <a href='https://academic.oup.com/nar/article/48/9/4811/5819598'>Nucleic Acids Res.</a>.",
            "reference_id": "PMID:32282918",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-12-01",
            "show_on_front_page": false
        },
        {
            "title": "Schwer et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-12-01-35012333-cft1-schwer.png"],
            "content": "Cleavage-Polyadenylation Factor Cft1 and SPX Domain Proteins Are Agents of Inositol Pyrophosphate Toxicosis in Fission Yeast..\nOriginally published in <a href='https://journals.asm.org/doi/10.1128/mbio.03476-21'>mBio</a>.",
            "reference_id": "PMID:35012333",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-12-01",
            "show_on_front_page": false
        },
        {
            "title": "Selicky et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2022-12-01-36361590-cipek-gpl1.png"],
            "content": "Interactome of Spliceosome-Associated G-Patch Protein Gpl1 in the Fission Yeast Schizosaccharomyces pombe.\nOriginally published in <a href='https://www.mdpi.com/1422-0067/23/21/12800'>Int J Mol Sci</a>.",
            "reference_id": "PMID:36361590",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2022-12-01",
            "show_on_front_page": false
        },
        {
            "title": "Bai et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-01-20_36108046_dnt1_Li_Sun_QW_Win.png"],
            "content": "Recovery from spindle checkpoint-mediated arrest requires a novel Dnt1-dependent APC/C activation mechanism.\nOriginally published in <a href='https://www.pombase.org/reference/PMID:36108046'>PLoS Genet</a>.",
            "reference_id": "PMID:36108046",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-01-20",
            "show_on_front_page": false
        },
        {
            "title": "Wang et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-01-20_36138017_CLIP170_Xi-wang-Jin-QW.png"],
            "content": "Ubiquitination of CLIP-170 family protein restrains polarized growth upon DNA replication stress.\nOriginally published in <a href='https://www.nature.com/articles/s41467-022-33311-y'>Nat Commun</a>.",
            "reference_id": "PMID:36138017",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-01-20",
            "show_on_front_page": false
        },
        {
            "title": "Poddar et al., 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-02-03-PMID-36200871-pkd2-chen.png"],
            "content": "Membrane stretching activates calcium-permeability of a putative channel Pkd2 during fission yeast cytokinesis..\nOriginally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E22-07-0248'>Molecular Biology of the Cell</a>.",
            "reference_id": "PMID:36200871",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-02-03",
            "show_on_front_page": false
        },
        {
            "title": "Foo et al., 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-03-05-36695178-DAG-Foo.png"],
            "content": "Diacylglycerol at the inner nuclear membrane fuels nuclear envelope expansion in closed mitosis..\nOriginally published in <a href='https://journals.biologists.com/jcs/article/136/3/jcs260568/286881/Diacylglycerol-at-the-inner-nuclear-membrane-fuels'>J Cell Sci</a>.",
            "reference_id": "PMID:36695178",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-03-05",
            "show_on_front_page": false
        },
        {
            "title": "Ohtsuka et al., 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-03-05-36779416-ecl3-Ohtsuka.png"],
            "content": "An ecl family gene ecl3+ is induced by phosphate starvation and contributes to sexual differentiation in fission yeast..\nOriginally published in <a href='https://journals.biologists.com/jcs/article/doi/10.1242/jcs.260759/287015/An-ecl-family-gene-ecl3-is-induced-by-phosphate'>J Cell Sci</a>.",
            "reference_id": "PMID:36779416",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-03-06",
            "show_on_front_page": false
        },
        {
            "title": "Deng et al, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-03-10-PPA2-Deng-PMID36006032.png"],
            "content": "Analysis of the potential role of fission yeast PP2A in spindle assembly checkpoint inactivation.\n\nOriginally published in <a href='https://doi.org/10.1096/fj.202101884R'>FASEB J</a>.",
            "reference_id": "PMID:36006032",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-03-10",
            "show_on_front_page": false
        },
        {
            "title": "Sun et al, 2015",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-03-10-anillin-Sun-PMID25959226.png"],
            "content": "Mechanistic insights into the anchorage of the contractile ring by anillin and Mid1.\n\nOriginally published in <a href='https://doi.org/10.1016/j.devcel.2015.03.003'>Dev Cell</a>.",
            "reference_id": "PMID:25959226",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-03-10",
            "show_on_front_page": false
        },
        {
            "title": "Moe et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-03-10-conplex-V-Moe-PMID36797353.png"],
            "content": "Cryo-EM structure and function of S. pombe complex IV with bound respiratory supercomplex factor.\n\nOriginally published in <a href='https://doi.org/10.1038/s42004-023-00827-3'>Commun Chem</a>.",
            "reference_id": "PMID:36797353",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-03-10",
            "show_on_front_page": false
        },
        {
            "title": "Asanuma et al, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-03-14-36617881-epe1-Asanuma.png"],
            "content": "Tandemly repeated genes promote RNAi-mediated heterochromatin formation via an antisilencing factor, Epe1, in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1101/gad.350129.122'>Genes Dev</a>.",
            "reference_id": "PMID:36617881",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-03-17",
            "show_on_front_page": false
        },
        {
            "title": "Jian et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-04-09-36537249-mhf1-2-fu.png"],
            "content": "The fission yeast kinetochore complex Mhf1-Mhf2 regulates the spindle assembly checkpoint and faithful chromosome segregation.\n\nOriginally published in <a href='https://doi.org/10.1242/jcs.260124'>J Cell Sci</a>.",
            "reference_id": "PMID:36537249",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-04-11",
            "show_on_front_page": false
        },
        {
            "title": "Panigrahi et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-04-20-3648124-wat1-pop1-TOR.png"],
            "content": "Critical role of Wat1/Pop3 in regulating the TORC1 signalling pathway in fission yeast S. pombe.\n\nOriginally published in <a href='https://doi.org/10.1016/j.fgb.2022.103764'>Fungal Genet Biol</a>.",
            "reference_id": "PMID:36481249",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-05-18",
            "show_on_front_page": false
        },
        {
            "title": "Hirano et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-08-PMID_36799444_lem2.png"],
            "content": "Inner nuclear membrane proteins Lem2 and Bqt4 interact with different lipid synthesis enzymes in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1093/jb/mvad017'>J Biochem</a>.",
            "reference_id": "PMID:36799444",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-08",
            "show_on_front_page": false
        },
        {
            "title": "Fukuda et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-13-PMID-37191320-hva22.png"],
            "content": "Hva22, a REEP family protein in fission yeast, promotes reticulophagy in collaboration with a receptor protein.\n\nOriginally published in <a href='https://doi.org/10.1080/15548627.2023.2214029'>Autophagy</a>.",
            "reference_id": "PMID:37191320",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-15",
            "show_on_front_page": false
        },
        {
            "title": "Fukuda et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-13-PMID-37192628-atg44.png"],
            "content": "The mitochondrial intermembrane space protein mitofissin drives mitochondrial fission required for mitophagy.\n\nOriginally published in <a href='https://doi.org/10.1016/j.molcel.2023.04.022'>Mol Cell</a>.",
            "reference_id": "PMID:37192628",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-15",
            "show_on_front_page": false
        },
        {
            "title": "Zareiesfandabadi et al, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-08-PMID-34951983-dhc1-klp2.png"],
            "content": "Force by minus-end motors Dhc1 and Klp2 collapses the S. pombe spindle after laser ablation.\n\nOriginally published in <a href='https://doi.org/10.1016/j.bpj.2021.12.019'>Biophys J</a>.",
            "reference_id": "PMID:34951983",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-15",
            "show_on_front_page": false
        },
        {
            "title": "Nishida et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-07-PMID_37156397-coq12.png" ],
            "content": "Identification of novel coenzyme Q10 biosynthetic proteins Coq11 and Coq12 in Schizosaccharomyces pombe.\n\nOriginally published in <a href='https://doi.org/10.1016/j.jbc.2023.104797'>J Biol Chem</a>.",
            "reference_id": "PMID:37156397",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-15",
            "show_on_front_page": false
        },
        {
            "title": "Mongia et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-18-PMID-37237082-srr1.png"],
            "content": "Fission yeast Srr1 and Skb1 promote isochromosome formation at the centromere.\n\nOriginally published in <a href='https://doi.org/10.1038/s42003-023-04925-9'>Commun Biol</a>.",
            "reference_id": "PMID:37237082",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-19",
            "show_on_front_page": false
        },
        {
            "title": "Wakiya et al, 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-19-33529549-dam1.png"],
            "content": "Chiasmata and the kinetochore component Dam1 are crucial for elimination of erroneous chromosome attachments and centromere oscillation at meiosis I.\n\nOriginally published in <a href='https://doi.org/10.1098/rsob.200308'>Open Biol</a>.",
            "reference_id": "PMID:33529549",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-20",
            "show_on_front_page": false
        },
        {
            "title": "Nambu et al, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-19-34851403-sister-KT.png"],
            "content": "Direct evaluation of cohesin-mediated sister kinetochore associations at meiosis I in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1242/jcs.259102'>J Cell Sci</a>.",
            "reference_id": "PMID:34851403",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-20",
            "show_on_front_page": false
        },
        {
            "title": "Rossi et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-19-37158439-fic1.png" ],
            "content": "The fission yeast cytokinetic ring component Fic1 promotes septum formation.\n\nOriginally published in <a href='https://doi.org/10.1242/bio.059957'>Biol Open</a>.",
            "reference_id": "PMID:37158439",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-20",
            "show_on_front_page": false
        },
        {
            "title": "Alam et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-20-37164017-TCA.png"],
            "content": "Optimization of energy production and central carbon metabolism in a non-respiring eukaryote.\n\nOriginally published in <a href='https://doi.org/10.1016/j.cub.2023.04.046'>Curr Biol</a>.",
            "reference_id": "PMID:37164017",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-20",
            "show_on_front_page": false
        },
        {
            "title": "Virant et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-06-27-PMID-36705602-KT.png"],
            "content": "Unraveling the kinetochore nanostructure in Schizosaccharomyces pombe using multi-color SMLM imaging.\n\nOriginally published in <a href='https://doi.org/10.1083/jcb.202209096'>J Cell Biol</a>.",
            "reference_id": "PMID:36705602",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-29",
            "show_on_front_page": false
        },
        {
            "title": "Esposito et al, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-06-30-PMID-35811551-Esposito-codon-bias-checkpoint.png"],
            "content": "Mitotic checkpoint gene expression is tuned by codon usage bias.\n\nOriginally published in <a href='https://doi.org/10.15252/embj.2021107896'>EMBO J</a>.",
            "reference_id": "PMID:35811551",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-06-30",
            "show_on_front_page": false
        },
        {
            "title": "Hiraoka et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-06-29-PMID_37128864-CDK-Hiraoko.png"
            ],
            "content": "CDK actively contributes to establishment of the stationary phase state in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1242/jcs.260727'>J Cell Sci</a>.",
            "reference_id": "PMID:37128864",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-07-11",
            "show_on_front_page": false
        },
        {
            "title": "Porat et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-07-11_PMID_37403782_bmc1.png"
            ],
            "content": "The fission yeast methyl phosphate capping enzyme Bmc1 guides 2'-O-methylation of the U6 snRNA.\n\nOriginally published in <a href='https://doi.org/10.1093/nar/gkad563'>Nucleic Acids Res</a>.",
            "reference_id": "PMID:37403782",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-07-11",
            "show_on_front_page": false
        },
        {
            "title": "Willet et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-08-11-PMID37531259-ank1-alaina.png"
                          ],
            "content": "Membrane binding of endocytic myosin-1s is inhibited by a class of ankyrin repeat proteins.\n\nOriginally published in <a href='https://doi.org/10.1091/mbc.E23-06-0233'>Mol Biol Cell</a>.",
            "reference_id": "PMID:37531259",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-08-11",
            "show_on_front_page": false
        },
        {
            "title": "Connor et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-08-18-PMID37540145-mdi1.png"
            ],
            "content": "Completion of mitochondrial division requires the intermembrane space protein Mdi1/Atg44.\n\nOriginally published in <a href='https://doi.org/10.1083/jcb.202303147'>J Cell Biol</a>.",
            "reference_id": "PMID:37540145",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-08-18",
            "show_on_front_page": false
        },
        {
            "title": "Garg et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-09-01-PMID-36794724-garg-maf1.png"
            ],
            "content": "Cellular responses to long-term phosphate starvation of fission yeast: Maf1 determines fate choice between quiescence and death associated with aberrant tRNA biogenesis.\n\nOriginally published in <a href='https://doi.org/10.1093/nar/gkad063'>Nucleic Acids Res</a>.",
            "reference_id": "PMID:36794724",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-09-02",
            "show_on_front_page": false
        },
        {
            "title": "Anil et al, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-09-01-PMID-36095128-tls1.png"
            ],
            "content": "Splicing of branchpoint-distant exons is promoted by Cactin, Tls1 and the ubiquitin-fold-activated Sde2.\n\nOriginally published in <a href='https://doi.org/10.1093/nar/gkac769'>Nucleic Acids Res</a>.",
            "reference_id": "PMID:36095128",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-09-02",
            "show_on_front_page": false
        },
        {
            "title": "He et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-08-30-PMID-37590302-yta4.png"
            ],
            "content": "The AAA-ATPase Yta4/ATAD1 interacts with the mitochondrial divisome to inhibit mitochondrial fission.\n\nOriginally published in <a href='https://doi.org/10.1371/journal.pbio.3002247'>PLoS Biol</a>.",
            "reference_id": "PMID:37590302",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-09-02",
            "show_on_front_page": false
        },
        {
            "title": "Elías-Villalobos et al, 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-09-07-31748520-TTT-tra1.png"
            ],
            "content": "Chaperone-mediated ordered assembly of the SAGA and NuA4 transcription co-activator complexes in yeast.\n\nOriginally published in <a href='https://doi.org/10.1038/s41467-019-13243-w'>Nat Commun</a>.",
            "reference_id": "PMID:31748520",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-09-06",
            "show_on_front_page": false
        },
        {
            "title": "Toullec et al, 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-09-07-34686329-TTT-co-translation.png"
            ],
            "content": "The Hsp90 cochaperone TTT promotes cotranslational maturation of PIKKs prior to complex assembly.\n\nOriginally published in <a href='https://doi.org/10.1016/j.celrep.2021.109867'>Cell Rep</a>.",
            "reference_id": "PMID:34686329",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-09-06",
            "show_on_front_page": false
        },
        {
            "title": "Rahayu et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2020-09-09-37400983-chp2.png"
            ],
            "content": "Cooperative DNA-binding activities of Chp2 are critical for its function in heterochromatin assembly.\n\nOriginally published in <a href='https://doi.org/10.1093/jb/mvad050'>J Biochem</a>.",
            "reference_id": "PMID:37400983",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-09-11",
            "show_on_front_page": false
        },
        {
            "title": "Ader et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-10-10-ader-ESCRT.png"
            ],
            "content": "An ESCRT grommet cooperates with a diffusion barrier to maintain nuclear integrity.\n\nOriginally published in <a href='https://doi.org/10.1038/s41556-023-01235-4'>Nat Cell Biol</a>.",
            "reference_id": "PMID:37783794",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-10-11",
            "show_on_front_page": false
        },
        {
            "title": "Willet et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-10-31-PMID37792890-MIPC.png"
            ],
            "content": "Elevated levels of sphingolipid MIPC in the plasma membrane disrupt the coordination of cell growth with cell wall formation in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1371/journal.pgen.1010987'>PLoS Genet</a>.",
            "reference_id": "PMID:37792890",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-10-31",
            "show_on_front_page": false
        },
        {
            "title": "Willet et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-10-31-PMID37815455-pik1.png"
            ],
            "content": "Characterization of Pik1 function in fission yeast reveals its conserved role in lipid synthesis and not cytokinesis.\n\nOriginally published in <a href='https://doi.org/10.1242/jcs.261415'>J Cell Sci</a>.",
            "reference_id": "PMID:37815455",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-10-31",
            "show_on_front_page": false
        },
        {
            "title": "Mori et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-11-01-PMID37859837-mito65.png"
            ],
            "content": "In fission yeast, 65 non-essential mitochondrial proteins related to respiration and stress become essential in low-glucose conditions.\n\nOriginally published in <a href='https://doi.org/10.1098/rsos.230404'>R Soc Open Sci</a>.",
            "reference_id": "PMID:37859837",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-11-01",
            "show_on_front_page": false
        },
        {
            "title": "Le et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2023-11-16-PMID37694715-doa10.png"
                          ],
            "content": "A ubiquitin-proteasome pathway degrades the inner nuclear membrane protein Bqt4 to maintain nuclear membrane homeostasis.\n\nOriginally published in <a href='https://doi.org/10.1242/jcs.260930'>J Cell Sci</a>.",
            "reference_id": "PMID:37694715",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-11-16",
            "show_on_front_page": false
        },
        {
            "title": "Strachan et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-11-16-PMID37970674-lem2.png"
            ],
            "content": "SUMOylation regulates Lem2 function in centromere clustering and silencing.\n\nOriginally published in <a href='https://doi.org/10.1242/jcs.260868'>J Cell Sci</a>.",
            "reference_id": "PMID:37970674",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-11-16",
            "show_on_front_page": false
        },
        {
            "title": "Sparks et al, 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2023-11-20-PMID-31563844-exo5.png"
            ],
            "content": "The roles of fission yeast exonuclease 5 in nuclear and mitochondrial genome stability.\n\nOriginally published in <a href='https://doi.org/10.1016/j.dnarep.2019.102720'>DNA Repair (Amst)</a>.",
            "reference_id": "PMID:31563844",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2023-11-20",
            "show_on_front_page": false
        },
        {
            "title": "Bednor et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-01-03-PMID38133430-bednor-tgp1.png"],
            "content": "Genetic suppressor screen identifies Tgp1 (glycerophosphocholine transporter), Kcs1 (IP6 kinase), and Plc1 (phospholipase C) as determinants of inositol pyrophosphate toxicosis in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1128/mbio.03062-23'>mBio</a>.",
            "reference_id": "PMID:38133430",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-01-03",
            "show_on_front_page": false
        },
        {
            "title": "Sorida et al, 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2024-01-03-PMID31206516-epe1.png"],
            "content": "Regulation of ectopic heterochromatin-mediated epigenetic diversification by the JmjC family protein Epe1.\n\nOriginally published in <a href='https://doi.org/10.1371/journal.pgen.1008129'>PLoS Genet</a>.",
            "reference_id": "PMID:31206516",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-01-03",
            "show_on_front_page": false
        },
        {
            "title": "Berg et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-01-03-PMID36574843-berg-cdr2.png"],
            "content": "Regulation of cell size and Wee1 kinase by elevated levels of the cell cycle regulatory protein kinase Cdr2.\n\nOriginally published in <a href='https://doi.org/10.1016/j.jbc.2022.102831'>J Biol Chem</a>.",
            "reference_id": "PMID:36574843",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-01-03",
            "show_on_front_page": false
        },
        {
            "title": "Ohshima et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-01-03-PMID37788281-14-3-3-makoto.png"],
            "content": "A dominant negative 14-3-3 mutant in Schizosaccharomyces pombe distinguishes the binding proteins involved in sexual differentiation and check point.\n\nOriginally published in <a href='https://doi.org/10.1371/journal.pone.0291524'>PLoS One</a>.",
            "reference_id": "PMID:37788281",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-01-03",
            "show_on_front_page": false
        },
        {
            "title": "Takado et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-01-18-PMID38166399-wee1-Takado.png"],
            "content": "Fission yeast Wee1 is required for stable kinetochore-microtubule attachment.\n\nOriginally published in <a href='https://doi.org/10.1098/rsob.230379'>Open Biol</a>.",
            "reference_id": "PMID:38166399",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-01-18",
            "show_on_front_page": false
        },
        {
            "title": "Tang et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-01-18-PMID37820734-tang-condensin.png"],
            "content": "Establishment of dsDNA-dsDNA interactions by the condensin complex.\n\nOriginally published in <a href='https://doi.org/10.1016/j.molcel.2023.09.019'>Mol Cell</a>.",
            "reference_id": "PMID:37820734",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-01-18",
            "show_on_front_page": false
        },
        {
            "title": "Hirai et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-01-18-PMID37913773-hirai-tor2.png"],
            "content": "TOR inactivation triggers heterochromatin formation in rDNA during glucose starvation.\n\nOriginally published in <a href='https://doi.org/10.1016/j.celrep.2023.113320'>Cell Rep</a>.",
            "reference_id": "PMID:37913773",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-01-18",
            "show_on_front_page": false
        },
        {
            "title": "Cao et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-02-05-PMID37572670.png"
            ],
            "content": "A peroxiredoxin-P38 MAPK scaffold increases MAPK activity by MAP3K-independent mechanisms.\n\nOriginally published in <a href='https://doi.org/10.1016/j.molcel.2023.07.018'>Mol Cell</a>.",
            "reference_id": "PMID:37572670",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-02-05",
            "show_on_front_page": false
        },
        {
            "title": "Kilchert et al, 2015",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-02-14-PMID-26670050-Kilchert-mmi1.png"
            ],
            "content": "Regulation of mRNA Levels by Decay-Promoting Introns that Recruit the Exosome Specificity Factor Mmi1.\n\nOriginally published in <a href='https://doi.org/10.1016/j.celrep.2015.11.026'>Cell Rep</a>.",
            "reference_id": "PMID:26670050",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2017-11-20",
            "show_on_front_page": false
        },
        {
            "title": "Doamekpor et al, 2014",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-03-04-PMID-24939935-pce1.png"
            ],
            "content": "How an mRNA capping enzyme reads distinct RNA polymerase II and Spt5 CTD phosphorylation codes.\n\nOriginally published in <a href='https://doi.org/10.1101/gad.242768.114'>Genes Dev</a>.",
            "reference_id": "PMID:24939935",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-03-04",
            "show_on_front_page": false
        },
        {
            "title": "Schwer et al, 2015",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-03-04-PMID26264592-CTD.png"
            ],
            "content": "RNA polymerase II CTD phospho-sites Ser5 and Ser7 govern phosphate homeostasis in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1261/rna.052555.115'>RNA</a>.",
            "reference_id": "PMID:26264592",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-03-04",
            "show_on_front_page": false
        },
        {
            "title": "Tasak et al, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-03-04-PMID35901126-trna-decay.png"
            ],
            "content": "Initiator tRNA lacking 1-methyladenosine is targeted by the rapid tRNA decay pathway in evolutionarily distant yeast species.\n\nOriginally published in <a href='https://doi.org/10.1371/journal.pgen.1010215'>PLoS Genet</a>.",
            "reference_id": "PMID:35901126",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-03-04",
            "show_on_front_page": false
        },
        {
            "title": "Nathanailidou et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-04-09-PMID38285941.png"
            ],
            "content": "Specialized replication of heterochromatin domains ensures self-templated chromatin assembly and epigenetic inheritance.\n\nOriginally published in <a href='https://doi.org/10.1073/pnas.2315596121'>Proc Natl Acad Sci U S A</a>.",
            "reference_id": "PMID:38285941",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-04-09",
            "show_on_front_page": false
        },
        {
            "title": "Lim et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": ["spotlight/2024-04-22-PMID_37445861-chen.png"
                          ],
            "content": "Resistance to Chemotherapeutic 5-Fluorouracil Conferred by Modulation of Heterochromatic Integrity through Ino80 Function in Fission Yeast.\n\nOriginally published in <a href='https://doi.org/10.3390/ijms241310687'>Int J Mol Sci</a>.",
            "reference_id": "PMID:37445861",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-04-22",
            "show_on_front_page": false
        },
        {
            "title": "Budden et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-04-22-PMID-37615341-rtf2.png"
            ],
            "content": "Schizosaccharomyces pombe Rtf2 is important for replication fork barrier activity of RTS1 via splicing of Rtf1.\n\nOriginally published in <a href='https://doi.org/10.7554/eLife.78554'>Elife</a>.",
            "reference_id": "PMID:37615341",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-04-22",
            "show_on_front_page": false
        },
        {
            "title": "Fang et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-04-22-PMID-38479839.png"
            ],
            "content": "Coordination of histone chaperones for parental histone segregation and epigenetic inheritance.\n\nOriginally published in <a href='https://doi.org/10.1101/gad.351278.123'>Genes Dev</a>.",
            "reference_id": "PMID:38479839",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-04-22",
            "show_on_front_page": false
        },
        {
            "title": "Morozumi et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-04-22-PMID-38269097-rapamycin.png"
            ],
            "content": "Rapamycin-sensitive mechanisms confine the growth of fission yeast below the temperatures detrimental to cell physiology.\n\nOriginally published in <a href='https://doi.org/10.1016/j.isci.2023.108777'>iScience</a>.",
            "reference_id": "PMID:38269097",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-04-22",
            "show_on_front_page": false
        },
        {
            "title": "Sun et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-04-22-PMID38289024-atf1.png"
            ],
            "content": "Heat stress-induced activation of MAPK pathway attenuates Atf1-dependent epigenetic inheritance of heterochromatin in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.7554/eLife.90525'>Elife</a>.",
            "reference_id": "PMID:38289024",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-04-22",
            "show_on_front_page": false
        },
        {
            "title": "Wang et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-04-22-PMID-37553386-yop1.png"
            ],
            "content": "A conserved membrane curvature-generating protein is crucial for autophagosome formation in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1038/s41467-023-40530-4'>Nat Commun</a>.",
            "reference_id": "PMID:37553386",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-04-22",
            "show_on_front_page": false
        },
        {
            "title": "Tavafoghi et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-04-22-PMID-38633868-dfg501.png"
            ],
            "content": "Generation and characterization of temperature-sensitive alleles encoding GPI anchored proteins Psu1 and Dfg502 in Schizosaccharomyces pombe.\n\nOriginally published in <a href='https://doi.org/10.17912/micropub.biology.001179'>MicroPubl Biol</a>.",
            "reference_id": "PMID:38633868",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-04-22",
            "show_on_front_page": false
        },
        {
            "title": "Liu et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-05-10-COMPASS-CLRC-PMID38181050.png"
            ],
            "content": "The Cross-Regulation Between Set1, Clr4, and Lsd1/2 in Schizosaccharomyces pombe.\n\nOriginally published in <a href='https://doi.org/10.1371/journal.pgen.1011107'>PLoS Genet</a>.",
            "reference_id": "PMID:38181050",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-05-10",
            "show_on_front_page": false
        },
        {
            "title": "Takado et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-05-23-PMID37949217-phosphate-export.png"
            ],
            "content": "Phosphate uptake restriction, phosphate export, and polyphosphate synthesis contribute synergistically to cellular proliferation and survival.\n\nOriginally published in <a href='https://doi.org/10.1016/j.jbc.2023.105454'>J Biol Chem</a>.",
            "reference_id": "PMID:37949217",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-05-23",
            "show_on_front_page": false
        },
        {
            "title": "Maki et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-06-27-PMID-36951094-swi2-swi5.png"
            ],
            "content": "Fission yeast Swi2 designates cell-type specific donor and stimulates Rad51-driven strand exchange for mating-type switching.\n\nOriginally published in <a href='https://doi.org/10.1093/nar/gkad204'>Nucleic Acids Res</a>.",
            "reference_id": "PMID:36951094",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-06-27",
            "show_on_front_page": false
        },
        {
            "title": "Hati et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-06-27-PMID-37923140-grx4-fra2.png"
            ],
            "content": "Iron homeostasis proteins Grx4 and Fra2 control activity of the Schizosaccharomyces pombe iron repressor Fep1 by facilitating [2Fe-2S] cluster removal.\n\nOriginally published in <a href='https://doi.org/10.1016/j.jbc.2023.105419'>J Biol Chem</a>.",
            "reference_id": "PMID:37923140",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-06-27",
            "show_on_front_page": false
        },
        {
            "title": "Kumar et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-06-27-PMID-38692277-micos.png"
            ],
            "content": "A dynamin superfamily-like pseudoenzyme coordinates with MICOS to promote cristae architecture.\n\nOriginally published in <a href='https://doi.org/10.1016/j.cub.2024.04.028'>Curr Biol</a>.",
            "reference_id": "PMID:38692277",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-06-27",
            "show_on_front_page": false
        },
        {
            "title": "Kume et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-06-27-PMID-38442865-mor2.png"
            ],
            "content": "The fission yeast NDR kinase Orb6 and its signalling pathway MOR regulate cytoplasmic microtubule organization during the cell cycle.\n\nOriginally published in <a href='https://doi.org/10.1098/rsob.230440'>Open Biol</a>.",
            "reference_id": "PMID:38442865",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-07-01",
            "show_on_front_page": false
        },
        {
            "title": "Ren et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-07-16-PMID-37831774-berro-end4.png"
            ],
            "content": "Force redistribution in clathrin-mediated endocytosis revealed by coiled-coil force sensors.\n\nOriginally published in <a href='https://doi.org/10.1126/sciadv.adi1535'>Sci Adv</a>.",
            "reference_id": "PMID:37831774",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-07-16",
            "show_on_front_page": false
        },
        {
            "title": "Fletcher et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-07-16-PMID-38938413-spg1-mutants.png"
            ],
            "content": "Characterization of temperature-sensitive Schizosaccharomyces pombe mutants in the septation initiation network Spg1 GTPase.\n\nOriginally published in <a href='https://doi.org/10.17912/micropub.biology.001193'>MicroPubl Biol</a>.",
            "reference_id": "PMID:38938413",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-07-16",
            "show_on_front_page": false
        },
        {
            "title": "Willet et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-07-16-PMID-38865179-SIP-complex.png"
            ],
            "content": "Transient PP2A SIP complex localization to mitotic SPBs for SIN inhibition is mediated solely by the Csc1 FHA domain.\n\nOriginally published in <a href='https://doi.org/10.1091/mbc.E24-04-0196'>Mol Biol Cell</a>.",
            "reference_id": "PMID:38865179",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-07-16",
            "show_on_front_page": false
        },
        {
            "title": "Schwer et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-07-29-PMID38899862.png"
            ],
            "content": "Suppression of inositol pyrophosphate toxicosis and hyper-repression of the fission yeast PHO regulon by loss-of-function mutations in chromatin remodelers Snf22 and Sol1.\n\nOriginally published in <a href='https://doi.org/10.1128/mbio.01252-24'>mBio</a>.",
            "reference_id": "PMID:38899862",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-07-29",
            "show_on_front_page": false
        },
        {
            "title": "Chen et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-08-01-PMID38985524.png"
            ],
            "content": "The core spindle pole body scaffold Ppc89 links the pericentrin orthologue Pcp1 to the fission yeast spindle pole body via an evolutionarily conserved interface.\n\nOriginally published in <a href='https://doi.org/10.1091/mbc.E24-05-0220'>Mol Biol Cell</a>.",
            "reference_id": "PMID:38985524",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-08-01",
            "show_on_front_page": false
        },
        {
            "title": "Turner et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-08-01-PMID38989013.png"
            ],
            "content": "Characterization of temperature-sensitive alleles of Schizosaccharomyces pombe septation initiation network components.\n\nOriginally published in <a href='https://doi.org/10.17912/micropub.biology.001249'>MicroPubl Biol</a>.",
            "reference_id": "PMID:38989013",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-08-01",
            "show_on_front_page": false
        },
        {
            "title": "Ouasti et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-08-02-PMID38376141.png"
            ],
            "content": "Disordered regions and folded modules in CAF-1 promote histone deposition in Schizosaccharomyces pombe.\n\nOriginally published in <a href='https://doi.org/10.7554/eLife.91461'>Elife</a>.",
            "reference_id": "PMID:38376141",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-08-02",
            "show_on_front_page": false
        },
        {
            "title": "Marešová et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-08-02-PMID38482739.png"
            ],
            "content": "Critical importance of DNA binding for CSL protein functions in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1242/jcs.261568'>J Cell Sci</a>.",
            "reference_id": "PMID:38482739",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-08-02",
            "show_on_front_page": false
        },
        {
            "title": "Schirmeisen et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-08-02-PMID38917328.png"
            ],
            "content": "SUMO protease and proteasome recruitment at the nuclear periphery differently affect replication dynamics at arrested forks.\n\nOriginally published in <a href='https://doi.org/10.1093/nar/gkae526'>Nucleic Acids Res</a>.",
            "reference_id": "PMID:38917328",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-08-02",
            "show_on_front_page": false
        },
        {
            "title": "Ghosh et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-08-02-PMID38940614.png"
            ],
            "content": "Activities and genetic interactions of fission yeast Aps1, a Nudix-type inositol pyrophosphatase and inorganic polyphosphatase.\n\nOriginally published in <a href='https://doi.org/10.1128/mbio.01084-24'>mBio</a>.",
            "reference_id": "PMID:38940614",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-08-02",
            "show_on_front_page": false
        },
        {
            "title": "Kobayashi-Ooka et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-09-09-PMID38913087.png"
            ],
            "content": "Mutational analyses of the interacting domains of Schizosaccharomyces pombe Byr2 with 14-3-3s.\n\nOriginally published in <a href='https://doi.org/10.1007/s00294-024-01293-7'>Curr Genet</a>.",
            "reference_id": "PMID:38913087",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-09-09",
            "show_on_front_page": false
        },
        {
            "title": "Willet et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-09-13-PMID-39239853.png"
            ],
            "content": "Fission yeast Duc1 links to ER-PM contact sites and influences PM lipid composition and cytokinetic ring anchoring.\n\nOriginally published in <a href='https://doi.org/10.1242/jcs.262347'>J Cell Sci</a>.",
            "reference_id": "PMID:39239853",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-09-13",
            "show_on_front_page": false
        },
        {
            "title": "Onwubiko et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-09-27-PMID37039135.png"
            ],
            "content": "Cdc42 prevents precocious Rho1 activation during cytokinesis in a Pak1-dependent manner.\n\nOriginally published in <a href='https://doi.org/10.1242/jcs.261160'>J Cell Sci</a>.",
            "reference_id": "PMID:37039135",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-09-27",
            "show_on_front_page": false
        },
        {
            "title": "Luo et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-09-29-PMID39289458.png"
            ],
            "content": "Characterization of Shy1, the Schizosaccharomyces pombe homolog of human SURF1.\n\nOriginally published in <a href='https://doi.org/10.1038/s41598-024-72681-9'>Sci Rep</a>.",
            "reference_id": "PMID:39289458",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-09-29",
            "show_on_front_page": false
        },
        {
            "title": "Ohtsuka et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-10-09-sdr1-PMID39289458.png"
            ],
            "content": "A novel transcription factor Sdr1 involving sulfur depletion response in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1111/gtc.13136'>Genes Cells</a>.",
            "reference_id": "PMID:39105351",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-10-09",
            "show_on_front_page": false
        },
        {
            "title": "Palacios-Blanco et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-10-16-PMID39174851.png"
            ],
            "content": "CDK phosphorylation of Sfr1 downregulates Rad51 function in late-meiotic homolog invasions.\n\nOriginally published in <a href='https://doi.org/10.1038/s44318-024-00205-2'>EMBO J</a>.",
            "reference_id": "PMID:39174851",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-10-16",
            "show_on_front_page": false
        },
        {
            "title": "Sakai et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-10-16-PMID38889144.png"
            ],
            "content": "Cytoplasmic fluidization contributes to breaking spore dormancy in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1073/pnas.2405553121'>Proc Natl Acad Sci U S A</a>.",
            "reference_id": "PMID:38889144",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-10-16",
            "show_on_front_page": false
        },
        {
            "title": "Wang et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-10-29-PMID38499152.png"
            ],
            "content": "Sls1 and Mtf2 mediate the assembly of the Mrh5C complex required for activation of cox1 mRNA translation.\n\nOriginally published in <a href='https://doi.org/10.1016/j.jbc.2024.107176'>J Biol Chem</a>.",
            "reference_id": "PMID:38499152",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-10-29",
            "show_on_front_page": false
        },
        {
            "title": "Gemin et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-10-29-PMID39379376.png"
            ],
            "content": "Ribosomes hibernate on mitochondria during cellular stress.\n\nOriginally published in <a href='https://doi.org/10.1038/s41467-024-52911-4'>Nat Commun</a>.",
            "reference_id": "PMID:39379376",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-10-29",
            "show_on_front_page": false
        },
        {
            "title": "Facchetti et al, 2019",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-10-29-PMID30639107.png"
            ],
            "content": "Reprogramming Cdr2-Dependent Geometry-Based Cell Size Control in Fission Yeast.\n\nOriginally published in <a href='https://doi.org/10.1016/j.cub.2018.12.017'>Curr Biol</a>.",
            "reference_id": "PMID:30639107",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-10-29",
            "show_on_front_page": false
        },
        {
            "title": "Rutkowski et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-10-29-PMID39333500.png"
            ],
            "content": "Cdc42 mobility and membrane flows regulate fission yeast cell shape and survival.\n\nOriginally published in <a href='https://doi.org/10.1038/s41467-024-52655-1'>Nat Commun</a>.",
            "reference_id": "PMID:39333500",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-10-29",
            "show_on_front_page": false
        },
        {
            "title": "Bustamante-Jaramillo et al, 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-10-29-PMID34067465.png"
            ],
            "content": "The Meiosis-Specific Crs1 Cyclin Is Required for Efficient S-Phase Progression and Stable Nuclear Architecture.\n\nOriginally published in <a href='https://doi.org/10.3390/ijms22115483'>Int J Mol Sci</a>.",
            "reference_id": "PMID:34067465",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-10-29",
            "show_on_front_page": false
        },
        {
            "title": "Hanna et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-11-06-ppc89-PMID39471327.png"
            ],
            "content": "New mutations in the core Schizosaccharomyces pombe spindle pole body scaffold Ppc89 reveal separable functions in regulating cell division.\n\nOriginally published in <a href='https://doi.org/10.1093/g3journal/jkae249'>G3 (Bethesda)</a>.",
            "reference_id": "PMID:39471327",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-11-06",
            "show_on_front_page": true
        },
        {
            "title": "Luo et al, 2021",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-11-06-ppr10-mpa1-PMID34119521.png"
            ],
            "content": "Schizosaccharomyces pombe Ppr10 and Mpa1 together mediate mitochondrial translational initiation.\n\nOriginally published in <a href='https://doi.org/10.1016/j.jbc.2021.100869'>J Biol Chem</a>.",
            "reference_id": "PMID:34119521",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-11-06",
            "show_on_front_page": true
        },
        {
            "title": "Hoh et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-11-06-VAP-PMID39110593.png"
            ],
            "content": "VAP-mediated membrane-tethering mechanisms implicate ER-PM contact function in pH homeostasis.\n\nOriginally published in <a href='https://doi.org/10.1016/j.celrep.2024.114592'>Cell Rep</a>.",
            "reference_id": "PMID:39110593",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-11-06",
            "show_on_front_page": true
        },
        {
            "title": "Larkin et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-11-14-PMID39094565.png"
            ],
            "content": "Mapping the dynamics of epigenetic adaptation in S. pombe during heterochromatin misregulation.\n\nOriginally published in <a href='https://doi.org/10.1016/j.devcel.2024.07.006'>Dev Cell</a>.",
            "reference_id": "PMID:39094565",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-11-14",
            "show_on_front_page": true
        },
        {
            "title": "Anver et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-11-14-PMID39358553.png"
            ],
            "content": "Ageing-associated long non-coding RNA extends lifespan and reduces translation in non-dividing cells.\n\nOriginally published in <a href='https://doi.org/10.1038/s44319-024-00265-9'>EMBO Rep</a>.",
            "reference_id": "PMID:39358553",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-11-14",
            "show_on_front_page": true
        },
        {
            "title": "Yao et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-12-12-PMID38272226.png"
            ],
            "content": "E3 ubiquitin ligase Hul6 modulates iron-dependent metabolism by regulating Php4 stability.\n\nOriginally published in <a href='https://doi.org/10.1016/j.jbc.2024.105670'>J Biol Chem</a>.",
            "reference_id": "PMID:38272226",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-12-12",
            "show_on_front_page": true
        },
        {
            "title": "Sivakova et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-12-12-PMID39367033.png"
            ],
            "content": "Quantitative proteomics and phosphoproteomics profiling of meiotic divisions in the fission yeast Schizosaccharomyces pombe.\n\nOriginally published in <a href='https://doi.org/10.1038/s41598-024-74523-0'>Sci Rep</a>.",
            "reference_id": "PMID:39367033",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-12-12",
            "show_on_front_page": true
        },
        {
            "title": "Cipak et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-12-12-PMID39476757.png"
            ],
            "content": "Characterization of Ksg1 protein kinase-dependent phosphoproteome in the fission yeast S. pombe.\n\nOriginally published in <a href='https://doi.org/10.1016/j.bbrc.2024.150895'>Biochem Biophys Res Commun</a>.",
            "reference_id": "PMID:39476757",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-12-12",
            "show_on_front_page": true
        },
        {
            "title": "Grochowski et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2024-12-16-PMID-39333464-uridylation.png"
            ],
            "content": "Uridylation regulates mRNA decay directionality in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1038/s41467-024-50824-w'>Nat Commun</a>.",
            "reference_id": "PMID:39333464",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-12-16",
            "show_on_front_page": true
        },
        {
            "title": "Vega et al, 2022",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2025-01-01-PMID-35820914-mito-protease-vega.png"
            ],
            "content": "Antagonistic effects of mitochondrial matrix and intermembrane space proteases on yeast aging.\n\nOriginally published in <a href='https://doi.org/10.1186/s12915-022-01352-w'>BMC Biol</a>.",
            "reference_id": "PMID:35820914",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-12-31",
            "show_on_front_page": true
        },
        {
            "title": "Vega et al, 2023",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2025-01-01-PMID-37956308-top1.png"
            ],
            "content": "Topoisomerase 1 facilitates nucleosome reassembly at stress genes during recovery.\n\nOriginally published in <a href='https://doi.org/10.1093/nar/gkad1066'>Nucleic Acids Res</a>.",
            "reference_id": "PMID:37956308",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-12-31",
            "show_on_front_page": true
        },
        {
            "title": "De Zoysa et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2025-01-01-PMID-38295128-phiziky-tan1.png"
            ],
            "content": "A connection between the ribosome and two S. pombe tRNA modification mutants subject to rapid tRNA decay.\n\nOriginally published in <a href='https://doi.org/10.1371/journal.pgen.1011146'>PLoS Genet</a>.",
            "reference_id": "PMID:38295128",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-12-31",
            "show_on_front_page": true
        },
        {
            "title": "Borao et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2025-01-01-PMID-38833506-saf5.png"
            ],
            "content": "A systematic screen identifies Saf5 as a link between splicing and transcription in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1371/journal.pgen.1011316'>PLoS Genet</a>.",
            "reference_id": "PMID:38833506",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-12-31",
            "show_on_front_page": true
        },
        {
            "title": "Innokentev et al, 2024",
            "title_link": null,
            "panel_type": "spotlight",
            "head_image": [
                "spotlight/2025-01-01-PMID-39660919-efn1-2.png"
            ],
            "content": "Efn1 and Efn2 are extracellular 5'-nucleotidases induced during the fission yeast response to phosphate starvation.\n\nOriginally published in <a href='https://doi.org/10.1128/mbio.02992-24'>mBio</a>.",
            "reference_id": "PMID:39660919",
            "link_label": "Publication record in PomBase ...",
            "date_added": "2024-12-31",
            "show_on_front_page": true
        },
	{
    		"title": "Hua et al, 2024",
    		"title_link": null,
    		"panel_type": "spotlight",
    		"head_image": [
        		"spotlight/2025-01-13-PMID39485795.png"
    		],
    		"content": "A meiotic driver hijacks an epigenetic reader to disrupt mitosis in noncarrier offspring.\n\nOriginally published in <a href='https://doi.org/10.1073/pnas.2408347121'>Proc Natl Acad Sci U S A</a>.",
    		"reference_id": "PMID:39485795",
    		"link_label": "Publication record in PomBase ...",
    		"date_added": "2025-01-13",
    		"show_on_front_page": true
	},
	{
    		"title": "Hua et al, 2024",
    		"title_link": null,
    		"panel_type": "spotlight",
    		"head_image": [
        		"spotlight/2025-01-13-PMID39485800.png"
    		],
    		"content": "Structural duality enables a single protein to act as a toxin-antidote pair for meiotic drive.\n\nOriginally published in <a href='https://doi.org/10.1073/pnas.2408618121'>Proc Natl Acad Sci U S A</a>.",
    		"reference_id": "PMID:39485800",
    		"link_label": "Publication record in PomBase ...",
    		"date_added": "2025-01-13",
    		"show_on_front_page": true
	},
	{
    		"title": "Marešová et al, 2024",
    		"title_link": null,
    		"panel_type": "spotlight",
    		"head_image": [
        		"spotlight/2025-01-17-PMID39652606.png"
    		],
    		"content": "Cbf11 and Mga2 function together to activate transcription of lipid metabolism genes and promote mitotic fidelity in fission yeast.\n\nOriginally published in <a href='https://doi.org/10.1371/journal.pgen.1011509'>PLoS Genet</a>.",
    		"reference_id": "PMID:39652606",
    		"link_label": "Publication record in PomBase ...",
    		"date_added": "2025-01-17",
    		"show_on_front_page": true
	},
        {
            "title": "Recent community curation",
            "panel_type": "community",
            "head_image": [
            ],
            "head_image_link": "https://curation.pombase.org/pombe/stats/annotation",
            "content": "<app-recent-community-pubs></app-recent-community-pubs>",
            "date_added": "2017-10-10",
            "show_on_front_page": true
        },
        {
            "title": "Step into the unknown",
            "panel_type": "explore",
            "head_image": [
                "explore/2017-10-08-unknowns.png"
            ],
            "content": "Browse PomBase's inventory of conserved proteins with no known biological role",
            "link": "/status/priority-unstudied-genes",
            "link_label": "more ...",
            "date_added": "2017-10-10",
            "show_on_front_page": false
        },
        {
            "title": "Biological process overview",
            "panel_type": "explore",
            "head_image": [
                "explore/2017-10-08-slimming.png"
            ],
            "content": "The fission yeast [GO slim](browse-curation/fission-yeast-go-slim-terms) provides a summary of genes annotated to broad biological processes",
            "date_added": "2017-10-10",
            "show_on_front_page": false
        },
        {
            "title": "Data mining",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2017-10-13-data_mining.png"
            ],
            "content": "Use the [Advanced Search](/query) to construct complex queries (GO, phenotypes, taxonomic distribution, domain, chromosomal location).",
            "date_added": "2017-10-13",
            "show_on_front_page": false
        },
        {
            "title": "Over 200,000 curated annotations",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2017-11-06-200_000_curated_annotations.png"
            ],
            "content": "PomBase has integrated over 200,000 manually curated gene-specific annotations. See our [Resource Metrics](about/resource-metrics) page for more literature curation progress reports.",
            "date_added": "2017-11-06",
            "show_on_front_page": false
        },
        {
            "title": "Building networks",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2017-11-06-building_networks.png"
            ],
            "content": "PomBase uses manually curated Gene Ontology annotation to generate biological process-specific networks, such as the cytokinesis network excerpted above. Currently available networks are linked to the [GO slim](browse-curation/fission-yeast-go-slim-terms) page.",
            "date_added": "2017-11-06",
            "show_on_front_page": false
        },
        {
            "title": "Help!",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2017-11-06-help.png"
            ],
            "content": "PomBase provides extensive documentation and answers many questions in the [FAQ](faq). For any information you can't find there, please <app-contact-email [linkName]=\"'contact the helpdesk'\" [subject]=\"'PomBase question'\"></app-contact-email>.",
            "date_added": "2017-11-06",
            "show_on_front_page": false
        },
        {
            "title": "Community curation in Canto",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2017-11-06-community_curation.png"
            ],
            "content": "PomBase has pioneered a community curation system that enables researchers to contribute publication-based annotations directly to database curators. To participate, search for the PubMed ID of your paper [in Canto](https://curation.pombase.org/pombe) or <app-contact-email [linkName]=\"'contact the helpdesk'\" [subject]=\"'PomBase question'\"></app-contact-email>.",
            "date_added": "2017-11-06",
            "show_on_front_page": false
        },
        {
            "title": "The naming of parts",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2017-11-09-name_that_gene.png"
            ],
            "content": "4020 of the 5070 fission yeast protein-coding genes now have assigned standard names. We encourage all authors and reviewers to ensure that gene names are registered prior to publication. Visit our [gene registry page](gene-names) and the [<em>S. pombe</em> gene naming guidelines](gene-names/gene-naming-guidelines) for more information.",
            "date_added": "2017-11-06",
            "show_on_front_page": false
        },
        {
            "title": "Disease gene orthologs",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2017-11-06-disease_gene_orthologs.png"
            ],
            "content": "Of the 3526 *S. pombe* with identified human orthologs, over 500 have been implicated in disease. These are manually annotated with terms from a small in-house vocabulary, and can be searched as described in the [disease gene FAQ](faq/how-can-i-find-s.-pombe-genes-associated-human-disease).",
            "date_added": "2017-11-06",
            "show_on_front_page": false
        },
        {
            "title": "Methods and Protocols chapter",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2018-05-21-pombase-book-chapter.png"
            ],
            "content": "A guide to getting the most from PomBase, and to its curation philosophy and data organization. [Free download](https://link.springer.com/protocol/10.1007/978-1-4939-7737-6_4) thanks to the [Wellcome Trust](https://wellcome.ac.uk/).\n\n[View chapter ...](https://link.springer.com/protocol/10.1007/978-1-4939-7737-6_4)",
            "date_added": "2018-05-21",
            "show_on_front_page": false
        },
        {
            "title": "Browse our inventory of conserved proteins with no known biological role",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2017-11-17-unknowns.png"
            ],
            "content": "Unknown process now down to 696, [conserved unknowns 410](/gene_subset/characterisation_status_conserved_unknown).\nOur \"Unknowns\" manuscript is now available on bioRxiv: [DOI: 10.1101/469569](https://www.biorxiv.org/content/early/2018/11/16/469569).",
            "date_added": "2018-11-17",
            "show_on_front_page": false
        },
        {
            "title": "25% of fission yeast proteins have disease associations",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2019-01-27-disease.png"
            ],
            "content": "Access [Disease associations](/term/MONDO:0000001) for 907 pombe genes (up from 610), includes new associations derived from MalaCards. This number will increase as work is still in progress...",
            "date_added": "2019-01-27",
            "show_on_front_page": false
        },
        {
            "title": "Browse our inventory of conserved proteins with no known biological role",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2017-11-17-unknowns.png"
            ],
            "content": "Our \"unknowns\" analysis is now published in [Open Biology](https://royalsocietypublishing.org/doi/10.1098/rsob.180241). You can browse the [list of fission yeast unknown proteins](status/priority-unstudied-genes), and use the \"Visualise\" button to explore the data interactively in QuiLT, the new tool inspired by [Figure 4](https://royalsocietypublishing.org/doi/10.1098/rsob.180241#RSOB180241F4).",
            "date_added": "2019-02-20",
            "show_on_front_page": true
        },
        {
            "title": "GO slim analysis for any gene list",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2019-03-05-slim-list.png"
            ],
            "content": "PomBase's advanced search now allows you to retrieve [GO slim](documentation/pombase-go-slim-documentation) annotations for any set of search results. Combine with the \"Gene IDs\" query option to find GO slim annotations for your own list of genes.",
            "date_added": "2019-03-05",
            "show_on_front_page": true
        },
        {
            "title": "Motif search updated",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2017-11-06-motif_search.png"
            ],
            "content": "The PomBase [Protein Motif Search Tool](/motif_search) allows you to search for any user-defined amino acid sequence, supporting exact matches, wildcards, gaps, and ambiguity codes. This search is now fully integrated into PomBase, allowing results to be sent directly to the [advanced search](/query).",
            "date_added": "2019-03-19",
            "show_on_front_page": false
        },
        {
            "title": "The PomBase genome browser",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2019-09-05-explore-JBrowse.png"
            ],
            "content": "The PomBase [JBrowse instance](https://www.pombase.org/jbrowse/) provides a responsive online environment in which to visualise published genomic datasets. For more information please see our [JBrowse documentation page](https://www.pombase.org/documentation/JBrowse_quick_start). Authors are welcome to submit their published data to PomBase for hosting.",
            "date_added": "2019-09-06",
            "show_on_front_page": false
        },
        {
            "title": "PomBase community curation",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2020-05-01-community_curation.png"
            ],
            "content": "Authors now contribute curation to PomBase for almost all new fission yeast publications. Find out how you can benefit in our [new paper on community curation](https://academic.oup.com/database/article/doi/10.1093/database/baaa028/5827230), and visit the [*S. pombe* Canto home](https://curation.pombase.org/pombe) to participate.",
            "date_added": "2020-05-01",
            "show_on_front_page": false
        },
        {
            "title": "25% of pombe proteins have disease associations",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2019-01-27-disease.png"
            ],
            "content": "Access [Disease associations](/term/MONDO:0000001) for 1239 of the 3540 pombe genes with human orthologs (up from 987 in 2019), using the MONDO disease ontology. Or browse the [disease slim set here](https://www.pombase.org/browse-curation/disease-slim).",
            "date_added": "2020-08-07",
            "show_on_front_page": false
        },
        {
            "title": "Fission yeast microPublications",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2021-06-08-pombe-micropublications.png"
            ],
            "content": "[*S. pombe* microPublications](https://www.micropublication.org/journals/biology/species/s-pombe/) describe brief, novel findings, negative and/or reproduced results, and results which may lack a broader scientific narrative. Find microPublications in the PomBase simple search, or [submit your own](https://www.micropublication.org/submit-new-article/).",
            "date_added": "2021-06-08",
            "show_on_front_page": true
        },
        {
            "title": "Disease associations for fission yeast proteins",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2019-01-27-disease.png"
            ],
            "content": "Browse [Disease associations](/term/MONDO:0000001), annotated using the Mondo disease ontology, for 1401 (up from 907 in 2019 and 1239 in 2020) of the 3560 *S. pombe* genes that have human orthologs. See the [disease slim set](/browse-curation/disease-slim) for a summary view.",
            "date_added": "2021-08-06",
            "show_on_front_page": false
        },
        {
            "title": "New disease associations for fission yeast proteins",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2019-01-27-disease.png"
            ],
            "content": "Browse [Disease associations](/term/MONDO:0000001), annotated using the Mondo disease ontology, for 1471 (up from 1401 in August 2021) of the 3597 *S. pombe* genes that have human orthologs. See the [disease slim set](/browse-curation/disease-slim) for a summary view.",
            "date_added": "2022-06-23",
            "show_on_front_page": true
        },
        {
            "title": "PomBase's 'sister' database for fission yeast Schizosaccharomyces japonicus",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2023-02-13-explore-JaponicusDB.png"
            ],
            "content": "We cloned PomBase to create a fully community-maintained resource for the [emerging model S. japonicus](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3789812/); providing genome browser, literature curation, and query tools.\n\n[Visit JaponicusDB](https://www.japonicusdb.org/)",
            "date_added": "2023-02-13",
            "show_on_front_page": true
        },
        {
            "title": "New documentation and videos for Canto",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2023-11-29-new-canto-video-docs.png"
            ],
            "content": "[A new website](https://pombase.github.io/canto_tutorial/) with videos and documentation for [Canto](https://curation.pombase.org/), our community curation tool. The website includes short step-by-step text and video guides on how to make annotations in Canto. \n\n[Visit the new Canto documentation](https://pombase.github.io/canto_tutorial/)",
            "date_added": "2023-11-29",
            "show_on_front_page": true
        },
        {
            "title": "Protein-coding gene characterisation status",
            "title_link": null,
            "panel_type": "explore",
            "head_image": [
                "explore/2023-11-29-gene-characterisation-status.png"
            ],
            "content": "Although over half of fission yeast proteins are now published in small-scale experiments, and the roles of a further >1900 can be inferred, there are still 616 of unknown process, of these 356 are conserved outside the fission yeast, 126 as far as human. \n\n[View the protein characterization graph](https://www.pombase.org/status/gene-characterisation)",
            "date_added": "2023-11-29",
            "show_on_front_page": true
        }
    ],
    "predefined_queries": {
        "priority-unstudied-genes:unknown_genes": {
            "constraints": {
                "or": [
                    {
                        "subset": {
                            "subset_name": "characterisation_status_Schizosaccharomyces_pombe_specific_protein_uncharacterized"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "characterisation_status_Schizosaccharomyces_specific_protein_uncharacterized"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "characterisation_status_conserved_unknown"
                        }
                    }
                ]
            }
        },
        "priority-unstudied-genes:conserved_unknown": {
            "constraints": {
                "subset": {
                    "subset_name": "characterisation_status_conserved_unknown"
                }
            }
        },
        "priority-unstudied-genes:conserved_unknown_AND_conserved_in_vertebrates": {
            "name": "Priority unstudied genes (vertebrate orthologs, unknown process, protein coding)",
            "constraints": {
                "and": [
                    {
                        "term": {
                            "termid": "PBO:0011070",
                            "name": "conserved in vertebrates",
                            "single_or_multi_locus": null,
                            "expression": null
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "characterisation_status_conserved_unknown"
                        }
                    }
                ]
            }
        },
        "canned_query:all_diseases": {
            "name": "All disease associated genes",
            "constraints": {
                "or": [
                    {
                        "node_name": "genes annotated with \"disease susceptibility\" (MONDO:0042489)",
                        "term": {
                            "termid": "MONDO:0042489",
                            "name": "disease susceptibility",
                            "ploidiness": null,
                            "expression": null
                        }
                    },
                    {
                        "node_name": "genes annotated with \"disease\" (MONDO:0000001)",
                        "term": {
                            "termid": "MONDO:0000001",
                            "name": "disease",
                            "ploidiness": null,
                            "expression": null
                        }
                    }
                ],
                "node_name": "genes annotated with \"disease\" (MONDO:0000001) OR genes annotated with \"disease susceptibility\" (MONDO:0042489)"
            }
        },
        "canned_query:all_protein_coding_ex_dubious_and_transposon": {
            "name": "All protein coding genes (excluding dubious and transposon)",
            "constraints": {
                "not": [
                    {
                        "not": [
                            {
                                "subset": {
                                    "subset_name": "feature_type_mRNA_gene"
                                }
                            },
                            {
                                "subset": {
                                    "subset_name": "characterisation_status_transposon"
                                }
                            }
                        ]
                    },
                    {
                        "subset": {
                            "subset_name": "characterisation_status_dubious"
                        }
                    }
                ]
            }
        },
        "canned_query:protein_coding_genes_unknown_process": {
            "name": "Protein coding genes, unknown process",
            "constraints": {
                "or": [
                    {
                        "subset": {
                            "subset_name": "characterisation_status_conserved_unknown"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "characterisation_status_Schizosaccharomyces_pombe_specific_protein_uncharacterized"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "characterisation_status_Schizosaccharomyces_specific_protein_uncharacterized"
                        }
                    }
                ]
            }
        },
        "single_allele_chemical_resistance": {
            "name": "chemical resistance (single mutants)",
            "constraints": {
                "term": {
                    "termid": "FYPO:0002682",
                    "name": "increased resistance to chemical",
                    "single_or_multi_locus": "single",
                    "expression": "any"
                }
            }
        },
        "single_allele_chemical_sensitivity": {
            "name": "chemical sensitivity (single mutants)",
            "constraints": {
                "term": {
                    "termid": "FYPO:0002683",
                    "name": "increased sensitivity to chemical",
                    "single_or_multi_locus": "single",
                    "expression": "any"
                }
            }
        },
        "null_inviable": {
            "constraints": {
                "term": {
                    "termid": "FYPO:0002061",
                    "name": "inviable vegetative cell population",
                    "single_or_multi_locus": "single",
                    "expression": "null",
                    "ploidiness": "haploid"
                }
            }
        },
        "null_viable": {
            "constraints": {
                "term": {
                    "termid": "FYPO:0002060",
                    "name": "viable vegetative cell population",
                    "single_or_multi_locus": "single",
                    "expression": "null",
                    "ploidiness": "haploid"
                }
            }
        },
        "null_viable_and_inviable": {
            "constraints": {
                "and": [
                    {
                        "term": {
                            "termid": "FYPO:0002061",
                            "name": "inviable vegetative cell population",
                            "single_or_multi_locus": "single",
                            "expression": "null",
                            "ploidiness": "haploid"
                        }
                    },
                    {
                        "term": {
                            "termid": "FYPO:0002060",
                            "name": "viable vegetative cell population",
                            "single_or_multi_locus": "single",
                            "expression": "null",
                            "ploidiness": "haploid"
                        }
                    }
                ]
            }
        },
        "conserved_in_vertebrates": {
            "name": "conserved in vertebrates",
            "constraints": {
                "term": {
                    "termid": "PBO:0011070",
                    "name": "conserved in vertebrates",
                    "single_or_multi_locus": null,
                    "expression": null
                }
            }
        },
        "GO_976_or_GO_3700": {
            "name": "GO:0000976 or GO:0003700",
            "constraints": {
                "or": [
                    {
                        "term": {
                            "termid": "GO:0003700",
                            "name": "DNA binding transcription factor activity",
                            "single_or_multi_locus": null,
                            "expression": null
                        }
                    },
                    {
                        "term": {
                            "termid": "GO:0000976",
                            "name": "transcription regulatory region sequence-specific DNA binding",
                            "single_or_multi_locus": null,
                            "expression": null
                        }
                    }
                ]
            }
        },
        "GO_978_and_published": {
            "name": "GO:0000978 and published",
            "constraints": {
                "and": [
                    {
                        "term": {
                            "termid": "GO:0000978",
                            "name": "RNA polymerase II proximal promoter sequence-specific DNA binding",
                            "single_or_multi_locus": null,
                            "expression": null
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "characterisation_status_biological_role_published"
                        }
                    }
                ]
            }
        },
        "transposons": {
            "name": "Transposons",
            "constraints": {
                "subset": {
                    "subset_name": "characterisation_status_transposon"
                }
            }
        },
        "canned_query:all_genes": {
            "name": "All genes",
            "constraints": {
                "or": [
                    {
                        "subset": {
                            "subset_name": "feature_type_guide_RNA_gene"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "feature_type_ncRNA_gene"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "feature_type_rRNA_gene"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "feature_type_sncRNA_gene"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "feature_type_snRNA_gene"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "feature_type_antisense_RNA_gene"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "feature_type_pseudogene"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "feature_type_mRNA_gene"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "feature_type_snoRNA_gene"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "feature_type_tRNA_gene"
                        }
                    },
                    {
                        "subset": {
                            "subset_name": "feature_type_lncRNA_gene"
                        }
                    }
                ]
            }
        },
        "2-20_exons": {
            "name": "protein-coding genes with 2-20 exons",
            "constraints": {
                "int_range": {
                    "range_type": "exon_count",
                    "start": 2,
                    "end": 20
                }
            }
        },
        "chr2_1000000-2000000": {
            "name": "genes between coordinates 1000000-2000000 on chromosome 2",
            "constraints": {
                "genome_range": {
                    "start": 1000000,
                    "end": 2000000,
                    "chromosome_name": "chromosome_2"
                }
            }
        },
        "MOD_696": {
            "name": "phosphorylated residue",
            "constraints": {
                "term": {
                    "termid": "MOD:00696",
                    "name": "phosphorylated residue",
                    "single_or_multi_locus": null,
                    "expression": null
                }
            }
        },
        "SO_1528": {
            "name": "nuclear localization signal",
            "constraints": {
                "term": {
                    "termid": "SO:0001528",
                    "name": "nuclear_localization_signal",
                    "single_or_multi_locus": null,
                    "expression": null
                }
            }
        },
        "FYPO_17_all": {
            "name": "elongated cell",
            "constraints": {
                "term": {
                    "termid": "FYPO:0000017",
                    "name": "elongated cell",
                    "single_or_multi_locus": "single",
                    "expression": "any"
                }
            }
        },
        "Pfam_PF00004": {
            "name": "ATPase, AAA-type, core",
            "constraints": {
                "subset": {
                    "subset_name": "PF00004"
                }
            }
        },
        "GO_4721": {
            "name": "phosphoprotein phosphatase activity",
            "constraints": {
                "term": {
                    "termid": "GO:0004721",
                    "name": "phosphoprotein phosphatase activity",
                    "single_or_multi_locus": null,
                    "expression": null
                }
            }
        },
        "GO_5634": {
            "name": "nucleus",
            "constraints": {
                "term": {
                    "termid": "GO:0005634",
                    "name": "nucleus",
                    "single_or_multi_locus": null,
                    "expression": null
                }
            }
        },
        "7TM": {
            "name": "proteins with 7 transmembrane domains",
            "constraints": {
                "int_range": {
                    "range_type": "tm_domain_count",
                    "start": 7,
                    "end": 7
                }
            }
        },
        "rrna_genes": {
            "name": "rRNA genes",
            "constraints": {
                "subset": {
                    "subset_name": "feature_type_rRNA_gene"
                }
            }
        },
        "cancer": {
            "name": "cancer",
            "constraints": {
                "term": {
                    "termid": "MONDO:0004992",
                    "name": "cancer",
                    "single_or_multi_locus": null,
                    "expression": null
                }
            }
        },
        "snorna_genes": {
            "name": "snoRNA genes",
            "constraints": {
                "subset": {
                    "subset_name": "feature_type_snoRNA_gene"
                }
            }
        },
        "FYPO_78_or_GO_45333": {
            "name": "FYPO:0000078 or GO:0045333",
            "constraints": {
                "or": [
                    {
                        "term": {
                            "termid": "FYPO:0000078",
                            "name": "abnormal cellular respiration",
                            "single_or_multi_locus": "single",
                            "expression": "any"
                        }
                    },
                    {
                        "term": {
                            "termid": "GO:0045333",
                            "name": "cellular respiration",
                            "single_or_multi_locus": null,
                            "expression": null
                        }
                    }
                ]
            }
        },
        "canned_query:viable_deletions": {
            "name": "Viable deletions and disruptions",
            "constraints": {
                "term": {
                    "termid": "FYPO:0002060",
                    "name": "viable vegetative cell population",
                    "single_or_multi_locus": "single",
                    "expression": "null",
                    "ploidiness": "haploid"
                }
            }
        },
        "canned_query:inviable_deletions": {
            "name": "Inviable deletions and disruptions",
            "constraints": {
                "term": {
                    "termid": "FYPO:0002061",
                    "name": "inviable vegetative cell population",
                    "single_or_multi_locus": "single",
                    "expression": "null",
                    "ploidiness": "haploid"
                }
            }
        },
        "canned_query:conserved_in_S_cerevisiae": {
            "name": "Proteins conserved in <i>S. cerevisiae</i>",
            "constraints": {
                "and": [
                    {
                        "node_name": "has ortholog with: S. cerevisiae",
                        "has_ortholog": {
                            "taxonid": 4932
                        }
                    },
                    {
                        "node_name": "subset: mRNA genes",
                        "subset": {
                            "subset_name": "feature_type_mRNA_gene"
                        }
                    }
                ]
            }
        },
        "canned_query:conserved_in_vertebrates": {
            "name": "Proteins conserved in human/vertebrates",
            "constraints": {
                "term": {
                    "termid": "PBO:0011070",
                    "name": "conserved in vertebrates",
                    "single_or_multi_locus": null,
                    "expression": null
                }
            }
        },
        "canned_query:conserved_in_human": {
            "name": "Proteins conserved in human",
            "constraints": {
                "and": [
                    {
                        "node_name": "has ortholog with: human",
                        "has_ortholog": {
                            "taxonid": 9606
                        }
                    },
                    {
                        "node_name": "subset: mRNA genes",
                        "subset": {
                            "subset_name": "feature_type_mRNA_gene"
                        }
                    }
                ]
            }
        },
        "canned_query:conserved_in_S_japonicus": {
            "name": "Proteins conserved in <i>S. japonicus</i>",
            "constraints": {
                "and": [
                    {
                        "node_name": "has ortholog with: S. japonicus",
                        "has_ortholog": {
                            "taxonid": 4897
                        }
                    },
                    {
                        "node_name": "subset: mRNA genes",
                        "subset": {
                            "subset_name": "feature_type_mRNA_gene"
                        }
                    }
                ]
            }
        },
        "canned_query:proteins_with_pdb_structures": {
            "name": "Proteins with PDB structures",
            "constraints": {
                "node_name": "pdb_structure_count(≥1)",
                "int_range": {
                    "range_type": "pdb_structure_count",
                    "start": 1,
                    "end": null
                }
            }
        },
        "canned_query:genes_in_gocam_models": {
            "name": "Genes in GO-CAM pathway models",
            "constraints": {
                "node_name": "gocam_model_count(≥1)",
                "int_range": {
                    "range_type": "gocam_model_count",
                    "start": 1,
                    "end": null
                }
            }
        },
        "canned_query:genes_with_rna_secondary_structure_predictions": {
            "name": "Genes with RNA secondary structure predictions",
            "constraints": {
                "node_name": "RNA 2D structures",
                "gene_properties": {
                    "property_flags": [
                        "rnacentral_2d_structure"
                    ]
                }
            }
        },
        "canned_query:genes_with_paralogs": {
            "name": "Genes with paralogs",
            "constraints": {
                "node_name": "Genes with paralogs",
                "gene_properties": {
                    "property_flags": [
                        "has_paralog"
                    ]
                }
            }
        },
        "canned_query:genes_with_annotated_protein_feature": {
            "name": "Genes annotated with protein features ",
            "constraints": {
                "term": {
                    "termid": "SO:0000839",
                    "name": " polypeptide_region"
                }
            }
        }
    },
    "canned_query_ids": [
        "all_diseases",
        "all_protein_coding_ex_dubious_and_transposon",
        "all_genes",
        "viable_deletions",
        "inviable_deletions",
        "conserved_in_S_cerevisiae",
        "conserved_in_human",
        "conserved_in_S_japonicus",
        "genes_with_paralogs",
        "priority-unstudied-genes:conserved_unknown_AND_conserved_in_vertebrates",
        "protein_coding_genes_unknown_process",
        "proteins_with_pdb_structures",
        "genes_with_rna_secondary_structure_predictions",
        "genes_in_gocam_models",
        "genes_with_annotated_protein_feature"
    ],
    "api_seq_chunk_sizes": [
        10000,
        200000
    ],
    "chromosomes": [
        {
            "name": "chromosome_1",
            "export_id": "I",
            "export_file_id": "chromosome_I",
            "short_display_name": "I",
            "long_display_name": "Chromosome I"
        },
        {
            "name": "chromosome_2",
            "export_id": "II",
            "export_file_id": "chromosome_II",
            "short_display_name": "II",
            "long_display_name": "Chromosome II"
        },
        {
            "name": "chromosome_3",
            "export_id": "III",
            "export_file_id": "chromosome_III",
            "short_display_name": "III",
            "long_display_name": "Chromosome III"
        },
        {
            "name": "mitochondrial",
            "export_id": "mitochondrial",
            "export_file_id": "mitochondrial_chromosome",
            "short_display_name": "Mitochondrial",
            "long_display_name": "Mitochondrial chromosome"
        },
        {
            "name": "mating_type_region",
            "export_id": "mating_type_region",
            "export_file_id": "mating_type_region",
            "short_display_name": "Mating type region",
            "long_display_name": "Mating type region"
        },
        {
            "name": "chr_II_telomeric_gap",
            "export_id": "chr_II_telomeric_gap",
            "export_file_id": "chr_II_telomeric_gap",
            "short_display_name": "Chromosome II telomeric gap",
            "long_display_name": "Chromosome II telomeric gap"
        }
    ],
    "extension_display_names": [
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0140597",
            "display_name": "chaperones",
            "reciprocal_display": "chaperoned by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0004721",
            "display_name": "dephosphorylates",
            "reciprocal_display": "dephosphorylated by"
        },
        {
            "rel_name": "activated_by",
            "display_name": "activated by"
        },
        {
            "rel_name": "coincident_with",
            "display_name": "at"
        },
        {
            "rel_name": "directly_negatively_regulates",
            "display_name": "directly inhibits",
            "reciprocal_display": "directly inhibited by"
        },
        {
            "rel_name": "directly_positively_regulates",
            "display_name": "directly activates",
            "reciprocal_display": "directly activated by"
        },
        {
            "rel_name": "during",
            "display_name": "during"
        },
        {
            "rel_name": "exists_during",
            "display_name": "during"
        },
        {
            "rel_name": "happens_during",
            "display_name": "during"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0000976",
            "display_name": "regulates transcription of",
            "reciprocal_display": "transcription regulated by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0003713",
            "display_name": "activates transcription of",
            "reciprocal_display": "transcription activated by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0003714",
            "display_name": "represses transcription of",
            "reciprocal_display": "transcription repressed by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0001671",
            "display_name": "activates",
            "reciprocal_display": "activated by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0000772",
            "display_name": "activates",
            "reciprocal_display": "activated by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0004857",
            "display_name": "inhibits",
            "reciprocal_display": "inhibited by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0008150",
            "display_name": "regulates",
            "reciprocal_display": "regulated by"
        },
        {
            "rel_name": "in_presence_of",
            "display_name": "in presence of"
        },
        {
            "rel_name": "inhibited_by",
            "display_name": "inhibited by"
        },
        {
            "rel_name": "not_during",
            "display_name": "except during"
        },
        {
            "rel_name": "not_exists_during",
            "display_name": "except during"
        },
        {
            "rel_name": "not_happens_during",
            "display_name": "except during"
        },
        {
            "rel_name": "occurs_at",
            "display_name": "at"
        },
        {
            "rel_name": "occurs_in",
            "display_name": "in"
        },
        {
            "rel_name": "part_of",
            "if_descendant_of": "GO:0003824",
            "display_name": "involved in"
        },
        {
            "rel_name": "part_of",
            "if_descendant_of": "GO:0098772",
            "display_name": "involved in"
        },
        {
            "rel_name": "part_of",
            "if_descendant_of": "GO:0140104",
            "display_name": "involved in"
        },
        {
            "rel_name": "part_of",
            "if_descendant_of": "GO:0140223",
            "display_name": "involved in"
        },
        {
            "rel_name": "part_of",
            "if_descendant_of": "GO:0008150",
            "display_name": "part of"
        },
        {
            "rel_name": "required_for",
            "display_name": "required for"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0001216",
            "display_name": "activates transcription of",
            "reciprocal_display": "transcription activated by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0001217",
            "display_name": "represses transcription of",
            "reciprocal_display": "transcription repressed by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0004842",
            "display_name": "ubiquitinates",
            "reciprocal_display": "ubiquitinated by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0004672",
            "display_name": "phosphorylates",
            "reciprocal_display": "phosphorylated by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0003824",
            "display_name": "has substrate",
            "reciprocal_display": "substrate of"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0006810",
            "display_name": "transports",
            "reciprocal_display": "transported by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0005215",
            "display_name": "transports",
            "reciprocal_display": "transported by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0005488",
            "display_name": "binds",
            "reciprocal_display": "binds"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0005484",
            "display_name": "recruits",
            "reciprocal_display": "recruited by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0008093",
            "display_name": "anchors",
            "reciprocal_display": "anchored by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0140566",
            "display_name": "reads",
            "reciprocal_display": "read by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0030674",
            "display_name": "recruits",
            "reciprocal_display": "recruited by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0051179",
            "display_name": "localizes",
            "reciprocal_display": "localized by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0140313",
            "display_name": "sequesters",
            "reciprocal_display": "sequestered by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0140677",
            "display_name": "activates",
            "reciprocal_display": "activated by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0140678",
            "display_name": "inhibits",
            "reciprocal_display": "inhibited by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0098772",
            "display_name": "regulates",
            "reciprocal_display": "regulated by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0044183",
            "display_name": "chaperones",
            "reciprocal_display": "chaperoned by"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0140142",
            "display_name": "carries",
            "carries_display": "carried by"
        },
        {
            "rel_name": "assayed_using",
            "if_descendant_of": "FYPO:0001985",
            "display_name": "affecting",
            "reciprocal_display": "affected by mutation in"
        },
        {
            "rel_name": "assayed_enzyme",
            "if_descendant_of": "FYPO:0001985",
            "display_name": "affecting activity of",
            "reciprocal_display": "activity affected by mutation in"
        },
        {
            "rel_name": "assayed_substrate",
            "if_descendant_of": "FYPO:0001985",
            "display_name": "affecting substrate",
            "reciprocal_display": "affected by mutation in"
        },
        {
            "rel_name": "assayed_using",
            "if_descendant_of": "FYPO:0000257",
            "display_name": "affecting"
        },
        {
            "rel_name": "assayed_enzyme",
            "if_descendant_of": "FYPO:0000257",
            "display_name": "affecting activity of"
        },
        {
            "rel_name": "assayed_substrate",
            "if_descendant_of": "FYPO:0000257",
            "display_name": "affecting"
        },
        {
            "rel_name": "assayed_protein",
            "if_descendant_of": "FYPO:0001985",
            "display_name": "affecting",
            "reciprocal_display": "affected by mutation in"
        },
        {
            "rel_name": "assayed_protein",
            "if_descendant_of": "FYPO:0000257",
            "display_name": "affecting"
        },
        {
            "rel_name": "assayed_transcript",
            "if_descendant_of": "FYPO:0001985",
            "display_name": "affecting",
            "reciprocal_display": "affected by mutation in"
        },
        {
            "rel_name": "assayed_transcript",
            "if_descendant_of": "FYPO:0000257",
            "display_name": "affecting"
        },
        {
            "rel_name": "assayed_region",
            "if_descendant_of": "FYPO:0001985",
            "display_name": "affected region",
            "reciprocal_display": "affected by mutation in"
        },
        {
            "rel_name": "assayed_region",
            "if_descendant_of": "FYPO:0000257",
            "display_name": "affected region"
        },
        {
            "rel_name": "residue",
            "if_descendant_of": "FYPO:0000001",
            "display_name": "modified residue"
        },
        {
            "rel_name": "residue",
            "if_descendant_of": "GO:0005515",
            "display_name": "binding site"
        },
        {
            "rel_name": "residue",
            "if_descendant_of": "MOD:00689",
            "display_name": "residue"
        },
        {
            "rel_name": "residue",
            "if_descendant_of": "MOD:00000",
            "display_name": "modified residue"
        },
        {
            "rel_name": "active_form",
            "if_descendant_of": "GO:0003674",
            "display_name": "active form"
        },
        {
            "rel_name": "active_form",
            "if_descendant_of": "GO:0008150",
            "display_name": "active form"
        },
        {
            "rel_name": "active_form",
            "if_descendant_of": "GO:0005575",
            "display_name": "localized form"
        },
        {
            "rel_name": "has_input",
            "if_descendant_of": "GO:0000981",
            "display_name": "regulates transcription of",
            "reciprocal_display": "transcription regulated by"
        }
    ],
    "extension_relation_order": {
        "relation_order": [
            "active_form",
            "assayed_using",
            "assayed_enzyme",
            "assayed_substrate",
            "assayed_protein",
            "assayed_transcript",
            "assayed_region",
            "residue",
            "modified residue",
            "directly_positively_regulates",
            "directly_negatively_regulates",
            "has_input",
            "binds",
            "activated by",
            "carried by",
            "involved_in",
            "part_of",
            "occurs_at",
            "occurs_in",
            "added_by",
            "added_during",
            "increased_during",
            "present_during",
            "in_presence_of",
            "removed_by",
            "removed_during",
            "decreased_during",
            "increased_in_presence_of",
            "decreased_in_presence_of",
            "absent_during",
            "has_severity",
            "has_penetrance"
        ],
        "always_last": [
            "happens_during",
            "exists_during"
        ]
    },
    "term_page_extensions_cv_names": [],
    "evidence_types": {
        "IMP": {
            "long": "Inferred from Mutant Phenotype",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Mutant_Phenotype_(IMP)"
        },
        "IDA": {
            "long": "Inferred from Direct Assay",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Direct_Assay_(IDA)"
        },
        "IGI": {
            "long": "Inferred from Genetic Interaction",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Genetic_Interaction_(IGI)"
        },
        "IPI": {
            "long": "Inferred from Physical Interaction",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Physical_Interaction_(IPI)"
        },
        "EXP": {
            "long": "Inferred from Experiment",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Experiment_(EXP)"
        },
        "IEP": {
            "long": "Inferred from Expression Pattern",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Expression_Pattern_(IEP)"
        },
        "ISS": {
            "long": "Inferred from Sequence or Structural Similarity",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Sequence_or_structural_Similarity_(ISS)"
        },
        "ISO": {
            "long": "Inferred from Sequence Orthology",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Sequence_Orthology_(ISO)"
        },
        "ISA": {
            "long": "Inferred from Sequence Alignment",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Sequence_Alignment_(ISA)"
        },
        "ISM": {
            "long": "Inferred from Sequence Model",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Sequence_Model_(ISM)"
        },
        "IGC": {
            "long": "Inferred from Genomic Context",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Genomic_Context_(IGC)"
        },
        "IBA": {
            "long": "Inferred from Biological aspect of Ancestor",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Biological_aspect_of_Ancestor_(IBA)"
        },
        "IBD": {
            "long": "Inferred from Biological aspect of Descendant",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Biological_aspect_of_Descendant_(IBD)"
        },
        "IKR": {
            "long": "Inferred from Key Residues",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Key_Residues_(IKR)"
        },
        "IRD": {
            "long": "Inferred from Rapid Divergence",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Rapid_Divergence(IRD)"
        },
        "RCA": {
            "long": "inferred from Reviewed Computational Analysis",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Reviewed_Computational_Analysis_(RCA)"
        },
        "NAS": {
            "long": "Non-traceable Author Statement",
            "link": "http://wiki.geneontology.org/index.php/Non-traceable_Author_Statement_(NAS)"
        },
        "IC": {
            "long": "Inferred by Curator",
            "link": "http://wiki.geneontology.org/index.php/Inferred_by_Curator_(IC)"
        },
        "ND": {
            "long": "No biological Data available",
            "link": "http://wiki.geneontology.org/index.php/No_biological_Data_available_(ND)_evidence_code"
        },
        "IEA": {
            "long": "Inferred from Electronic Annotation",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_Electronic_Annotation_(IEA)"
        },
        "NR": {
            "long": "Not Recorded",
            "link": "http://wiki.geneontology.org/index.php/Guide_to_GO_Evidence_Codes"
        },
        "TAS": {
            "long": "Traceable Author Statement",
            "link": "http://wiki.geneontology.org/index.php/Traceable_Author_Statement_(TAS)"
        },
        "HTP": {
            "long": "Inferred from high throughput experiment",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_High_Throughput_Experiment_(HTP)"
        },
        "HDA": {
            "long": "Inferred from high throughput direct assay",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_High_Throughput_Direct_Assay_(HDA)"
        },
        "HEP": {
            "long": "Inferred from high throughput expression pattern",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_High_Throughput_Expression_Pattern_(HEP)"
        },
        "HGI": {
            "long": "Inferred from high throughput genetic interaction",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_High_Throughput_Genetic_Interaction_(HGI)"
        },
        "HMP": {
            "long": "Inferred from high throughput mutant phenotype",
            "link": "http://wiki.geneontology.org/index.php/Inferred_from_High_Throughput_Mutant_Phenotype_(HMP)"
        },
        "UNK": {
            "long": "Unknown"
        }
    },
    "extension_categories": {
        "during": [
            {
                "display_name": "mitotic interphase",
                "ancestors": [
                    "GO:0051329"
                ]
            },
            {
                "display_name": "mitotic G1 phase",
                "ancestors": [
                    "GO:0000080"
                ]
            },
            {
                "display_name": "mitotic S phase",
                "ancestors": [
                    "GO:0000084"
                ]
            },
            {
                "display_name": "mitotic G2 phase",
                "ancestors": [
                    "GO:0000085"
                ]
            },
            {
                "display_name": "mitotic M phase",
                "ancestors": [
                    "GO:0000087"
                ]
            },
            {
                "display_name": "mitotic prophase",
                "ancestors": [
                    "GO:0000088"
                ]
            },
            {
                "display_name": "mitotic metaphase",
                "ancestors": [
                    "GO:0000089"
                ]
            },
            {
                "display_name": "mitotic anaphase",
                "ancestors": [
                    "GO:0000090"
                ]
            },
            {
                "display_name": "mitotic telophase",
                "ancestors": [
                    "GO:0000093"
                ]
            },
            {
                "display_name": "meiotic S phase",
                "ancestors": [
                    "GO:0051332"
                ]
            },
            {
                "display_name": "meiotic M phase",
                "ancestors": [
                    "GO:0051327"
                ]
            },
            {
                "display_name": "meiotic interphase",
                "ancestors": [
                    "GO:0051328"
                ]
            },
            {
                "display_name": "meiotic prophase I",
                "ancestors": [
                    "GO:0007128"
                ]
            },
            {
                "display_name": "meiotic metaphase I",
                "ancestors": [
                    "GO:0007132"
                ]
            },
            {
                "display_name": "meiotic anaphase I",
                "ancestors": [
                    "GO:0007133"
                ]
            },
            {
                "display_name": "meiotic telophase I",
                "ancestors": [
                    "GO:0007134"
                ]
            },
            {
                "display_name": "meiotic metaphase II",
                "ancestors": [
                    "GO:0007137"
                ]
            },
            {
                "display_name": "meiotic anaphase II",
                "ancestors": [
                    "GO:0007138"
                ]
            },
            {
                "display_name": "meiotic telophase II",
                "ancestors": [
                    "GO:0007139"
                ]
            }
        ]
    },
    "cv_config": {
        "fission_yeast_phenotype": {
            "feature_type": "genotype",
            "display_name": "Phenotype",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "genotype",
                "genotype_background",
                "allele_promoters",
                "evidence",
                "conditions",
                "reference",
                "extension"
            ],
            "columns_to_show_by_page_type": {
                "reference": ["annotation_phenotype_score"]
            },
            "external_db_link_keys": [
                "BioPortal"
            ],
            "filters": [
                {
                    "filter_type": "ploidiness",
                    "display_name": "Haploid/Diploid",
                    "scope": [
                        "gene",
                        "term",
                        "reference"
                    ],
                    "detailed_view_only": false
                },
                {
                    "filter_type": "term",
                    "display_name": "Term",
                    "scope": [
                        "gene",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": false,
                    "term_categories": [
                        {
                            "ancestors": [
                                "FYPO:0000257"
                            ],
                            "display_name": "Normal phenotype"
                        },
                        {
                            "ancestors": [
                                "FYPO:0001985"
                            ],
                            "display_name": "Abnormal phenotype"
                        },
                        {
                            "ancestors": [
                                "FYPO:0001320"
                            ],
                            "display_name": "Vegetative cell phenotype"
                        },
                        {
                            "ancestors": [
                                "FYPO:0002683"
                            ],
                            "display_name": "Sensitive to chemical"
                        },
                        {
                            "ancestors": [
                                "FYPO:0005447"
                            ],
                            "display_name": "Abnormal biological process"
                        },
                        {
                            "ancestors": [
                                "FYPO:0000005"
                            ],
                            "display_name": "Abnormal cell morphology"
                        },
                        {
                            "ancestors": [
                                "FYPO:0000631"
                            ],
                            "display_name": "Cell cycle phenotype"
                        },
                        {
                            "ancestors": [
                                "FYPO:0000138"
                            ],
                            "display_name": "Localization phenotype"
                        },
                        {
                            "ancestors": [
                                "FYPO:0000004"
                            ],
                            "display_name": "Cell viability"
                        },
                        {
                            "ancestors": [
                                "FYPO:0002057"
                            ],
                            "display_name": "Cell population viability"
                        },
                        {
                            "ancestors": [
                                "FYPO:0000707"
                            ],
                            "display_name": "Abnormal molecular function"
                        },
                        {
                            "ancestors": [
                                "FYPO:0000702"
                            ],
                            "display_name": "Protein-protein interaction"
                        },
                        {
                            "ancestors": [
                                "FYPO:0000661"
                            ],
                            "display_name": "Abnormal catalytic activity"
                        }
                    ]
                },
                {
                    "filter_type": "evidence",
                    "display_name": "Evidence",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "evidence_categories": [
                        {
                            "display_name": "Microscopy",
                            "evidence_codes": [
                                "Microscopy",
                                "immunofluorescence",
                                "Epitope-tagged protein immunolocalization experiment data",
                                "Immunolocalization experiment data",
                                "Fusion protein localization",
                                "In situ hybridization assay"
                            ]
                        },
                        {
                            "display_name": "Gel electrophoresis",
                            "evidence_codes": [
                                "Gel electrophoresis",
                                "Sodium dodecyl sulfate polyacrylamide gel electrophoresis",
                                "RNA protection assay"
                            ]
                        },
                        {
                            "display_name": "Western blot",
                            "evidence_codes": [
                                "Western blot",
                                "Western blot assay"
                            ]
                        },
                        {
                            "display_name": "Co-immunoprecipitation experiment",
                            "evidence_codes": [
                                "Co-immunoprecipitation experiment"
                            ]
                        },
                        {
                            "display_name": "Cell growth assay",
                            "evidence_codes": [
                                "Cell growth assay"
                            ]
                        },
                        {
                            "display_name": "Chromatin immunoprecipitation experiment",
                            "evidence_codes": [
                                "Chromatin immunoprecipitation experiment"
                            ]
                        },
                        {
                            "display_name": "Chromatography",
                            "evidence_codes": [
                                "Chromatography"
                            ]
                        },
                        {
                            "display_name": "Competitive growth assay",
                            "evidence_codes": [
                                "Competitive growth assay"
                            ]
                        },
                        {
                            "display_name": "Electrophoretic mobility shift assay data",
                            "evidence_codes": [
                                "Electrophoretic mobility shift assay data"
                            ]
                        },
                        {
                            "display_name": "Enzyme assay data",
                            "evidence_codes": [
                                "Enzyme assay data"
                            ]
                        },
                        {
                            "display_name": "Experimental phenotypic",
                            "evidence_codes": [
                                "Experimental phenotypic"
                            ]
                        },
                        {
                            "display_name": "Flow cytometry data",
                            "evidence_codes": [
                                "Flow cytometry data"
                            ]
                        },
                        {
                            "display_name": "High throughput nucleotide sequencing assay",
                            "evidence_codes": [
                                "High throughput nucleotide sequencing assay"
                            ]
                        },
                        {
                            "display_name": "Inferred from Mutant Phenotype",
                            "evidence_codes": [
                                "Inferred from Mutant Phenotype"
                            ]
                        },
                        {
                            "display_name": "Northern assay",
                            "evidence_codes": [
                                "Northern assay"
                            ]
                        },
                        {
                            "display_name": "Particle size and count assay",
                            "evidence_codes": [
                                "Particle size and count assay"
                            ]
                        },
                        {
                            "display_name": "Plasmid maintenance assay",
                            "evidence_codes": [
                                "Plasmid maintenance assay"
                            ]
                        },
                        {
                            "display_name": "Quantitative PCR",
                            "evidence_codes": [
                                "Quantitative PCR"
                            ]
                        },
                        {
                            "display_name": "Reporter gene assay",
                            "evidence_codes": [
                                "Reporter gene assay"
                            ]
                        },
                        {
                            "display_name": "Substance quantification",
                            "evidence_codes": [
                                "Substance quantification"
                            ]
                        },
                        {
                            "display_name": "Transcript expression level",
                            "evidence_codes": [
                                "Transcript expression level"
                            ]
                        },
                        {
                            "display_name": "Other",
                            "evidence_codes": [
                                "Other",
                                "Other direct assay"
                            ]
                        }
                    ]
                },
                {
                    "filter_type": "throughput",
                    "display_name": "Throughput",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "throughput_categories": [
                        {
                            "display_name": "High",
                            "throughput_type": "high"
                        },
                        {
                            "display_name": "Low",
                            "throughput_type": "low"
                        },
                        {
                            "display_name": "Non-experimental",
                            "throughput_type": "non-experimental"
                        }
                    ]
                }
            ],
            "split_by_parents": [
                {
                    "config_name": "population_phenotype",
                    "termids": [
                        "FYPO:0000003",
                        "FYPO:0001155"
                    ],
                    "display_name": "population"
                },
                {
                    "config_name": "cell_phenotype",
                    "termids": [
                        "FYPO:0000002",
                        "FYPO:0000652",
                        "FYPO:0000301",
                        "FYPO:0000680",
                        "FYPO:0001312",
                        "FYPO:0002928",
                        "FYPO:0002931",
                        "FYPO:0002929",
                        "FYPO:0003048",
                        "FYPO:0003093",
                        "FYPO:0003290",
                        "FYPO:0003324",
                        "FYPO:0003427",
                        "FYPO:0003480",
                        "FYPO:0004914",
                        "FYPO:0004915",
                        "FYPO:0004942",
                        "FYPO:0005156",
                        "FYPO:0005157",
                        "FYPO:0006297",
                        "FYPO:0006790",
                        "FYPO:0006793",
                        "FYPO:0007135",
                        "FYPO:0007170",
                        "FYPO:0007394",
                        "FYPO:0007477",
                        "FYPO:0007479",
                        "FYPO:0007491"
                    ],
                    "display_name": "cell"
                }
            ],
            "summary_relations_to_hide": [
                "has_penetrance",
                "has_severity"
            ],
            "summary_relation_ranges_to_collect": [
                "affecting",
                "assayed_enzyme",
                "assayed_substrate",
                "assayed_using",
                "residue"
            ]
        },
        "single_locus_phenotype": {
            "feature_type": "genotype",
            "display_name": "Single locus phenotype",
            "menu_item_label": "single locus",
            "inherits_from": "fission_yeast_phenotype",
            "downstream_query_title": "Send all downstream (affected) genes to the Advanced Search ...",
            "downstream_relations": [
                "assayed_using", "assayed_substrate", "assayed_protein", "assayed_transcript", "assayed_enzyme"
            ],
            "single_or_multi_locus": "single",
            "help_route": "/documentation/gene-page-phenotypes"
        },
        "multi_locus_phenotype": {
            "feature_type": "genotype",
            "display_name": "Multi-locus phenotype",
            "menu_item_label": "multi-locus",
            "inherits_from": "fission_yeast_phenotype",
            "single_or_multi_locus": "multi",
            "help_route": "/documentation/gene-page-phenotypes"
        },
        "fission_yeast_phenotype_condition": {
            "feature_type": "genotype",
            "display_name": "Phenotype condition",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "genotype",
                "evidence",
                "qualifiers",
                "reference",
                "count",
                "extension"
            ]
        },
        "molecular_function": {
            "feature_type": "gene",
            "display_name": "GO molecular function",
            "menu_item_label": "Molecular function",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "evidence",
                "qualifiers",
                "reference",
                "count",
                "extension"
            ],
            "external_db_link_keys": [
                "AmiGO",
                "QuickGO",
                "BioPortal"
            ],
            "slim_subset_name": "mf_goslim_pombe",
            "slim_description": "Molecular function overview (GO slim)",
            "slim_link": "/browse-curation/fission-yeast-mf-go-slim-terms",
            "slim_no_category_text": "molecular function GO Slim category",
            "split_by_parents": [
                {
                    "config_name": "not-binding",
                    "termids": [
                        "NOT GO:0005488"
                    ],
                    "display_name": "activities"
                },
                {
                    "config_name": "binding",
                    "termids": [
                        "GO:0005488"
                    ],
                    "display_name": "binding"
                }
            ],
            "summary_relations_to_hide": [],
            "summary_relation_ranges_to_collect": [
                "directly_activates",
                "directly_inhibits",
                "has_input",
                "has_substrate",
                "binds"
            ],
            "downstream_query_title": "Send all substrate genes to Advanced Search ...",
            "downstream_relations": [
                "RO:0002233"
            ],
            "filters": [
                {
                    "filter_type": "extension",
                    "filter_subtype": "during",
                    "display_name": "During",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "extension_rel_type_names": [
                        "during",
                        "happens_during",
                        "exists_during"
                    ]
                },
                {
                    "filter_type": "term",
                    "display_name": "Slim term",
                    "scope": [
                        "gene",
                        "reference"
                    ],
                    "detailed_view_only": false,
                    "slim_name": "mf_goslim_pombe"
                },
                {
                    "filter_type": "throughput",
                    "display_name": "Evidence type",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "throughput_categories": [
                        {
                            "display_name": "High throughput experiment",
                            "throughput_type": "high"
                        },
                        {
                            "display_name": "Low throughput experiment",
                            "throughput_type": "low"
                        },
                        {
                            "display_name": "All experimental annotation",
                            "throughput_type": "high|low"
                        },
                        {
                            "display_name": "Inferred",
                            "throughput_type": "non-experimental"
                        }
                    ]
                }
            ],
            "help_route": "/documentation/gene-page-gene-ontology"
        },
        "biological_process": {
            "feature_type": "gene",
            "display_name": "GO biological process",
            "menu_item_label": "Biological process",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "evidence",
                "qualifiers",
                "reference",
                "count",
                "extension"
            ],
            "external_db_link_keys": [
                "AmiGO",
                "QuickGO",
                "BioPortal"
            ],
            "external_link_config": [
                {
                    "display_text": "View process term ancestry ...",
                    "url_prefix": "https://www.ebi.ac.uk/QuickGO/services/ontology/go/terms/{ids}/chart?ids="
                }
            ],
            "summary_relation_ranges_to_collect": [
                "has_input"
            ],
            "slim_subset_name": "bp_goslim_pombe",
            "slim_description": "Biological process overview (GO slim)",
            "slim_link": "/browse-curation/fission-yeast-go-slim-terms",
            "slim_no_category_text": "biological process GO Slim category",
            "filters": [
                {
                    "filter_type": "extension",
                    "filter_subtype": "during",
                    "display_name": "During",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "extension_rel_type_names": [
                        "during",
                        "happens_during",
                        "exists_during"
                    ]
                },
                {
                    "filter_type": "term",
                    "display_name": "Slim term",
                    "scope": [
                        "gene",
                        "reference"
                    ],
                    "detailed_view_only": false,
                    "slim_name": "bp_goslim_pombe"
                },
                {
                    "filter_type": "throughput",
                    "display_name": "Evidence type",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "throughput_categories": [
                        {
                            "display_name": "High throughput experiment",
                            "throughput_type": "high"
                        },
                        {
                            "display_name": "Low throughput experiment",
                            "throughput_type": "low"
                        },
                        {
                            "display_name": "All experimental annotation",
                            "throughput_type": "high|low"
                        },
                        {
                            "display_name": "Inferred",
                            "throughput_type": "non-experimental"
                        }
                    ]
                }
            ],
            "help_route": "/documentation/gene-page-gene-ontology"
        },
        "cellular_component": {
            "feature_type": "gene",
            "display_name": "GO cellular component",
            "menu_item_label": "Cellular component",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "evidence",
                "qualifiers",
                "reference",
                "count",
                "extension"
            ],
            "external_db_link_keys": [
                "AmiGO",
                "QuickGO",
                "BioPortal"
            ],
            "slim_subset_name": "cc_goslim_pombe",
            "slim_description": "Cellular component overview (GO slim)",
            "slim_link": "/browse-curation/fission-yeast-cc-go-slim-terms",
            "slim_no_category_text": "cellular component GO Slim category",
            "summary_relation_ranges_to_collect": [
                "coincident_with",
                "during",
                "exists_during",
                "happens_during"
            ],
            "filters": [
                {
                    "filter_type": "extension",
                    "filter_subtype": "during",
                    "display_name": "During",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "extension_rel_type_names": [
                        "during",
                        "happens_during",
                        "exists_during"
                    ]
                },
                {
                    "filter_type": "term",
                    "display_name": "Slim term",
                    "scope": [
                        "gene",
                        "reference"
                    ],
                    "detailed_view_only": false,
                    "slim_name": "cc_goslim_pombe"
                },
                {
                    "filter_type": "throughput",
                    "display_name": "Evidence type",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "throughput_categories": [
                        {
                            "display_name": "High throughput experiment",
                            "throughput_type": "high"
                        },
                        {
                            "display_name": "Low throughput experiment",
                            "throughput_type": "low"
                        },
                        {
                            "display_name": "All experimental annotation",
                            "throughput_type": "high|low"
                        },
                        {
                            "display_name": "Inferred",
                            "throughput_type": "non-experimental"
                        }
                    ]
                }
            ],
            "help_route": "/documentation/gene-page-gene-ontology"
        },
        "PSI-MOD": {
            "feature_type": "gene",
            "display_name": "Modification",
            "protein_viewer_type": "modifications",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "evidence",
                "reference",
                "count",
                "extension"
            ],
            "external_db_link_keys": [
                "BioPortal"
            ],
            "sort_details_by": [
                "extension"
            ],
            "summary_relation_ranges_to_collect": [
                "residue",
                "modified residue"
            ],
            "modification_abbreviations": {
                "SPBC28F2.12": {
                    "CTD_S2": "S1559,S1566,S1579,S1586,S1593,S1600,S1607,S1614,S1621,S1628,S1635,S1642,S1649,S1656,S1663,S1670,S1677,S1684,S1691,S1698,S1705,S1712,S1719,S1726,S1733,S1740,S1747",
                    "CTD_T4": "T1554,T1567,T1581,T1588,T1595,T1602,T1609,T1616,T1623,T1630,T1637,T1644,T1651,T1658,T1665,T1672,T1679,T1686,T1693,T1700,T1707,T1714,T1721,T1728,T1735,T1742,T1749",
                    "CTD_S5": "S1555,S1562,S1568,S1575,S1582,S1589,S1596,S1603,S1610,S1617,S1624,S1631,S1638,S1645,S1652,S1659,S1666,S1673,S1680,S1687,S1694,S1701,S1708,S1715,S1722,S1729,S1736,S1743,S1750",
                    "CTD_S7": "S1557,S1577,S1584,S1591,S1598,S1605,S1612,S1619,S1626,S1633,S1640,S1647,S1654,S1661,S1668,S1675,S1682,S1689,S1696,S1703,S1710,S1717,S1724,S1731,S1738,S1745,S1752"
                }
            },
            "gene_structure_change_message": ["Warning: The translation start or intron/exon structure of this gene has changed since the genome was published (see Gene structure history section for change details).", "Changed Modification positions from older studies have been revised to match their current amino acid location."],
            "filters": [
                {
                    "filter_type": "throughput",
                    "display_name": "Throughput",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "throughput_categories": [
                        {
                            "display_name": "High",
                            "throughput_type": "high"
                        },
                        {
                            "display_name": "Low",
                            "throughput_type": "low"
                        },
                        {
                            "display_name": "Non-experimental",
                            "throughput_type": "non-experimental"
                        }
                    ]
                }
            ],
            "help_route": "/documentation/gene-page-modifications"
        },
        "qualitative_gene_expression": {
            "feature_type": "gene",
            "display_name": "Qualitative gene expression",
            "menu_item_label": "Qualitative",
            "columns_to_show": [
                "desc-rel",
                "gene",
                "evidence",
                "reference",
                "count",
                "extension"
            ],
            "filters": [
                {
                    "filter_type": "extension",
                    "filter_subtype": "during",
                    "display_name": "During",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "extension_rel_type_names": [
                        "during",
                        "happens_during",
                        "exists_during"
                    ]
                },
                {
                    "filter_type": "throughput",
                    "display_name": "Throughput",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "throughput_categories": [
                        {
                            "display_name": "High",
                            "throughput_type": "high"
                        },
                        {
                            "display_name": "Low",
                            "throughput_type": "low"
                        },
                        {
                            "display_name": "Non-experimental",
                            "throughput_type": "non-experimental"
                        }
                    ]
                }
            ]
        },
        "quantitative_gene_expression": {
            "feature_type": "gene",
            "display_name": "Quantitative gene expression",
            "menu_item_label": "Quantitative",
            "filters": [
                {
                    "filter_type": "extension",
                    "filter_subtype": "during",
                    "display_name": "During",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "extension_rel_type_names": [
                        "during",
                        "happens_during",
                        "exists_during"
                    ]
                },
                {
                    "filter_type": "throughput",
                    "display_name": "Throughput",
                    "scope": [
                        "gene",
                        "term",
                        "genotype",
                        "reference"
                    ],
                    "detailed_view_only": true,
                    "throughput_categories": [
                        {
                            "display_name": "High",
                            "throughput_type": "high"
                        },
                        {
                            "display_name": "Low",
                            "throughput_type": "low"
                        },
                        {
                            "display_name": "Non-experimental",
                            "throughput_type": "non-experimental"
                        }
                    ]
                }
            ]
        },
        "mondo": {
            "feature_type": "gene",
            "display_name": "Disease association",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "qualifiers",
                "term-xref",
                "reference",
                "count"
            ],
            "external_db_link_keys": [
                "Monarch Disease Ontology"
            ],
            "source_config": {
                "MalaCards": {
                    "display_name_prop": "malacards_displayed_disease_name",
                    "id_source": "prop_name:malacards_disease_slug",
                    "condition": "reference=PMID:27899610"
                },
                "Monarch": {
                    "id_source": "ACCESSION",
                    "condition": "reference=PB_REF:0000006"
                },
                "PomBase": {
                    "condition": "reference=PB_REF:0000003"
                },
                "Cancer census": {
                    "condition": "reference=PMID:30371878"
                }
            },
            "slim_subset_name": "pombe_mondo_slim",
            "slim_description": "Disease ontology overview",
            "slim_link": "/browse-curation/disease-slim",
            "slim_no_category_text": "disease ontology overview category",
            "filters": [
                {
                    "filter_type": "term",
                    "display_name": "Slim term",
                    "scope": [
                        "gene",
                        "reference"
                    ],
                    "detailed_view_only": false,
                    "slim_name": "pombe_mondo_slim"
                }
            ]
        },
        "species_dist": {
            "feature_type": "gene",
            "display_name": "Taxonomic conservation",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "count"
            ],
            "hideTermDetails": "true",
            "help_route": "/documentation/taxonomic-conservation"
        },
        "ex_tools": {
            "display_name": "Experimental tools",
            "feature_type": "gene",
            "columns_to_show": [
                "term_id",
                "gene",
                "reference",
                "count"
            ],
            "details_only": true
        },
        "cat_act": {
            "feature_type": "gene",
            "display_name": "Catalytic activity attributes",
            "columns_to_show": [
                "term_id",
                "gene",
                "reference",
                "count"
            ],
            "details_only": true
        },
        "genome_org": {
            "feature_type": "gene",
            "display_name": "Genome organisation",
            "columns_to_show": [
                "term_id",
                "gene",
                "reference",
                "count"
            ],
            "details_only": true
        },
        "misc": {
            "feature_type": "gene",
            "display_name": "Comment",
            "columns_to_show": [
                "term_id",
                "gene",
                "evidence",
                "reference",
                "count"
            ],
            "details_only": true
        },
        "m_f_g": {
            "feature_type": "gene",
            "display_name": "Miscellaneous functional group",
            "columns_to_show": [
                "term_id",
                "gene",
                "evidence",
                "count"
            ],
            "details_only": true
        },
        "complementation": {
            "feature_type": "gene",
            "display_name": "Complementation",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "evidence",
                "qualifiers",
                "reference",
                "count",
                "extension"
            ]
        },
        "transcript_view": {
            "feature_type": "gene",
            "display_name": "Transcript details"
        },
        "protein_domains_and_properties": {
            "feature_type": "gene",
            "display_name": "Protein domains and features"
        },
        "pombase_family_or_domain": {
            "feature_type": "gene",
            "display_name": "Protein features",
            "details_only": true,
            "no_gene_details_section": true,
            "columns_to_show": [
                "term_id",
                "gene",
                "count",
                "reference"
            ]
        },
        "PomBase gene characterisation status": {
            "feature_type": "gene",
            "display_name": "gene characterisation status"
        },
        "target_of": {
            "feature_type": "gene",
            "display_name": "Target of"
        },
        "genetic_interactions": {
            "feature_type": "gene",
            "display_name": "Genetic interaction",
            "columns_to_show": [
                "gene",
                "gene-product",
                "interactor",
                "interactor-product",
                "evidence",
                "reference"
            ],
            "filters": [
                {
                    "filter_type": "throughput",
                    "display_name": "Throughput",
                    "throughput_categories": [
                        {
                            "display_name": "High",
                            "throughput_type": "high"
                        },
                        {
                            "display_name": "Low",
                            "throughput_type": "low"
                        }
                    ]
                },
                {
                    "filter_type": "interaction_type",
                    "display_name": "Interaction Type",
                    "interaction_types": [
                        "Dosage Growth Defect",
                        "Dosage Lethality",
                        "Dosage Rescue",
                        "Negative Genetic",
                        "Phenotypic Enhancement",
                        "Phenotypic Suppression",
                        "Positive Genetic",
                        "Synthetic Growth Defect",
                        "Synthetic Lethality",
                        "Synthetic Rescue"
                    ]
                }
            ]
        },
        "double_mutant_genetic_interactions": {
            "feature_type": "gene",
            "display_name": "Double allele interactions",
            "inherits_from": "genetic_interactions"
        },
        "single_allele_genetic_interactions": {
            "feature_type": "gene",
            "display_name": "Single allele interactions",
            "inherits_from": "genetic_interactions"
        },
        "physical_interactions": {
            "feature_type": "gene",
            "display_name": "Physical interaction",
            "columns_to_show": [
                "gene",
                "gene-product",
                "interactor",
                "interactor-product",
                "evidence",
                "reference"
            ],
            "filters": [
                {
                    "filter_type": "throughput",
                    "display_name": "Throughput",
                    "throughput_categories": [
                        {
                            "display_name": "High",
                            "throughput_type": "high"
                        },
                        {
                            "display_name": "Low",
                            "throughput_type": "low"
                        }
                    ]
                }
            ]
        },
        "orthologs": {
            "feature_type": "gene",
            "display_name": "Orthologs",
            "columns_to_show": [
                "species",
                "ortholog",
                "description"
            ]
        },
        "paralogs": {
            "feature_type": "gene",
            "display_name": "Paralogs",
            "columns_to_show": [
                "ortholog",
                "description"
            ]
        },
        "subunit_composition": {
            "feature_type": "gene",
            "display_name": "Subunit composition",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "evidence",
                "qualifiers",
                "reference",
                "count",
                "extension"
            ]
        },
        "sequence": {
            "feature_type": "gene",
            "display_name": "Protein sequence feature",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "evidence",
                "qualifiers",
                "residue",
                "reference",
                "count",
                "extension"
            ],
            "external_db_link_keys": [
                "MISO"
            ],
            "no_gene_details_section": true
        },
        "warning": {
            "feature_type": "gene",
            "display_name": "Warnings",
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "evidence",
                "qualifiers",
                "reference",
                "count",
                "extension"
            ]
        },
        "_DEFAULT_": {
            "feature_type": "gene",
            "display_name": null,
            "columns_to_show": [
                "term_id",
                "desc-rel",
                "gene",
                "evidence",
                "qualifiers",
                "reference",
                "count",
                "extension"
            ]
        }
    },
    "interaction_config": {
      "Co-crystal Structure": {
        "baitLabel": "co-crystallizes with",
        "preyLabel": "co-crystallizes with",
        "isSymmetric": true
      },
      "Co-fractionation": {
        "baitLabel": "co-fractionates with",
        "preyLabel": "co-fractionates with",
        "isSymmetric": true
      },
      "Co-localization": {
        "baitLabel": "co-localizes with",
        "preyLabel": "co-localizes with",
        "isSymmetric": true
      },
      "Co-purification": {
        "baitLabel": "co-purifies with",
        "preyLabel": "co-purifies with",
        "isSymmetric": true
      },
      "Reconstituted Complex": {
        "baitLabel": "forms complex with",
        "preyLabel": "forms complex with",
        "isSymmetric": true
      },
      "Affinity Capture-Luminescence": {
        "baitLabel": "affinity captures",
        "preyLabel": "affinity captured by",
        "isSymmetric": false
      },
      "Affinity Capture-MS": {
        "baitLabel": "affinity captures",
        "preyLabel": "affinity captured by",
        "isSymmetric": false
      },
      "Affinity Capture-RNA": {
        "baitLabel": "affinity captures",
        "preyLabel": "affinity captured by",
        "isSymmetric": false
      },
      "Affinity Capture-Western": {
        "baitLabel": "affinity captures",
        "preyLabel": "affinity captured by",
        "isSymmetric": false
      },
      "Proximity Label-MS": {
        "baitLabel": "affinity captures",
        "preyLabel": "affinity captured by",
        "isSymmetric": false
      },
      "Biochemical Activity": {
        "baitLabel": "modifies",
        "preyLabel": "modified by",
        "isSymmetric": false
      },
      "Far Western": {
        "baitLabel": "captures",
        "preyLabel": "captured by",
        "isSymmetric": false
      },
      "FRET": {
        "baitLabel": "fluorescence resonance energy donor to",
        "preyLabel": "fluorescence resonance energy acceptor from",
        "isSymmetric": false
      },
      "PCA": {
        "baitLabel": "interacts with",
        "preyLabel": "interacts with",
        "isSymmetric": true
      },
      "Protein-peptide": {
        "baitLabel": "binds to peptide derived from",
        "preyLabel": "peptide from this protein binds to",
        "isSymmetric": false
      },
      "Protein-RNA": {
        "baitLabel": "binds to RNA",
        "preyLabel": "binds to protein",
        "isSymmetric": false
      },
      "Two-hybrid": {
        "baitLabel": "binds activation domain construct with",
        "preyLabel": "binds DNA-binding domain construct with",
        "isSymmetric": false
      },
      "Negative Genetic": {
        "baitLabel": "negative genetic interaction with",
        "preyLabel": "negative genetic interaction with",
        "isSymmetric": true
      },
      "Positive Genetic": {
        "baitLabel": "positive genetic interaction with",
        "preyLabel": "positive genetic interaction with",
        "isSymmetric": true
      },
      "Synthetic Growth Defect": {
        "baitLabel": "synthetic growth defect with",
        "preyLabel": "synthetic growth defect with",
        "isSymmetric": true
      },
      "Synthetic Haploinsufficiency": {
        "baitLabel": "synthetic haploinsufficient with",
        "preyLabel": "synthetic haploinsufficient with",
        "isSymmetric": true
      },
      "Synthetic Lethality": {
        "baitLabel": "synthetic lethal with",
        "preyLabel": "synthetic lethal with",
        "isSymmetric": true
      },
      "Synthetic Phenotype": {
        "baitLabel": "synthetically interacts with",
        "preyLabel": "synthetically interacts with",
        "isSymmetric": true
      },
      "Synthetic Rescue": {
        "baitLabel": "synthetically rescued by",
        "preyLabel": "synthetically rescues",
        "isSymmetric": false
      },
      "Dosage Growth Defect": {
        "baitLabel": "growth defect in presence of overexpressed",
        "preyLabel": "overexpression causes growth defect to",
        "isSymmetric": false
      },
      "Dosage Lethality": {
        "baitLabel": "inviable in presence of overexpressed",
        "preyLabel": "overexpression lethal to",
        "isSymmetric": false
      },
      "Dosage Rescue": {
        "baitLabel": "rescued by overexpression of",
        "preyLabel": "overexpression rescues",
        "isSymmetric": false
      },
      "Phenotypic Enhancement": {
        "baitLabel": "phenotype enhanced by",
        "preyLabel": "enhances phenotype of",
        "isSymmetric": false
      },
      "Phenotypic Suppression": {
        "baitLabel": "rescued by",
        "preyLabel": "rescues",
        "isSymmetric": false
      }
    },
    "target_of_config": {
        "ontology_labels": {
            "molecular_function": "GO",
            "biological_process": "GO",
            "cellular_component": "GO",
            "fission_yeast_phenotype": "FYPO",
            "single_locus_phenotype": "FYPO",
            "multi_locus_phenotype": "FYPO"
        },
        "relation_priority": {
            "dephosphorylated by": 25,
            "directly activated by": 20,
            "directly inhibited by": 20,
            "inhibited by": 18,
            "phosphorylated by": 15,
            "ubiquitinated by": 15,
            "chaperoned by": 15,
            "activated by": 15,
            "recruited by": 15,
            "anchored by": 15,
            "sequestered by": 15,
            "activity regulated by": 13,
            "substrate of": 11,
            "binds": 10,
            "transported by": 9,
            "localized by": 8,
            "transcription activated by": 7,
            "transcription repressed by": 7,
            "transcription regulated by": 7,
            "regulated by": 6,
            "activity affected by mutation in": 5,
            "affected by mutation in": 4
        }
    },
    "phenotype_id_prefixes": [
        "FYPO"
    ],
    "annotation_type_order": [
        { "name": "Gene Ontology",
          "sub_types": ["molecular_function", "biological_process", "cellular_component" ]
        },
        { "name": "target_of" },
        { "name": "phenotype",
          "sub_types": ["single_locus_phenotype", "multi_locus_phenotype"]
        },
        { "name": "fission_yeast_phenotype_condition" },
        { "name": "complementation" },
        { "name": "protein_domains_and_properties" },
        { "name": "PSI-MOD" },
        { "name": "expression",
          "sub_types": ["qualitative_gene_expression", "quantitative_gene_expression"] },
        { "name": "pombase_family_or_domain" },
        { "name": "cat_act" },
        { "name": "m_f_g" },
        { "name": "genome_org" },
        { "name": "ex_tools" },
        { "name": "sequence and transcripts",
          "sub_types": ["transcript_view", "transcript-sequence"]
        },
        { "name": "interactions",
          "sub_types": ["physical_interactions", "genetic_interactions"]
        },
        { "name": "double_mutant_genetic_interactions" },
        { "name": "single_allele_genetic_interactions" },
        { "name": "mondo" },
        { "name": "homologs and conservation",
          "sub_types": ["orthologs", "paralogs", "species_dist"]
        },
        { "name": "Comments and warnings",
          "sub_types": ["misc", "warning"] },
        { "name": "subunit_composition" }
    ],
    "interesting_parents": [
        {
            "termid": "GO:0005515",
            "rel_name": "is_a"
        },
        {
            "termid": "GO:0003723",
            "rel_name": "is_a"
        },
        {
            "termid": "GO:0032991",
            "rel_name": "is_a"
        },
        {
            "termid": "GO:0032991",
            "rel_name": "part_of"
        },
        {
            "termid": "SO:0000839",
            "rel_name": "is_a"
        },
        {
            "termid": "GO:0000786",
            "rel_name": "is_a"
        }
    ],
    "external_gene_references": [
        {
            "ref_type": "Sequence Resources",
            "feature_types": [
                "rRNA gene",
                "tRNA gene",
                "snRNA gene",
                "snoRNA gene",
                "lncRNA gene",
                "sncRNA gene",
                "ncRNA gene"
            ],
            "name": "RNAcentral",
            "description": "Non-coding RNA sequence database",
            "field_name": "rnacentral_urs_identifier",
            "url": "https://rnacentral.org/rna/<<IDENTIFIER>>/<<TAXONID>>"
        },
        {
            "ref_type": "Sequence resources",
            "feature_types": [
                "mRNA gene",
                "pseudogene"
            ],
            "name": "UniProtKB/SwissProt",
            "description": "Protein database ",
            "field_name": "uniprot_identifier",
            "go_xrf_abbrev": "UniProtKB"
        },
        {
            "ref_type": "Sequence resources",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "InterPro",
            "description": "Classification of protein families",
            "field_name": "uniprot_identifier",
            "go_xrf_abbrev": "InterProUniProtId"
        },
        {
            "ref_type": "Sequence resources",
            "feature_types": [
                "ALL"
            ],
            "name": "NCBI",
            "description": "Query all NCBI databases",
            "field_name": "NCBI_ALL_IDS",
            "url": "https://www.ncbi.nlm.nih.gov/gquery/?term=(<<IDENTIFIER>>)+AND+(pombe+OR+fission+yeast)"
        },
        {
            "ref_type": "Sequence resources",
            "feature_types": [
                "ALL"
            ],
            "name": "Ensembl",
            "description": "Genome browser",
            "field_name": "uniquename",
            "url": "http://fungi.ensembl.org/Schizosaccharomyces_pombe/Location/View?db=core;g=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Phenotype resources",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "Monarch",
            "description": "Phenotype driven discovery",
            "field_name": "uniquename",
            "go_xrf_abbrev": "Monarch"
        },
        {
            "ref_type": "Structure",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "AlphaFold",
            "description": "AlphaFold structure predictions",
            "field_name": "uniprot_identifier",
            "go_xrf_abbrev": "AlphaFold"
        },
        {
            "ref_type": "Structure",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "PDB",
            "constraint": "pdb_entries",
            "description": "Experiment structure predictions",
            "field_name": "uniprot_identifier",
            "url": "https://www.ebi.ac.uk/pdbe/entry/search/index/?searchParams=%7B%22q_uniprot%22:%5B%7B%22value%22:%22<<IDENTIFIER>>%22,%22condition1%22:%22AND%22,%22condition2%22:%22Contains%22%7D%5D,%22resultState%22:%7B%22tabIndex%22:0,%22paginationIndex%22:1,%22perPage%22:%2210%22,%22sortBy%22:%22Sort%20by%22%7D%7D"
        },
        {
            "ref_type": "Structure",
            "feature_types": [
                "mRNA gene",
                "pseudogene"
            ],
            "name": "ModBase",
            "description": "Comparative protein structure models",
            "field_name": "uniprot_identifier",
            "go_xrf_abbrev": "ModBase"
        },
        {
            "ref_type": "Structure",
            "feature_types": [
                "mRNA gene",
                "pseudogene"
            ],
            "name": "SWISS-MODEL Repository",
            "description": "Automated protein structure homology modelling",
            "field_name": "uniprot_identifier",
            "url": "https://swissmodel.expasy.org/repository/uniprot/<<IDENTIFIER>>"
        },
        {
            "ref_type": "Localization",
            "feature_types": [
                "ALL"
            ],
            "name": "SPD / RIKEN",
            "description": "Riken Orfeome Localization Data",
            "field_name": "orfeome_identifier",
            "url": "http://www.riken.jp/SPD/<<IDENTIFIER>>.html"
        },
        {
            "ref_type": "Strain and reagents",
            "feature_types": [
                "ALL"
            ],
            "name": "National BioResource Project (NBRP)",
            "description": "Strains and clones",
            "field_name": "uniquename",
            "url": "http://viewer.shigen.info/yeastvw/wordsearch/search?fuzzy=y&action=mapview&ysttype=f&word=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Strain and reagents",
            "feature_types": [
                "ALL"
            ],
            "name": "Image Data Resource",
            "description": "Microscopy data",
            "field_name": "uniquename",
            "url": "https://idr.openmicroscopy.org/mapr/gene/?value=<<IDENTIFIER>>"
        },
        {
            "ref_type": "GO annotation",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "QuickGO",
            "field_name": "uniprot_identifier",
            "description": "QuickGO Gene Ontology annotation viewer",
            "url": "https://www.ebi.ac.uk/QuickGO/annotations?geneProductId=<<IDENTIFIER>>"
        },
        {
            "ref_type": "GO annotation",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "AmiGO",
            "field_name": "uniquename",
            "description": "GO Consortium browser",
            "url": "http://amigo.geneontology.org/amigo/gene_product/PomBase:<<IDENTIFIER>>"
        },
        {
            "ref_type": "Interactions",
            "feature_types": [
                "ALL"
            ],
            "name": "BioGRID",
            "description": "Physical and genetic interactions",
            "field_name": "biogrid_interactor_id",
            "url": "https://thebiogrid.org/<<IDENTIFIER>>/summary/schizosaccharomyces-pombe/<<GENE_NAME>>.html"
        },
        {
            "ref_type": "Interactions",
            "feature_types": [
                "ALL"
            ],
            "name": "STRING",
            "description": "Network display of known and predicted interactions and functional associations",
            "field_name": "uniprot_identifier",
            "url": "https://string-db.org/cgi/network.pl?species=4896&identifier=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Interactions",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "IntAct",
            "description": "IntAct Interaction Network",
            "field_name": "uniprot_identifier",
            "go_xrf_abbrev": "IntactInteractionNetwork"
        },
        {
            "ref_type": "Modifications",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "iPTMnet",
            "description": "Protein modifications",
            "field_name": "uniprot_identifier",
            "go_xrf_abbrev": "iPTMnet"
        },
        {
            "ref_type": "Modifications",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "PombePhosphoproteomics",
            "constraint": "pombephosphoproteomics_unige_ch_starvation_mating_gene",
            "description": "Phosphorylation changes during sexual reproduction, starvation/mating",
            "field_name": "uniprot_identifier",
            "url": "https://pombephosphoproteomics.unige.ch/protein/<<IDENTIFIER>>/starvation-mating"
        },
        {
            "ref_type": "Modifications",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "PombePhosphoproteomics",
            "constraint": "pombephosphoproteomics_unige_ch_fusion_gene",
            "description": "Phosphorylation changes during sexual reproduction, cell-cell fusion",
            "field_name": "uniprot_identifier",
            "url": "https://pombephosphoproteomics.unige.ch/protein/<<IDENTIFIER>>/cell-cell-fusion"
        },
        {
            "ref_type": "Expression",
            "feature_types": [
                "ALL"
            ],
            "name": "Gene Expression Omnibus",
            "description": "GEO profiles",
            "field_name": "uniquename",
            "url": "https://www.ncbi.nlm.nih.gov/geoprofiles/?term=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Expression",
            "feature_types": [
                "ALL"
            ],
            "name": "pombeTV (v1)",
            "description": "Tiling array & Illumina sequencing profiles",
            "field_name": "uniquename",
            "url": "http://bahlerweb.cs.ucl.ac.uk/cgi-bin/pombetv/pombetv?condition=13&action=display&genename=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Expression",
            "feature_types": [
                "ALL"
            ],
            "name": "pombeNcTV (pombeTV v2)",
            "description": "Illumina RNA sequencing profiles",
            "field_name": "uniquename",
            "url": "http://bahlerweb.cs.ucl.ac.uk/cgi-bin/nctv/nctv?genename=<<IDENTIFIER>>&action=Display"
        },
        {
            "ref_type": "Expression",
            "feature_types": [
                "ALL"
            ],
            "name": "Bähler Expression viewer",
            "description": "RNA expression during the cell cycle",
            "field_name": "uniquename",
            "url": "http://bahlerweb.cs.ucl.ac.uk/cgi-bin/SPGE/geexview?group=4&scale=log2&scale_min=-2&scale_max=2&q=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Expression",
            "feature_types": [
                "ALL"
            ],
            "name": "Bähler Expression viewer",
            "description": "RNA expression during stress",
            "field_name": "uniquename",
            "url": "http://bahlerweb.cs.ucl.ac.uk/cgi-bin/SPGE/geexview?group=3&scale=log2&scale_min=-2&scale_max=2&q=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Expression",
            "feature_types": [
                "ALL"
            ],
            "name": "Bähler Expression viewer",
            "description": "RNA expression during meiosis",
            "field_name": "uniquename",
            "url": "http://bahlerweb.cs.ucl.ac.uk/cgi-bin/SPGE/geexview?group=2&scale=log2&scale_min=-2&scale_max=2&q=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Expression",
            "feature_types": [
                "ALL"
            ],
            "name": "Bähler Expression viewer",
            "description": "RNA expression during mating",
            "field_name": "uniquename",
            "url": "http://bahlerweb.cs.ucl.ac.uk/cgi-bin/SPGE/geexview?group=1&scale=log2&scale_min=-2&scale_max=2&q=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Expression",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "Cyclebase",
            "description": "Genome-wide cell-cycle-related experimental results",
            "field_name": "uniquename",
            "url": "https://cyclebase.org/CyclebasePage?type=4896&id=<<IDENTIFIER>>.1"
        },
        {
            "ref_type": "Expression",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "Butter Lab (Öztürk et al. 2022)",
            "description": "Changes in protein level of this gene product when deleting other protein coding genes",
            "field_name": "uniquename",
            "url": "https://butterlab.imb-mainz.de/SpProtQuant/?data=KOLibrary&data2=searchByProtein&protein=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Expression",
            "feature_types": [
                "mRNA gene"
            ],
            "name": "Butter Lab (Öztürk et al. 2022)",
            "description": "Proteomic changes resulting from the deletion of this gene",
            "field_name": "uniquename",
            "url": "https://butterlab.imb-mainz.de/SpProtQuant/?data=KOLibrary&data2=searchByKOStrain&KOStrain=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Proteomics",
            "feature_types": [
                "mRNA gene",
                "pseudogene"
            ],
            "name": "PeptideAtlas",
            "description": "Peptides identified in tandem mass spectrometry",
            "field_name": "uniquename",
            "url": "https://db.systemsbiology.net/sbeams/cgi/PeptideAtlas/GetProtein?action=QUERY&atlas_build_id=355&protein_name=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Pathways",
            "feature_types": [
                "mRNA gene",
                "pseudogene"
            ],
            "name": "Reactome",
            "description": "Manually curated and peer-reviewed pathways",
            "field_name": "uniprot_identifier",
            "url": "https://reactome.org/content/query?q=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Orthologs",
            "feature_types": [
                "mRNA gene",
                "pseudogene"
            ],
            "name": "DIOPT",
            "description": "DRSC Integrative Ortholog Prediction Tool",
            "field_name": "uniquename",
            "show_in_sections": [
                "orthologs"
            ],
            "url": "http://www.flyrnai.org/cgi-bin/DRSC_orthologs.pl?input_species=4896&output_species=0&additional_filter=None&search_fields=***&search_datasets=***&gene_list=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Orthologs",
            "feature_types": [
                "mRNA gene",
                "pseudogene"
            ],
            "name": "HCOP",
            "description": "Integrated human orthologs",
            "field_name": "uniquename",
            "show_in_sections": [
                "orthologs"
            ],
            "url": "https://www.genenames.org/tools/hcop/#!/?q=<<IDENTIFIER>>&qtype=pombase&qtax_id=284812&ttax_id=9606&submit=true"
        },
        {
            "ref_type": "Orthologs",
            "feature_types": [
                "mRNA gene",
                "pseudogene"
            ],
            "name": "Ensembl Fungal Compara",
            "description": "Alignments, gene trees, orthologs, families",
            "field_name": "uniquename",
            "show_in_sections": [
                "orthologs"
            ],
            "url": "http://fungi.ensembl.org/Schizosaccharomyces_pombe/Gene/Compara?db=core;g=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Orthologs",
            "feature_types": [
                "mRNA gene",
                "pseudogene"
            ],
            "name": "Ensembl Pan-taxonomic Compara",
            "description": " Gene tree, orthologs",
            "field_name": "uniquename",
            "show_in_sections": [
                "orthologs"
            ],
            "url": "http://fungi.ensembl.org/Schizosaccharomyces_pombe/Gene/PanCompara?db=core;g=<<IDENTIFIER>>"
        },
        {
            "ref_type": "Orthologs",
            "feature_types": [
                "mRNA gene",
                "pseudogene"
            ],
            "name": "PANTHER",
            "description": "Orthologs, gene trees, alignments",
            "field_name": "uniprot_identifier",
            "show_in_sections": [
                "orthologs"
            ],
            "url": "http://www.pantherdb.org/genes/gene.do?acc=SCHPO%7CPomBase%3D<<UNIQUENAME>>%7CUniProtKB%3D<<IDENTIFIER>>"
        },
        {
            "ref_type": "Promoters",
            "feature_types": [
                "ALL"
            ],
            "name": "Eukaryotic Promoter Database",
            "description": "Experimentally validated promoters",
            "field_name": "uniquename",
            "url": "http://epd.vital-it.ch/search_EPDnew.php?db=S_pombe&query=<<IDENTIFIER>>"
        }
    ],
    "external_term_references": [
        {
            "name": "BioPortal",
            "url": "https://purl.bioontology.org/ontology/[conf_db_prefix]?conceptid=http%3A%2F%2Fpurl.obolibrary.org%2Fobo%2F[conf_db_prefix]_[id_no_prefix]"
        },
        {
            "name": "MISO",
            "url": "http://www.sequenceontology.org/browser/current_svn/term/[example_id]"
        },
        {
            "name": "Reactome",
            "url": "https://reactome.org/content/query?species=Schizosaccharomyces+pombe&q=[example_id]"
        },
        {
            "name": "Monarch Disease Ontology",
            "url": "https://monarchinitiative.org/disease/[example_id]"
        }
    ],
    "external_term_link_prefixes": [
        "CHEBI",
        "CL"
    ],
    "viability_terms": {
        "viable": "FYPO:0002060",
        "inviable": "FYPO:0002061"
    },
    "slims": {
        "cc_goslim_pombe": {
            "slim_display_name": "Cellular Component slim",
            "doc_path": "/documentation/pombase-go-slim-documentation",
            "full_slim_path": "/browse-curation/fission-yeast-cc-go-slim-terms",
            "description": "S. pombe high level GO cellular component terms",
            "slim_results_description": "A \"GO slim\" is a subset of the Gene Ontology terms selected for a specific purpose in interpreting the functional annotations in an entire organism, or a gene product list derived from an experiment.",
            "cv_name": "cellular_component",
            "terms": [
                {
                    "name": "cytosol",
                    "termid": "GO:0005829"
                },
                {
                    "name": "cytoplasmic vesicle",
                    "termid": "GO:0031410"
                },
                {
                    "name": "division septum",
                    "termid": "GO:0000935"
                },
                {
                    "name": "prospore membrane",
                    "termid": "GO:0005628"
                },
                {
                    "name": "extracellular region",
                    "termid": "GO:0005576"
                },
                {
                    "name": "cell surface",
                    "termid": "GO:0009986"
                },
                {
                    "name": "endoplasmic reticulum membrane",
                    "termid": "GO:0005789"
                },
                {
                    "name": "actin cytoskeleton",
                    "termid": "GO:0015629"
                },
                {
                    "name": "cytoplasmic stress granule",
                    "termid": "GO:0010494"
                },
                {
                    "name": "P-body",
                    "termid": "GO:0000932"
                },
                {
                    "name": "phagophore assembly site",
                    "termid": "GO:0000407"
                },
                {
                    "name": "peroxisome",
                    "termid": "GO:0005777"
                },
                {
                    "name": "Golgi apparatus",
                    "termid": "GO:0005794"
                },
                {
                    "name": "cell cortex",
                    "termid": "GO:0005938"
                },
                {
                    "name": "cell wall",
                    "termid": "GO:0005618"
                },
                {
                    "name": "cytosolic ribosome",
                    "termid": "GO:0022626"
                },
                {
                    "name": "actin cortical patch",
                    "termid": "GO:0030479"
                },
                {
                    "name": "nucleus",
                    "termid": "GO:0005634"
                },
                {
                    "name": "kinetochore",
                    "termid": "GO:0000776"
                },
                {
                    "name": "microtubule cytoskeleton",
                    "termid": "GO:0015630"
                },
                {
                    "name": "nuclear pore",
                    "termid": "GO:0005643"
                },
                {
                    "name": "nuclear periphery",
                    "termid": "GO:0034399"
                },
                {
                    "name": "nucleolus",
                    "termid": "GO:0005730"
                },
                {
                    "name": "nucleoplasm",
                    "termid": "GO:0005654"
                },
                {
                    "name": "endosome",
                    "termid": "GO:0005768"
                },
                {
                    "name": "vacuole",
                    "termid": "GO:0005773"
                },
                {
                    "name": "contractile ring",
                    "termid": "GO:0070938"
                },
                {
                    "name": "mitotic spindle",
                    "termid": "GO:0072686"
                },
                {
                    "name": "spindle pole body",
                    "termid": "GO:0005816"
                },
                {
                    "name": "endoplasmic reticulum",
                    "termid": "GO:0005783"
                },
                {
                    "name": "plasma membrane",
                    "termid": "GO:0005886"
                },
                {
                    "name": "mitochondrion",
                    "termid": "GO:0005739"
                },
                {
                    "name": "chromosome",
                    "termid": "GO:0005694"
                },
                {
                    "name": "preribosome",
                    "termid": "GO:0030684"
                }
            ]
        },
        "bp_goslim_pombe": {
            "slim_display_name": "Biological Process slim",
            "doc_path": "/documentation/pombase-go-slim-documentation",
            "full_slim_path": "/browse-curation/fission-yeast-bp-go-slim-terms",
            "description": "S. pombe high level GO biological process terms",
            "slim_results_description": "A \"GO slim\" is a subset of the Gene Ontology terms selected for a specific purpose in interpreting the functional annotations in an entire organism, or a gene product list derived from an experiment.  PomBase has created a GO slim to provide a simple summary of S. pombe's biological capabilities by grouping gene products using broad biological process classifiers.",
            "cv_name": "biological_process",
            "external_link_config": {
                "description": "Physical network interaction",
                "link_text": "esyN",
                "link_config_key": "esyn",
                "icon_image": "esyn_icon.png"
            },
            "terms": [
                {
                    "name": "DNA recombination",
                    "termid": "GO:0006310"
                },
                {
                    "name": "DNA repair",
                    "termid": "GO:0006281"
                },
                {
                    "name": "DNA replication",
                    "termid": "GO:0006260"
                },
                {
                    "name": "actin cytoskeleton organization",
                    "termid": "GO:0030036"
                },
                {
                    "name": "ascospore formation",
                    "termid": "GO:0030437"
                },
                {
                    "name": "autophagy",
                    "termid": "GO:0006914"
                },
                {
                    "name": "carbohydrate metabolic process",
                    "termid": "GO:0005975"
                },
                {
                    "name": "cell adhesion",
                    "termid": "GO:0007155"
                },
                {
                    "name": "cell wall organization or biogenesis",
                    "termid": "GO:0071554"
                },
                {
                    "name": "cellular amino acid metabolic process",
                    "termid": "GO:0006520"
                },
                {
                    "name": "chromatin organization",
                    "termid": "GO:0006325"
                },
                {
                    "name": "conjugation with cellular fusion",
                    "termid": "GO:0000747"
                },
                {
                    "name": "cytoplasmic translation",
                    "termid": "GO:0002181"
                },
                {
                    "name": "detoxification",
                    "termid": "GO:0098754"
                },
                {
                    "name": "establishment or maintenance of cell polarity",
                    "termid": "GO:0007163"
                },
                {
                    "name": "generation of precursor metabolites and energy",
                    "termid": "GO:0006091"
                },
                {
                    "name": "GPI anchored protein biosynthesis",
                    "termid": "GO:0180046"
                },
                {
                    "name": "inorganic ion homeostasis",
                    "termid": "GO:0098771"
                },
                {
                    "name": "iron-sulfur cluster assembly",
                    "termid": "GO:0016226"
                },
                {
                    "name": "lipid metabolic process",
                    "termid": "GO:0006629"
                },
                {
                    "name": "lipoprotein biosynthetic process",
                    "termid": "GO:0042158"
                },
                {
                    "name": "mRNA metabolic process",
                    "termid": "GO:0016071"
                },
                {
                    "name": "meiotic nuclear division",
                    "termid": "GO:0140013"
                },
                {
                    "name": "membrane organization",
                    "termid": "GO:0061024"
                },
                {
                    "name": "microtubule cytoskeleton organization",
                    "termid": "GO:0000226"
                },
                {
                    "name": "meiotic drive",
                    "termid": "GO:0110134"
                },
                {
                    "name": "mitochondrial gene expression",
                    "termid": "GO:0140053"
                },
                {
                    "name": "mitochondrion organization",
                    "termid": "GO:0007005"
                },
                {
                    "name": "mitotic cytokinesis",
                    "termid": "GO:0000281"
                },
                {
                    "name": "mitotic sister chromatid segregation",
                    "termid": "GO:0000070"
                },
                {
                    "name": "nitrogen cycle metabolic process",
                    "termid": "GO:0071941"
                },
                {
                    "name": "nucleobase-containing small molecule metabolic process",
                    "termid": "GO:0055086"
                },
                {
                    "name": "nucleocytoplasmic transport",
                    "termid": "GO:0006913"
                },
                {
                    "name": "organelle localization by membrane tethering",
                    "termid": "GO:0140056"
                },
                {
                    "name": "organophosphate metabolic process",
                    "termid": "GO:0019637"
                },

                {
                    "name": "peroxisome organization",
                    "termid": "GO:0007031"
                },
                {
                    "name": "protein catabolic process",
                    "termid": "GO:0030163"
                },
                {
                    "name": "protein-containing complex assembly",
                    "termid": "GO:0065003"
                },
                {
                    "name": "protein folding",
                    "termid": "GO:0006457"
                },
                {
                    "name": "protein glycosylation",
                    "termid": "GO:0006486"
                },
                {
                    "name": "protein maturation by iron-sulfur cluster transfer GO:0097428",
                    "termid": "GO:0097428"
                },
                {
                    "name": "protein modification by small protein conjugation or removal",
                    "termid": "GO:0070647"
                },
                {
                    "name": "protein targeting",
                    "termid": "GO:0006605"
                },
                {
                    "name": "pteridine-containing compound biosynthetic process",
                    "termid": "GO:0042559"
                },
                {
                    "name": "mitotic cell cycle phase transition",
                    "termid": "GO:0044772"
                },
                {
                    "name": "regulation of transcription, DNA-templated",
                    "termid": "GO:0006355"
                },
                {
                    "name": "ribosome biogenesis",
                    "termid": "GO:0042254"
                },
                {
                    "name": "secondary metabolic process",
                    "termid": "GO:0019748"
                },
                {
                    "name": "signaling",
                    "termid": "GO:0023052"
                },
                {
                    "name": "snoRNA metabolic process",
                    "termid": "GO:0016074"
                },
                {
                    "name": "snRNA metabolic process",
                    "termid": "GO:0016073"
                },
                {
                    "name": "sulfur compound metabolic process",
                    "termid": "GO:0006790"
                },
                {
                    "name": "tRNA metabolic process",
                    "termid": "GO:0006399"
                },
                {
                    "name": "telomere organization",
                    "termid": "GO:0032200"
                },
                {
                    "name": "tetrapyrrole metabolic process",
                    "termid": "GO:0033013"
                },
                {
                    "name": "transcription, DNA-templated",
                    "termid": "GO:0006351"
                },
                {
                    "name": "transmembrane transport",
                    "termid": "GO:0055085"
                },
                {
                    "name": "ubiquinone metabolic process",
                    "termid": "GO:0006743"
                },
                {
                    "name": "vesicle-mediated transport",
                    "termid": "GO:0016192"
                },
                {
                    "name": "vitamin metabolic process",
                    "termid": "GO:0006766"
                }
            ]
        },
        "mf_goslim_pombe": {
            "slim_display_name": "Molecular Function slim",
            "doc_path": "/documentation/pombase-go-slim-documentation",
            "full_slim_path": "/browse-curation/fission-yeast-mf-go-slim-terms",
            "description": "S. pombe high level GO molecular function terms",
            "slim_results_description": "A \"GO slim\" is a subset of the Gene Ontology terms selected for a specific purpose in interpreting the functional annotations in an entire organism, or a gene product list derived from an experiment.",
            "cv_name": "molecular_function",
            "terms": [
                {
                    "name": "1,3-beta-glucanosyltransferase activity",
                    "termid": "GO:0042124"
                },
                {
                    "name": "ATPase activity",
                    "termid": "GO:0016887"
                },
                {
                    "name": "DNA-binding transcription factor activity",
                    "termid": "GO:0003700"
                },
                {
                    "name": "GTPase activity",
                    "termid": "GO:0003924"
                },
                {
                    "name": "actin binding",
                    "termid": "GO:0003779"
                },
                {
                    "name": "cargo receptor activity",
                    "termid": "GO:0038024"
                },
                {
                    "name": "catalytic activity, acting on DNA",
                    "termid": "GO:0140097"
                },
                {
                    "name": "catalytic activity, acting on RNA",
                    "termid": "GO:0140098"
                },
                {
                    "name": "catalytic activity, acting on a protein",
                    "termid": "GO:0140096"
                },
                {
                    "name": "catalytic activity, acting on a tRNA",
                    "termid": "GO:0140101"
                },
                {
                    "name": "cell adhesion mediator activity",
                    "termid": "GO:0098631"
                },
                {
                    "name": "cyclase activity",
                    "termid": "GO:0009975"
                },
                {
                    "name": "deacetylase activity",
                    "termid": "GO:0019213"
                },
                {
                    "name": "disulfide oxidoreductase activity",
                    "termid": "GO:0015036"
                },
                {
                    "name": "enzyme regulator activity",
                    "termid": "GO:0030234"
                },
                {
                    "name": "exonuclease activity",
                    "termid": "GO:0004527"
                },
                {
                    "name": "general transcription initiation factor activity",
                    "termid": "GO:0140223"
                },
                {
                    "name": "guanyl-nucleotide exchange factor activity",
                    "termid": "GO:0005085"
                },
                {
                    "name": "histone demethylase activity",
                    "termid": "GO:0032452"
                },
                {
                    "name": "hydrolase activity, acting on carbon-nitrogen (but not peptide) bonds",
                    "termid": "GO:0016810"
                },
                {
                    "name": "hydrolase activity, acting on ester bonds",
                    "termid": "GO:0016788"
                },
                {
                    "name": "hydrolase activity, acting on glycosyl bonds",
                    "termid": "GO:0016798"
                },
                {
                    "name": "intramembrane lipid transporter activity",
                    "termid": "GO:0140303"
                },
                {
                    "name": "isomerase activity",
                    "termid": "GO:0016853"
                },
                {
                    "name": "kinase activity",
                    "termid": "GO:0016301"
                },
                {
                    "name": "ligase activity",
                    "termid": "GO:0016874"
                },
                {
                    "name": "lipid binding",
                    "termid": "GO:0008289"
                },
                {
                    "name": "lipid transfer activity",
                    "termid": "GO:0120013"
                },
                {
                    "name": "lyase activity",
                    "termid": "GO:0016829"
                },
                {
                    "name": "microtubule binding",
                    "termid": "GO:0008017"
                },
                {
                    "name": "molecular adaptor activity",
                    "termid": "GO:0060090"
                },
                {
                    "name": "molecular carrier activity",
                    "termid": "GO:0140104"
                },
                {
                    "name": "molecular function regulator",
                    "termid": "GO:0098772"
                },
                {
                    "name": "molecular sequestering activity",
                    "termid": "GO:0140313"
                },
                {
                    "name": "motor activity",
                    "termid": "GO:0003774"
                },
                {
                    "name": "nucleocytoplasmic carrier activity",
                    "termid": "GO:0140142"
                },
                {
                    "name": "nucleoside-triphosphate diphosphatase activity",
                    "termid": "GO:0047429"
                },
                {
                    "name": "nucleotidyltransferase activity",
                    "termid": "GO:0016779"
                },
                {
                    "name": "oxidoreductase activity",
                    "termid": "GO:0016491"
                },
                {
                    "name": "peptidase activity",
                    "termid": "GO:0008233"
                },
                {
                    "name": "peptide N-acetyltransferase activity",
                    "termid": "GO:0034212"
                },
                {
                    "name": "peroxiredoxin activity",
                    "termid": "GO:0051920"
                },
                {
                    "name": "pheromone activity",
                    "termid": "GO:0005186"
                },
                {
                    "name": "phosphoprotein phosphatase activity",
                    "termid": "GO:0004721"
                },
                {
                    "name": "phosphotransferase activity, for other substituted phosphate groups",
                    "termid": "GO:0016780"
                },
                {
                    "name": "protein deacetylase activity",
                    "termid": "GO:0033558"
                },
                {
                    "name": "protein kinase activity",
                    "termid": "GO:0004672"
                },
                {
                    "name": "protein methyltransferase activity",
                    "termid": "GO:0008276"
                },
                {
                    "name": "protein sequestering activity",
                    "termid": "GO:0140311"
                },
                {
                    "name": "protein tag",
                    "termid": "GO:0031386"
                },
                {
                    "name": "protein transporter activity",
                    "termid": "GO:0140318"
                },
                {
                    "name": "signal sequence binding",
                    "termid": "GO:0005048"
                },
                {
                    "name": "signaling receptor activity",
                    "termid": "GO:0038023"
                },
                {
                    "name": "small molecule sensor activity",
                    "termid": "GO:0140299"
                },
                {
                    "name": "structural molecule activity",
                    "termid": "GO:0005198"
                },
                {
                    "name": "topological DNA entrapment activity",
                    "termid": "GO:0061776"
                },
                {
                    "name": "transcription coactivator activity",
                    "termid": "GO:0003713"
                },
                {
                    "name": "transcription coregulator activity",
                    "termid": "GO:0003712"
                },
                {
                    "name": "transcription elongation regulator activity",
                    "termid": "GO:0003711"
                },
                {
                    "name": "transferase activity, transferring acyl groups",
                    "termid": "GO:0016746"
                },
                {
                    "name": "transferase activity, transferring aldehyde or ketonic groups",
                    "termid": "GO:0016744"
                },
                {
                    "name": "transferase activity, transferring alkyl or aryl (other than methyl) groups",
                    "termid": "GO:0016765"
                },
                {
                    "name": "transferase activity, transferring glycosyl groups",
                    "termid": "GO:0016757"
                },
                {
                    "name": "transferase activity, transferring nitrogenous groups",
                    "termid": "GO:0016769"
                },
                {
                    "name": "transferase activity, transferring one-carbon groups",
                    "termid": "GO:0016741"
                },
                {
                    "name": "transferase activity, transferring sulfur-containing groups",
                    "termid": "GO:0016782"
                },
                {
                    "name": "translation regulator activity",
                    "termid": "GO:0045182"
                },
                {
                    "name": "transmembrane transporter activity",
                    "termid": "GO:0022857"
                },
                {
                    "name": "ubiquitin-like protein transferase activity",
                    "termid": "GO:0019787"
                },
                {
                    "name": "unfolded protein binding",
                    "termid": "GO:0051082"
                }
            ]
        },
        "fypo_slim": {
            "slim_display_name": "Phenotype slim",
            "full_slim_path": "/browse-curation/fypo-slim",
            "description": "High level fission yeast phenotype terms",
            "slim_results_description": "A \"slim\" is a high-level subset of terms from an ontology, used to analyze sets of annotated genes. PomBase has created a slim from the Fission Yeast Phenotype Ontology (FYPO) to provide a summary of fission yeast phenotypes.",
            "cv_name": "single_locus_phenotype",
            "terms": [
                {
                    "name": "abnormal cell morphology",
                    "termid": "FYPO:0000005"
                },
                {
                    "name": "abnormal cytokinesis",
                    "termid": "FYPO:0000032"
                },
                {
                    "name": "abnormal gene expression",
                    "termid": "FYPO:0006551"
                },
                {
                    "name": "abnormal microtubule cytoskeleton organization",
                    "termid": "FYPO:0000054"
                },
                {
                    "name": "abnormal spindle",
                    "termid": "FYPO:0000120"
                },
                {
                    "name": "abnormal sporulation",
                    "termid": "FYPO:0000121"
                },
                {
                    "name": "localization phenotype",
                    "termid": "FYPO:0000138"
                },
                {
                    "name": "cell population growth phenotype",
                    "termid": "FYPO:0000139"
                },
                {
                    "name": "DNA metabolism phenotype",
                    "termid": "FYPO:0000293"
                },
                {
                    "name": "RNA metabolism phenotype",
                    "termid": "FYPO:0000294"
                },
                {
                    "name": "cellular response phenotype",
                    "termid": "FYPO:0000298"
                },
                {
                    "name": "mating phenotype",
                    "termid": "FYPO:0000301"
                },
                {
                    "name": "abnormal mitosis",
                    "termid": "FYPO:0000337"
                },
                {
                    "name": "cell cycle phenotype",
                    "termid": "FYPO:0000631"
                },
                {
                    "name": "abnormal chromosome organization",
                    "termid": "FYPO:0000641"
                },
                {
                    "name": "DNA binding phenotype",
                    "termid": "FYPO:0000653"
                },
                {
                    "name": "catalytic activity phenotype",
                    "termid": "FYPO:0000654"
                },
                {
                    "name": "protein-protein interaction phenotype",
                    "termid": "FYPO:0000702"
                },
                {
                    "name": "vegetative cell phenotype",
                    "termid": "FYPO:0001320"
                },
                {
                    "name": "viable cell population",
                    "termid": "FYPO:0002058"
                },
                {
                    "name": "inviable cell population",
                    "termid": "FYPO:0002059"
                },
                {
                    "name": "abnormal transport",
                    "termid": "FYPO:0001720"
                },
                {
                    "name": "protein-RNA interaction phenotype",
                    "termid": "FYPO:0002131"
                },
                {
                    "name": "abnormal protein metabolic process",
                    "termid": "FYPO:0002274"
                },
                {
                    "name": "abnormal microtubule cytoskeleton",
                    "termid": "FYPO:0002399"
                },
                {
                    "name": "abnormal nucleus",
                    "termid": "FYPO:0002403"
                },
                {
                    "name": "abnormal protein modification",
                    "termid": "FYPO:0002494"
                },
                {
                    "name": "increased resistance to chemical",
                    "termid": "FYPO:0002682"
                },
                {
                    "name": "increased sensitivity to chemical",
                    "termid": "FYPO:0002683"
                },
                {
                    "name": "abnormal cell wall",
                    "termid": "FYPO:0002946"
                },
                {
                    "name": "abnormal actin cytoskeleton organization",
                    "termid": "FYPO:0004803"
                },
                {
                    "name": "abnormal cell wall organization",
                    "termid": "FYPO:0004805"
                },
                {
                    "name": "abnormal cell polarity",
                    "termid": "FYPO:0005568"
                }
            ]
        },
        "pombe_mondo_slim": {
            "slim_display_name": "Disease slim",
            "doc_path": "/browse-curation/disease-slim",
            "full_slim_path": "/browse-curation/disease-slim",
            "description": "High level disease terms",
            "slim_results_description": "A \"slim\" is a high-level subset of terms from an ontology, used to analyze sets of annotated genes. PomBase has created a slim from the Mondo Disease Ontology (Mondo) to provide a simple summary of S. pombe genes with human orthologs implicated in disease.",
            "cv_name": "mondo",
            "terms": [
                {
                    "name": "premature aging syndrome",
                    "termid": "MONDO:0019303"
                },
                {
                    "name": "DNA repair disease",
                    "termid": "MONDO:0021190"
                },
                {
                    "name": "diabetes mellitus (disease)",
                    "termid": "MONDO:0005015"
                },
                {
                    "name": "inherited amino acid metabolic disorder",
                    "termid": "MONDO:0004736"
                },
                {
                    "name": "inborn disorder of purine or pyrimidine metabolism",
                    "termid": "MONDO:0019254"
                },
                {
                    "name": "peroxisomal disease",
                    "termid": "MONDO:0019053"
                },
                {
                    "name": "inherited lipid metabolism disorder",
                    "termid": "MONDO:0002525"
                },
                {
                    "name": "inborn mitochondrial metabolism disorder",
                    "termid": "MONDO:0004069"
                },
                {
                    "name": "lysosomal storage disease",
                    "termid": "MONDO:0002561"
                },
                {
                    "name": "carbohydrate metabolism disease",
                    "termid": "MONDO:0037792"
                },
                {
                    "name": "peripheral neuropathy",
                    "termid": "MONDO:0005244"
                },
                {
                    "name": "neurodegenerative disease",
                    "termid": "MONDO:0005559"
                },
                {
                    "name": "dystonic disorder",
                    "termid": "MONDO:0003441"
                },
                {
                    "name": "epilepsy",
                    "termid": "MONDO:0005027"
                },
                {
                    "name": "cognitive disorder",
                    "termid": "MONDO:0002039"
                },
                {
                    "name": "bone development disease",
                    "termid": "MONDO:0005497"
                },
                {
                    "name": "anemia (disease)",
                    "termid": "MONDO:0002280"
                },
                {
                    "name": "nonsyndromic genetic deafness",
                    "termid": "MONDO:0019497"
                },
                {
                    "name": "eye disease",
                    "termid": "MONDO:0005328"
                },
                {
                    "name": "skin disease",
                    "termid": "MONDO:0005093"
                },
                {
                    "name": "kidney disease",
                    "termid": "MONDO:0005240"
                },
                {
                    "name": "hepatobiliary disease",
                    "termid": "MONDO:0002515"
                },
                {
                    "name": "heart disease",
                    "termid": "MONDO:0005267"
                },
                {
                    "name": "myopathy",
                    "termid": "MONDO:0005336"
                },
                {
                    "name": "proteostasis deficiencies",
                    "termid": "MONDO:0021179"
                },
                {
                    "name": "inherited porphyria",
                    "termid": "MONDO:0019142"
                },
                {
                    "name": "immune system disease",
                    "termid": "MONDO:0005046"
                },
                {
                    "name": "cancer",
                    "termid": "MONDO:0004992"
                },
                {
                    "name": "autosomal dominant disease",
                    "termid": "MONDO:0000426"
                },
                {
                    "name": "autosomal recessive disease",
                    "termid": "MONDO:0006025"
                },
                {
                    "name": "gamma-glutamyl cycle",
                    "termid": "MONDO:0019241"
                },
                {
                    "name": "digestive system disease",
                    "termid": "MONDO:0004335"
                },
                {
                    "name": "hematologic disease",
                    "termid": "MONDO:0005570"
                },
                {
                    "name": "infertility disorder",
                    "termid": "MONDO:0005047"
                },
                {
                    "name": "central nervous system malformation",
                    "termid": "MONDO:0020022"
                },
                {
                    "name": "disorder of glycosylation",
                    "termid": "MONDO:0024322"
                },
                {
                    "name": "neurodevelopmental disorder",
                    "termid": "MONDO:0700092"
                },
                {
                    "name": "congenital nervous system disorder",
                    "termid": "MONDO:0002320"
                },
                {
                    "name": "metabolic disease",
                    "termid": "MONDO:0005066"
                }
            ]
        }
    },
    "reference_page_config": {
        "triage_status_to_ignore": []
    },
    "extra_database_aliases": {
        "tigrfams": "JCVI_TIGRFAMS",
        "ssf": "SUPERFAMILY",
        "mobidblt": "MobiDB",
        "amigo": "GO",
        "cathgene3d": "Gene3D",
        "scanprosite": "Prosite",
        "prositeprofiles": "Prosite",
        "prosite_profiles": "Prosite",
        "prositepatterns": "Prosite",
        "coils": "Coiled coils"
    },
    "misc_external_links": {
        "esyn": "http://esyn.rosalind.kcl.ac.uk/builder.php?type=Graph&term=<<IDENTIFIER>>&interactionType=physical&source=pombase&includeInteractors=false",
        "MalaCards": "https://www.malacards.org/card/<<IDENTIFIER>>",
        "Monarch": "https://monarchinitiative.org/MONDO:<<IDENTIFIER>>"
    },
    "interpro": {
        "dbnames_to_filter": ["PIRSR"]
    },
    "server": {
        "subsets": {
            "prefixes_to_remove": [
                "interpro:CDD",
                "interpro:MOBIDBLT",
                "interpro:PANTHER",
                "interpro:PANTHER",
                "interpro:PFAM",
                "interpro:PIRSF",
                "interpro:PIRSR",
                "interpro:PRINTS",
                "interpro:PRODOM",
                "interpro:PROFILE",
                "interpro:PROSITE",
                "interpro:SFLD",
                "interpro:SMART",
                "interpro:SSF",
                "interpro:TIGRFAMs",
                "interpro"
            ]
        },
        "solr_url": "http://localhost:8983/solr",
        "django_url": "http://localhost:8999",
        "exact_synonym_boost": 0.25,
        "narrow_synonym_boost": 0.1,
        "distant_synonym_boost": 0.05,
        "term_definition_boost": 0.05,
        "cv_name_for_terms_search": "(molecular_function OR biological_process OR cellular_component OR fission_yeast_phenotype OR mondo)",
        "gene_uniquename_re": "^SP([ABCN][CTP]|RR|SN|MIT|MT)[\\d\\w]*\\.\\d\\d\\d?\\d?c?$"
    },
    "gene_results": {
        "field_config": {
            "uniquename": {
                "display_name": "Systematic ID",
                "column_type": "string"
            },
            "name": {
                "display_name": "Gene name",
                "column_type": "string"
            },
            "synonyms": {
                "display_name": "Synonyms",
                "column_type": "list"
            },
            "pdb_ids": {
                "display_name": "PDB structure IDs",
                "column_type": "list"
            },
            "paralogs": {
                "display_name": "Paralog IDs",
                "column_type": "list"
            },
            "rnacentral_id": {
                "display_name": "RNACentral ID",
                "column_type": "string"
            },
            "product": {
                "display_name": "Product description",
                "column_type": "string"
            },
            "uniprot_identifier": {
                "display_name": "UniProt ID",
                "column_type": "string"
            },
            "feature_type": {
                "display_name": "Feature type",
                "column_type": "string"
            },
            "start_pos": {
                "display_name": "Start position",
                "column_type": "number"
            },
            "end_pos": {
                "display_name": "End position",
                "column_type": "number"
            },
            "chromosome_name": {
                "display_name": "Chromosome",
                "column_type": "string"
            },
            "strand": {
                "display_name": "Strand",
                "column_type": "string"
            },
            "physical_interactors": {
                "display_name": "Physical interactors",
                "column_type": "gene_list"
            },
            "orthologs:4932": {
                "display_name": "Budding yeast orthologs",
                "column_type": "orthologs"
            },
            "orthologs:4897": {
                "display_name": "S. japonicus orthologs",
                "column_type": "orthologs"
            },
            "orthologs:9606": {
                "display_name": "Human orthologs",
                "column_type": "orthologs"
            },
            "deletion_viability": {
                "display_name": "Deletion viability",
                "column_type": "attribute",
                "attr_values": [
                    {
                        "name": "inviable",
                        "color": "#c32"
                    },
                    {
                        "name": "depends_on_conditions",
                        "color": "#66f",
                        "display_name": "variable"
                    },
                    {
                        "name": "viable",
                        "color": "#3d3"
                    },
                    {
                        "name": "unknown",
                        "color": "#fff",
                        "display_name": "no data"
                    }
                ]
            },
            "ortholog_taxonids:4932": {
                "display_name": "Budding yeast ortholog",
                "column_type": "ortholog",
                "attr_values": [
                    {
                        "name": "absent",
                        "color": "#c32"
                    },
                    {
                        "name": "present",
                        "color": "#3c2"
                    }
                ]
            },
            "ortholog_taxonids:4897": {
                "display_name": "S. japonicus ortholog",
                "column_type": "ortholog",
                "attr_values": [
                    {
                        "name": "absent",
                        "color": "#c32"
                    },
                    {
                        "name": "present",
                        "color": "#3d3"
                    }
                ]
            },
            "ortholog_taxonids:9606": {
                "display_name": "Human ortholog",
                "column_type": "ortholog",
                "attr_values": [
                    {
                        "name": "absent",
                        "color": "#c32"
                    },
                    {
                        "name": "present",
                        "color": "#3d3"
                    }
                ]
            },
            "tmm": {
                "display_name": "Transmembrane domain",
                "column_type": "attribute",
                "attr_values": [
                    {
                        "name": "present",
                        "color": "#283048"
                    },
                    {
                        "name": "absent",
                        "color": "#e8e8e8"
                    },
                    {
                        "name": "not_applicable",
                        "display_name": "not applicable",
                        "color": "#bbf"
                    }
                ]
            },
            "go_process_superslim": {
                "display_name": "GO process",
                "column_type": "ontology_term",
                "attr_values": [
                    {
                        "name": "signaling",
                        "termid": "GO:0023052",
                        "color": "#9b9144"
                    },
                    {
                        "name": "gene expression",
                        "termid": "GO:0010467",
                        "color": "#5f1827"
                    },
                    {
                        "name": "chromatin organization",
                        "termid": "GO:0006325",
                        "color": "#9d7b69"
                    },
                    {
                        "name": "protein folding",
                        "termid": "GO:0006457",
                        "color": "#5544ff"
                    },
                    {
                        "name": "cellular component biogenesis",
                        "termid": "GO:0044085",
                        "color": "#da4837"
                    },
                    {
                        "name": "DNA metabolic process",
                        "termid": "GO:0006259",
                        "color": "#198744"
                    },
                    {
                        "name": "cell cycle",
                        "termid": "GO:0007049",
                        "color": "#e41532"
                    },
                    {
                        "name": "cytoskeleton organization",
                        "termid": "GO:0007010",
                        "color": "#cc64d9"
                    },
                    {
                        "name": "membrane organization",
                        "termid": "GO:0061024",
                        "color": "#efbf98"
                    },
                    {
                        "name": "lipid metabolic process",
                        "termid": "GO:0006629",
                        "color": "#394b2d"
                    },
                    {
                        "name": "small molecule metabolic process",
                        "termid": "GO:0044281",
                        "color": "#d998f5"
                    },
                    {
                        "name": "generation of precursor metabolites and energy",
                        "termid": "GO:0006091",
                        "color": "#e5c17c"
                    },
                    {
                        "name": "transport",
                        "termid": "GO:0006810",
                        "color": "#c2dd36"
                    },
                    {
                        "name": "cellular catabolic process",
                        "termid": "GO:0044248",
                        "color": "#bbc9b7"
                    },
                    {
                        "name": "detoxification",
                        "termid": "GO:0098754",
                        "color": "#9960d8"
                    },
                    {
                        "name": "other",
                        "color": "#a73"
                    },
                    {
                        "name": "unknown",
                        "color": "#fff"
                    }
                ]
            },
            "go_component": {
                "display_name": "GO component",
                "column_type": "ontology_term",
                "attr_values": [
                    {
                        "name": "nucleus",
                        "termid": "GO:0005634",
                        "color": "#c32"
                    },
                    {
                        "name": "endoplasmic reticulum",
                        "termid": "GO:0005783",
                        "color": "#4f4"
                    },
                    {
                        "name": "mitochondrion",
                        "termid": "GO:0005739",
                        "color": "#04f"
                    },
                    {
                        "name": "cytoplasm",
                        "termid": "GO:0005737",
                        "color": "#ca3"
                    },
                    {
                        "name": "other",
                        "color": "#aaa"
                    },
                    {
                        "name": "unknown",
                        "color": "#fff"
                    }
                ]
            },
            "go_function": {
                "display_name": "GO function",
                "column_type": "ontology_term",
                "attr_values": [
                    {
                        "name": "oxidoreductase activity",
                        "termid": "GO:0016491",
                        "color": "#89a6a1"
                    },
                    {
                        "name": "hydrolase activity",
                        "termid": "GO:0016787",
                        "color": "#2a394a"
                    },
                    {
                        "name": "transferase activity",
                        "termid": "GO:0016740",
                        "color": "#6d3ba4"
                    },
                    {
                        "name": "ligase activity",
                        "termid": "GO:0016874",
                        "color": "#da9d46"
                    },
                    {
                        "name": "cyclase activity",
                        "termid": "GO:0009975",
                        "color": "#d770f5"
                    },
                    {
                        "name": "lyase activity",
                        "termid": "GO:0016829",
                        "color": "#99a1c0"
                    },
                    {
                        "name": "catalytic activity",
                        "termid": "GO:0003824",
                        "color": "#dd37de",
                        "display_name": "other catalytic activity"
                    },
                    {
                        "name": "molecular adaptor activity",
                        "termid": "GO:0060090",
                        "color": "#3e9"
                    },
                    {
                        "name": "enzyme regulator activity",
                        "termid": "GO:0030234",
                        "color": "#feb62f"
                    },
                    {
                        "name": "DNA binding",
                        "termid": "GO:0003677",
                        "color": "#821fad"
                    },
                    {
                        "name": "transcription regulator activity",
                        "termid": "GO:0140110",
                        "color": "#cb3063"
                    },
                    {
                        "name": "transporter activity",
                        "termid": "GO:0005215",
                        "color": "#f498f0"
                    },
                    {
                        "name": "cytoskeletal protein binding",
                        "termid": "GO:0008092",
                        "color": "#6ba420"
                    },
                    {
                        "name": "unfolded protein binding",
                        "termid": "GO:0051082",
                        "color": "#21a18f"
                    },
                    {
                        "name": "RNA binding",
                        "termid": "GO:0003723",
                        "color": "#f6a0cd"
                    },
                    {
                        "name": "metal ion binding",
                        "termid": "GO:0046872",
                        "color": "#bc6384"
                    },
                    {
                        "name": "lipid binding",
                        "termid": "GO:0008289",
                        "color": "#22849b"
                    },
                    {
                        "name": "cell adhesion mediator activity",
                        "termid": "GO:0098631",
                        "color": "#3f6865"
                    },
                    {
                        "name": "structural molecule activity",
                        "termid": "GO:0005198",
                        "color": "#cdb4da"
                    },
                    {
                        "name": "other",
                        "color": "#a73"
                    },
                    {
                        "name": "unknown",
                        "color": "#fff"
                    }
                ]
            },
            "characterisation_status": {
                "display_name": "Characterisation status",
                "column_type": "attribute",
                "attr_values": [
                    {
                        "name": "biological role published",
                        "color": "#2d3"
                    },
                    {
                        "name": "dubious",
                        "color": "#299"
                    },
                    {
                        "name": "Schizosaccharomyces pombe specific protein, uncharacterized",
                        "display_name": "S. pombe specific protein, uncharacterized",
                        "color": "#359"
                    },
                    {
                        "name": "Schizosaccharomyces specific protein, uncharacterized",
                        "display_name": "Schizosaccharomyces specific, uncharacterized",
                        "color": "#9af"
                    },
                    {
                        "name": "fission yeast specific family",
                        "color": "#23a"
                    },
                    {
                        "name": "biological role inferred",
                        "color": "#c22"
                    },
                    {
                        "name": "transposon",
                        "color": "#d72"
                    },
                    {
                        "name": "conserved unknown",
                        "color": "#36f"
                    },
                    {
                        "name": "unknown",
                        "display_name": "not curated for this datatype",
                        "color": "#aaa"
                    }
                ]
            },
            "taxonomic_distribution": {
                "display_name": "Taxonomic distribution",
                "column_type": "attribute",
                "attr_values": [
                    {
                        "name": "fungi and prokaryotes",
                        "color": "#1c2"
                    },
                    {
                        "name": "eukaryotes only, fungi and metazoa",
                        "color": "#de3"
                    },
                    {
                        "name": "eukaryotes and prokaryotes",
                        "color": "#35b"
                    },
                    {
                        "name": "fungi only",
                        "color": "#39e"
                    },
                    {
                        "name": "S. pombe specific",
                        "color": "#23a"
                    },
                    {
                        "name": "Schizos. specific",
                        "color": "#a26"
                    },
                    {
                        "name": "dubious",
                        "color": "#f52"
                    },
                    {
                        "name": "not curated",
                        "color": "#882"
                    },
                    {
                        "name": "other",
                        "color": "#bbb"
                    }
                ]
            },
            "molecular_weight": {
                "display_name": "Protein molecular weight",
                "column_type": "number"
            },
            "protein_length": {
                "display_name": "Protein length",
                "column_type": "number"
            },
            "spliced_rna_length": {
                "display_name": "Spliced RNA length",
                "column_type": "number"
            },
            "unspliced_rna_length": {
                "display_name": "Unspliced RNA length",
                "column_type": "number"
            },
            "protein_length_bin": {
                "display_name": "Protein length (bins)",
                "column_type": "bins",
                "attr_values": [
                    {
                        "name": "1-100",
                        "color": "#e14e4e",
                        "bin_start": 1,
                        "bin_end": 100
                    },
                    {
                        "name": "101-200",
                        "color": "#d0804b",
                        "bin_start": 101,
                        "bin_end": 200
                    },
                    {
                        "name": "201-300",
                        "color": "#e0b030",
                        "bin_start": 201,
                        "bin_end": 300
                    },
                    {
                        "name": "301-400",
                        "color": "#3c2",
                        "bin_start": 301,
                        "bin_end": 400
                    },
                    {
                        "name": "401-500",
                        "color": "#40d3a6",
                        "bin_start": 401,
                        "bin_end": 500
                    },
                    {
                        "name": "501-600",
                        "color": "#30a0d0",
                        "bin_start": 501,
                        "bin_end": 600
                    },
                    {
                        "name": "601-700",
                        "color": "#6a5be0",
                        "bin_start": 601,
                        "bin_end": 700
                    },
                    {
                        "name": "701-",
                        "color": "#d054e3",
                        "bin_start": 701,
                        "bin_end": 999999999
                    },
                    {
                        "name": "unknown",
                        "display_name": "unknown / not applicable",
                        "color": "#777"
                    }
                ]
            }
        },
        "gene_summary_field_names": [
            "uniquename",
            "name",
            "product",
            "uniprot_identifier",
            "synonyms",
            "feature_type",
            "start_pos",
            "end_pos",
            "chromosome_name",
            "strand",
            "orthologs:4932",
            "orthologs:4897",
            "orthologs:9606"
        ],
        "gene_table_field_names": [
            "uniquename",
            "name",
            "product",
            "uniprot_identifier",
            "synonyms",
            "feature_type",
            "start_pos",
            "end_pos",
            "chromosome_name",
            "strand",
            "orthologs:4932",
            "orthologs:4897",
            "orthologs:9606",
            "physical_interactors",
            "deletion_viability",
            "tmm",
            "characterisation_status",
            "taxonomic_distribution",
            "pdb_ids",
            "paralogs",
            "rnacentral_id",
            "molecular_weight",
            "protein_length",
            "protein_length_bin",
            "spliced_rna_length",
            "unspliced_rna_length"
        ],
        "visualisation_extra_column_cv_names": [
            "molecular_function",
            "biological_process",
            "cellular_component",
            "mondo"
        ],
        "visualisation_field_names": [
            "deletion_viability",
            "ortholog_taxonids:4932",
            "ortholog_taxonids:4897",
            "ortholog_taxonids:9606",
            "tmm",
            "go_process_superslim",
            "go_component",
            "go_function",
            "characterisation_status",
            "taxonomic_distribution",
            "protein_length_bin"
        ],
        "slim_table_slim_names": [
            "mf_goslim_pombe",
            "bp_goslim_pombe",
            "cc_goslim_pombe",
            "fypo_slim",
            "pombe_mondo_slim"
        ]
    },
    "file_exports": {
        "site_map_term_prefixes": [
            "GO:",
            "FYPO:"
        ],
        "site_map_reference_prefixes": [
            "PMID:"
        ],
        "include_nd_lines": true,
        "macromolecular_complexes": {
            "parent_complex_termid": "GO:0032991",
            "excluded_terms": [
                "GO:0099023",
                "GO:0022624",
                "GO:0005834",
                "GO:0000153",
                "GO:0000835",
                "GO:1990904",
                "GO:0032806",
                "GO:0000307",
                "GO:0016461",
                "GO:1990421",
                "GO:0008023",
                "GO:0017102",
                "GO:0044796",
                "GO:0032777",
                "GO:0033100",
                "GO:0000113",
                "GO:0034457",
                "GO:0030685",
                "GO:0097648",
                "GO:0098802",
                "GO:0097648",
                "GO:0034506",
                "GO:0038037",
                "GO:0045267",
                "GO:0098797",
                "GO:0098796",
                "GO:0061695",
                "GO:0098797",
                "GO:0098798",
                "GO:0098799",
                "GO:0098800",
                "GO:0098803",
                "GO:1902555",
                "GO:0000015",
                "GO:0000109",
                "GO:0000118",
                "GO:0000120",
                "GO:0000123",
                "GO:0000151",
                "GO:0000152",
                "GO:0000159",
                "GO:0000178",
                "GO:0000313",
                "GO:0000314",
                "GO:0000315",
                "GO:0000428",
                "GO:0000445",
                "GO:0000775",
                "GO:0000776",
                "GO:0000777",
                "GO:0000778",
                "GO:0000779",
                "GO:0000780",
                "GO:0000782",
                "GO:0000785",
                "GO:0000786",
                "GO:0000790",
                "GO:0000791",
                "GO:0000792",
                "GO:0000796",
                "GO:0000808",
                "GO:0000941",
                "GO:0000942",
                "GO:0002178",
                "GO:0005667",
                "GO:0005677",
                "GO:0005681",
                "GO:0005719",
                "GO:0005720",
                "GO:0005721",
                "GO:0005724",
                "GO:0005732",
                "GO:0005761",
                "GO:0005818",
                "GO:0005838",
                "GO:0005840",
                "GO:0005844",
                "GO:0005871",
                "GO:0005874",
                "GO:0005875",
                "GO:0005891",
                "GO:0005952",
                "GO:0008012",
                "GO:0008278",
                "GO:0008287",
                "GO:0009331",
                "GO:0015934",
                "GO:0015935",
                "GO:0016459",
                "GO:0016469",
                "GO:0016471",
                "GO:0016585",
                "GO:0017053",
                "GO:0017059",
                "GO:0022626",
                "GO:0030117",
                "GO:0030118",
                "GO:0030119",
                "GO:0030120",
                "GO:0030125",
                "GO:0030128",
                "GO:0030130",
                "GO:0030131",
                "GO:0030132",
                "GO:0030529",
                "GO:0030530",
                "GO:0030532",
                "GO:0030681",
                "GO:0030684",
                "GO:0030686",
                "GO:0030687",
                "GO:0030874",
                "GO:0030880",
                "GO:0030892",
                "GO:0030894",
                "GO:0031211",
                "GO:0031248",
                "GO:0031332",
                "GO:0031379",
                "GO:0031414",
                "GO:0031461",
                "GO:0031618",
                "GO:0031933",
                "GO:0031934",
                "GO:0032300",
                "GO:0032301",
                "GO:0032302",
                "GO:0032991",
                "GO:0032993",
                "GO:0033177",
                "GO:0033178",
                "GO:0033202",
                "GO:0033553",
                "GO:0033573",
                "GO:0034702",
                "GO:0034703",
                "GO:0034704",
                "GO:0034707",
                "GO:0034708",
                "GO:0035770",
                "GO:0036464",
                "GO:0042788",
                "GO:0043189",
                "GO:0043234",
                "GO:0043235",
                "GO:0043527",
                "GO:0043601",
                "GO:0044391",
                "GO:0044798",
                "GO:0044815",
                "GO:0045239",
                "GO:0045259",
                "GO:0045263",
                "GO:0045271",
                "GO:0045274",
                "GO:0045282",
                "GO:0046930",
                "GO:0055029",
                "GO:0070069",
                "GO:0070461",
                "GO:0070603",
                "GO:0070822",
                "GO:0071010",
                "GO:0071819",
                "GO:0090544",
                "GO:0090568",
                "GO:0090575",
                "GO:0097346",
                "GO:0097361",
                "GO:1902377",
                "GO:1902493",
                "GO:1902494",
                "GO:1902495",
                "GO:1902554",
                "GO:1902562",
                "GO:1902911",
                "GO:1903293",
                "GO:1990104",
                "GO:1990204",
                "GO:1990234",
                "GO:1990342",
                "GO:1990351",
                "GO:1990391"
            ]
        },
        "rnacentral": {
            "export_so_ids": [
                "SO:0000252",
                "SO:0000253",
                "SO:0000274",
                "SO:0000275",
                "SO:0000655",
                "SO:0001877",
                "SO:0002247"
            ]
        },
        "annotation_subsets": [
            {
                "term_ids": [
                    "FYPO:0002683"
                ],
                "file_name": "increased_sensitivity_to_chemical.tsv",
                "single_or_multi_locus": "single",
                "columns": [
                    {
                        "name": "gene_uniquename",
                        "display_name": "Systematic ID"
                    },
                    {
                        "name": "gene_name",
                        "display_name": "Gene name"
                    },
                    {
                        "name": "allele",
                        "display_name": "Allele"
                    },
                    {
                        "name": "termid",
                        "display_name": "Term ID"
                    },
                    {
                        "name": "term_name",
                        "display_name": "Term name"
                    }
                ]
            },
            {
                "term_ids": [
                    "FYPO:0002682"
                ],
                "file_name": "increased_resistance_to_chemical.tsv",
                "single_or_multi_locus": "single",
                "columns": [
                    {
                        "name": "gene_uniquename",
                        "display_name": "Systematic ID"
                    },
                    {
                        "name": "gene_name",
                        "display_name": "Gene name"
                    },
                    {
                        "name": "allele",
                        "display_name": "Allele"
                    },
                    {
                        "name": "termid",
                        "display_name": "Term ID"
                    },
                    {
                        "name": "term_name",
                        "display_name": "Term name"
                    }
                ]
            }
        ],
        "nd_reference": "GO_REF:0000015",
        "gpad_gpi": {
            "go_aspect_terms": {
                "molecular_function": "GO:0003674",
                "cellular_component": "GO:0005575",
                "biological_process": "GO:0008150"
            },
            "extension_relation_mappings": {
                "not_happens_during": null,
                "not_exists_during": null,
                "activated_by": null,
                "exists_during": "RO:0002490",
                "regulates_activity_of": "RO:0002233",
                "directly_negatively_regulates": "RO:0002233",
                "directly_positively_regulates": "RO:0002233",
                "regulates_expression_of": "RO:0002233",
                "regulates_transcription_of": "RO:0002233",
                "regulates_translation_of": "RO:0002233",
                "occurs_at": "BFO:0000066",
                "coincident_with": "RO:0002008",
                "has_input": "RO:0002233"
            },
            "transcript_gene_so_term_map": {
                "SO:0000234": "SO:0001217",
                "SO:0000516": "SO:0000336",
                "SO:0000252": "SO:0001637",
                "SO:0000253": "SO:0001272",
                "SO:0000274": "SO:0001268",
                "SO:0000275": "SO:0001267",
                "SO:0000655": "SO:0001263",
                "SO:0001877": "SO:0001641",
                "SO:0002247": "SO:0002342",
                "SO:0000602": null,
                "SO:0000644": null
            }
        },
        "phaf_cv_name": "single_locus_phenotype",
        "phaf_parental_strain": {
            "4896": "972 h-"
        }
    },
    "term_display_names": {
        "regional_centromere": "centromere",
        "regional_centromere_central_core": "centromere central core"
    },
    "protein_feature_db_order": [
        "PFAM",
        "CDD",
        "PROSITE",
        "SMART",
        "PRINTS",
        "FUNFAM",
        "SUPERFAMILY",
        "GENE3D",
        "PANTHER",
        "PIRSF",
        "OTHER"
    ],
    "protein_feature_table": {
        "feature_types": {
            "PRINTS": {
                "min_max_only": true
            }
        }
    },
    "protein_feature_view": {
        "modification_extension_rel_types": [
            "added_during", "added_by", "affected_by", "removed_by",
            "present_during", "increased_during", "level_fluctuates_during",
            "increased_in_presence_of"
        ],
        "widget_tracks": [
            "AA substitution positions",
            "Modifications",
            "Glycosylation sites",
            "Pfam domains",
            "TM domains",
            "Disordered regions",
            "Low complexity",
            "Coiled coils",
            "Disulfide bonds",
            "Binding sites",
            "Active sites",
            "Localization signals",
            "Propeptides",
            "Cleavage sites"
        ],
        "domains_and_features_tracks": [
            "Binding sites",
            "Active sites",
            "Conserved motifs",
            "MOBIDB-Disorder",
            "MOBIDB-Glycine-rich",
            "MOBIDB-Negative-Polyelectrolyte",
            "MOBIDB-Polar",
            "MOBIDB-Polyampholyte",
            "MOBIDB-Positive-Polyelectrolyte",
            "MOBIDB-Proline-rich",
            "CDD",
            "FUNFAM",
            "GENE3D",
            "HAMAP",
            "NCBIFAM",
            "PANTHER",
            "PFAM",
            "PIRSF",
            "PRINTS",
            "PROSITE_PATTERNS",
            "PROSITE_PROFILES",
            "SFLD",
            "SMART",
            "SUPERFAMILY",
            "Localization signals",
            "TM domains",
            "Disordered regions",
            "Low complexity",
            "Coiled coils"
        ],
        "modification_section_tracks": [
            "Modifications",
            "Disulfide bonds"
        ],
        "modification_groups": [
            {
                "termid": "MOD:01875",
                "name": "N6-acylated L-lysine"
            },
            {
                "termid": "MOD:01155",
                "name": "lipoconjugated residue"
            },
            {
                "termid": "MOD:00408",
                "name": "N-acetylated residue"
            },
            {
                "termid": "MOD:00696",
                "name": "phosphorylated residue"
            },
            {
                "termid": "MOD:00427",
                "name": "methylated residue"
            },
            {
                "termid": "MOD:00764",
                "name": "glycoconjugated residue"
            },
            {
                "termid": "MOD:00905",
                "name": "modified L-cysteine residue"
            }
        ],
        "full_display_excluded": [
            "MOBIDB-Low-complexity",
            "Pfam domains"
        ]
    },
    "gene_expression": {
        "max_gene_ex_plot_genes": 250,
        "datasets": [
            {
                "name": "G1 phase - protein (Carpy)",
                "plot_display_name": "G1 phase\n(Carpy)",
                "first_author": "Carpy",
                "pubmed_id": "PMID:24763107",
                "level_type": "protein level",
                "level_type_termid": "PBO:0006310",
                "scale": "single_cell",
                "during": "mitotic G1 phase",
                "during_termid": "GO:0000080"
            },
            {
                "name": "G2 phase - protein (Carpy)",
                "plot_display_name": "G2 phase\n(Carpy)",
                "first_author": "Carpy",
                "pubmed_id": "PMID:24763107",
                "level_type": "protein level",
                "level_type_termid": "PBO:0006310",
                "scale": "single_cell",
                "during": "mitotic G2 phase",
                "during_termid": "GO:0000085"
            },
            {
                "name": "M phase - protein (Carpy)",
                "plot_display_name": "M phase\n(Carpy)",
                "first_author": "Carpy",
                "pubmed_id": "PMID:24763107",
                "level_type": "protein level",
                "level_type_termid": "PBO:0006310",
                "scale": "single_cell",
                "during": "mitotic M phase",
                "during_termid": "GO:0000087"
            },
            {
                "name": "S phase - protein (Carpy)",
                "plot_display_name": "S phase\n(Carpy)",
                "first_author": "Carpy",
                "pubmed_id": "PMID:24763107",
                "level_type": "protein level",
                "level_type_termid": "PBO:0006310",
                "scale": "single_cell",
                "during": "mitotic S phase",
                "during_termid": "GO:0000084"
            },
            {
                "name": "veg. growth - protein (Carpy)",
                "plot_display_name": "veg. growth\n(Carpy)",
                "first_author": "Carpy",
                "pubmed_id": "PMID:24763107",
                "level_type": "protein level",
                "level_type_termid": "PBO:0006310",
                "scale": "single_cell",
                "during": "single-celled organism vegetative growth phase",
                "during_termid": "GO:0072690"
            },
            {
                "name": "G1 quiescence - protein (Marguerat)",
                "plot_display_name": "G1 quiescence\n(Marguerat)",
                "first_author": "marguerat",
                "pubmed_id": "PMID:23101633",
                "level_type": "protein level",
                "level_type_termid": "PBO:0006310",
                "scale": "population_wide",
                "during": "cell quiescence following G1 arrest due to nitrogen limitation",
                "during_termid": "PBQ:0000003"
            },
            {
                "name": "veg. growth - protein (Marguerat)",
                "plot_display_name": "veg. growth\n(Marguerat)",
                "first_author": "Marguerat",
                "pubmed_id": "PMID:23101633",
                "level_type": "protein level",
                "level_type_termid": "PBO:0006310",
                "scale": "population_wide",
                "during": "single-celled organism vegetative growth phase",
                "during_termid": "GO:0072690"
            },
            {
                "name": "G1 quiescence - RNA (Marguerat)",
                "plot_display_name": "G1 quiescence\n(Marguerat)",
                "first_author": "Marguerat",
                "pubmed_id": "PMID:23101633",
                "level_type": "RNA level",
                "level_type_termid": "PBO:0011963",
                "scale": "population_wide",
                "during": "cell quiescence following G1 arrest due to nitrogen limitation",
                "during_termid": "PBQ:0000003"
            },
            {
                "name": "veg. growth - RNA (Marguerat)",
                "plot_display_name": "veg. growth\n(Marguerat)",
                "first_author": "Marguerat",
                "pubmed_id": "PMID:23101633",
                "level_type": "RNA level",
                "level_type_termid": "PBO:0011963",
                "scale": "population_wide",
                "during": "single-celled organism vegetative growth phase",
                "during_termid": "GO:0072690"
            }
        ]
    },
    "stats": {
        "curated_vs_curatable_min_year": 2009,
        "annotation_type_counts_min_year": 2005,
        "annotation_type_groups": [
            {
                "display_name": "GO",
                "cv_names": ["molecular_function", "biological_process", "cellular_component"]
            },
            {   "display_name": "Phenotype",
                "cv_names": ["fission_yeast_phenotype"]
            },
            {
                "display_name": "Modifications",
                "cv_names": ["PSI-MOD"]
            },
            {
                "display_name": "Gene expression",
                "cv_names": ["gene_ex", "PomGeneExProt", "PomGeneExRD", "PomGeneExRNA"]
            },
            {
                "display_name": "Genetic interactions",
                "cv_names": ["interacts_genetically"]
            },
            {
                "display_name": "Physical interactions",
                "cv_names": ["interacts_physically"]
            },
            {
                "display_name": "Orthologs",
                "cv_names": ["orthologous_to"]
            },
            {
                "display_name": "Other",
                "cv_names": [
                    "pombase_family_or_domain", "complementation", "EC numbers",
                    "PomBase gene characterisation status", "external_link",
                    "PomBase gene products", "ex_tools", "genome_org",
                    "m_f_g", "misc", "mondo",
                    "name_description", "sequence", "species_dist", "warning",
                    "cat_act", "subunit_composition"]
            }
        ]
    },
    "apicuron": {
        "resource_id": "pombase"
    }
}
