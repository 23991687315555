<div class="front-container">
<app-social-contact></app-social-contact>

<h2 class="welcome-title">Welcome to {{siteName}}</h2>
<div class="welcome">
  <div class="welcome-content">
    <span [innerHTML]="welcomeMessage"></span>
  </div>
  <div *ngIf="showElixirAndGbcLogos" class="welcome-logos">
    <div class="genetics-journal-mod-issue-banner">
      <a href="https://academic.oup.com/genetics/issue/227/1">
        <img src="assets/pombase/genetics_mod_issue_2024_banner.png"/>
      </a>
    </div>
    <div class="gcbr-elixir-logo-row">
    <span class="gcbr-elixir-logo">
      <a href="https://globalbiodata.org/scientific-activities/global-core-biodata-resources/">
        <img src="assets/GCBR-Logo-RGB.svg">
      </a>
    </span>
    <span class="gcbr-elixir-logo">
      <a href="https://elixir-europe.org/platforms/data/core-data-resources">
        <img src="assets/elixir-core-data-resources-logo.png">
      </a>
    </span>
    </div>
  </div>
</div>

<div class="quick-links">
  <span class="quick-links-group">
    <span class="quick-links-title">Tools:</span>
    <span class="quick-links-label">
      <a routerLink="/query">Advanced search</a>
    </span>
    <span class="quick-links-separator">&#x25CF;</span>
    <span class="quick-links-label">
      <a href="/jbrowse/">
        Genome browser
      </a>
    </span>
  </span>
  <span class="quick-links-group">
    <span class="quick-links-line">
      <span class="quick-links-title">Example pages:</span>
      <span class="quick-links-label"><a routerLink="/gene/{{examplePages.gene}}">Gene</a></span>
      <span class="quick-links-separator">&#x25CF;</span>
      <span class="quick-links-label"><a routerLink="/term/{{examplePages.term}}">Term</a></span>
      <span class="quick-links-separator">&#x25CF;</span>
      <span class="quick-links-label"><a routerLink="/reference/{{examplePages.reference}}">Publication</a></span>
    </span>
  </span>
  <span class="quick-links-group">
    <span class="quick-links-line">
      <span class="quick-links-title">Overviews:</span>
      <span class="quick-links-label">
        <a routerLink="/browse-curation/fission-yeast-mf-go-slim-terms">Function</a>
      </span>
      <span class="quick-links-separator">&#x25CF;</span>
      <span class="quick-links-label">
        <a routerLink="/browse-curation/fission-yeast-bp-go-slim-terms">Process</a>
      </span>
      <span class="quick-links-separator">&#x25CF;</span>
      <span class="quick-links-label">
        <a routerLink="/browse-curation/fission-yeast-cc-go-slim-terms">Component</a>
      </span>
      <span *ngIf="hasDiseaseAnnotation" class="quick-links-separator">&#x25CF;</span>
      <span *ngIf="hasDiseaseAnnotation" class="quick-links-label">
        <a routerLink="/browse-curation/disease-slim">Disease association</a>
      </span>

      <span *ngIf="fypoSlim" class="quick-links-separator">&#x25CF;</span>
      <span *ngIf="fypoSlim" class="quick-links-label">
        <a routerLink="{{fypoSlim.full_slim_path}}">Phenotype</a>
      </span>
      <span *ngIf="hasUnknownsList" class="quick-links-separator">&#x25CF;</span>
      <span *ngIf="hasUnknownsList" class="quick-links-label">
        <a routerLink="/status/gene-characterisation">Status/unknowns</a>
      </span>
      <span *ngIf="siteName == 'PomBase'" class="quick-links-separator">&#x25CF;</span>
      <span *ngIf="siteName == 'PomBase'">
        <a routerLink="/documentation/go-cam-pathway-models">GO-CAM pathways</a>
      </span>
    </span>
  </span>
</div>

<div style="clear: both"></div>

<div class="front-callout bs-callout bs-callout-info">
</div>

<div class="panel-container">

  <div *ngIf="showNewsItems" class="front-news-feed">
    <div class="font-news-archive"><a routerLink="/news">News archive ...</a></div>
    <h3 class="front-page-list-title">Recent news</h3>
    <app-recent-news></app-recent-news>
  </div>

  <div class="front-panel info-panel">
    <app-front-panel [conf]="spotlightConf"></app-front-panel>
    <div class="archive-link-container">
    <a routerLink="/archive/spotlight">View more spotlights ...</a>
    </div>
  </div>

  <div class="front-panel info-panel">
    <app-front-panel [conf]="exploreConf"></app-front-panel>
    <div class="archive-link-container">
      <a routerLink="/archive/explore">View more {{siteName}} features ...</a>
    </div>
  </div>

  <div class="front-panel info-panel">
    <app-front-panel [conf]="communityConf"></app-front-panel>
    <div class="archive-link-container">
      <a routerLink="/reference_list/community">All community curation ...</a>
    </div>
    <div *ngIf="hasAdminCuration" class="archive-link-container">
      <a routerLink="/reference_list/admin">{{siteName}} staff curated publications ...</a>
    </div>
  </div>
</div>
</div>

<hr style="clear: both" />

<div class="metadata" *ngIf="metadata">
  <div routerLink="/internal-details">Database created: {{metadata.db_creation_datetime}}</div>
  <div class="pombase-chado-json">Database v{{metadata.date_version}} generated by {{metadata.export_prog_name}} v{{metadata.export_prog_version}}</div>
</div>
