<div *ngIf="getIFrameURL()">
  <div class="header-section">
  <div class="return-to-gene-page">
    <div class="title">GO-CAM pathway model
      <span class="model-title-or-id">
       <app-description-display *ngIf="gocamDetails && gocamDetails.title"
         [description]="gocamDetails!.title"
         [descriptionParts]="titleParts">
       </app-description-display>
       <span *ngIf="(!gocamDetails || !gocamDetails.title) && gocamId">{{gocamId}}</span>
      </span>
      <span *ngIf="sourcePageType != 'docs'" class="go-cam-view">
        <span class="help-link"><a routerLink="/documentation/go-cam-pathway-models">All curated pathways</a></span>
      </span>
    </div>

    <div *ngIf="sourcePageType == 'gene'">
      <a routerLink="/gene/{{sourceId}}">Return to the {{sourceName || sourceId}} gene page</a>
    </div>
    <div *ngIf="sourcePageType == 'term'">
      <a routerLink="/term/{{sourceId}}">Return to the term page for {{sourceName}} ({{sourceId}})</a>
    </div>
    <div *ngIf="sourcePageType == 'docs'">
      <a routerLink="/documentation/go-cam-pathway-models">Return to the "All curated pathways" list</a>
    </div>
  </div>

  <div *ngIf="gocamDetails" class="attribution">
    Curated by: {{contributors()}}
  </div>
  </div>

  <div class="iframe-container">
    <div *ngIf="gocamDetails && modelGenes.length > 0" class="genes-and-terms">
      <div>
        Genes in this model:
        <ul>
          <li *ngFor="let geneSumm of modelGenes">
            <app-gene-link [gene]="geneSumm" [long]="false"></app-gene-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="go-cam-iframe">
      <iframe [src]="getIFrameURL()" class="full-go-cam-view" #gocamiframe>
      </iframe>
    </div>
  </div>
</div>

