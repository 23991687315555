<div id="header-navbar">
  <span class="dropdown btn-group" dropdown>
    <button dropdownToggle class="btn dropdown-toggle">
      Search <span class="caret"></span>
    </button>
    <ul *dropdownMenu class="dropdown-menu" role="menu">
      <li role="menuitem">
        <a routerLink="/query" class="dropdown-item">
          Advanced
        </a>
      </li>
      <li role="menuitem">
        <a href="/jbrowse/" class="dropdown-item">
          Genome browser
        </a>
      </li>
      <li role="menuitem">
        <a routerLink="/query/start_from/gene_list" class="dropdown-item">
          Gene list
        </a>
      </li>
      <li role="menuitem">
        <a routerLink="/identifier-mapper" class="dropdown-item">
          Identifier Mapper
        </a>
      </li>
      <li role="menuitem">
        <a routerLink="/motif_search" class="dropdown-item">
          Peptide motifs
        </a>
      </li>
      <li role="menuitem">
        <a href="{{ensemblBlastURL}}?db=core" class="dropdown-item">
          BLAST at Ensembl
        </a>
      </li>
      <li role="menuitem">
        <a routerLink="/documentation/docsearch" class="dropdown-item">
          Search documentation
        </a>
      </li>
    </ul>
  </span>

  <span class="dropdown btn-group" dropdown>
    <button dropdownToggle class="btn dropdown-toggle">
      Submit <span class="caret"></span>
    </button>
    <ul *dropdownMenu class="dropdown-menu" role="menu">
      <li role="menuitem">
        <a routerLink="/submit-data/gene-names" class="dropdown-item">Gene names</a></li>
      <li role="menuitem">
        <a routerLink="/submit-data" class="dropdown-item">Datasets</a></li>
      <li role="menuitem" *ngIf="cantoURL">
        <a href="{{cantoURL}}" class="dropdown-item">Curate a paper</a></li>
    </ul>
  </span>

  <span *ngFor="let menu of navBarMenus; let idx = index" class="dropdown btn-group" dropdown>
    <button dropdownToggle class="btn dropdown-toggle">
      {{menu.header}} <span class="caret"></span>
    </button>

      <ul *dropdownMenu role="menu"
          [ngClass]="idx + 1 >= navBarMenus.length ? 'dropdown-menu nav-bar-dropdown-menu-right' : 'dropdown-menu'">
        <li *ngFor="let menuEntry of menu.entries" role="menuitem">
          <a *ngIf="entryIsRoute(menuEntry)" routerLink="{{menuEntry.url}}" class="dropdown-item">{{menuEntry.title}}</a>
          <a *ngIf="!entryIsRoute(menuEntry)" href="{{menuEntry.url}}" class="dropdown-item">{{menuEntry.title}}</a>
        </li>
      </ul>

  </span>

  <span class="dropdown btn-group" dropdown placement="bottom right">
    <button dropdownToggle class="btn dropdown-toggle">
      Help <span class="caret"></span>
    </button>

    <ul *dropdownMenu class="dropdown-menu nav-bar-dropdown-menu-right" role="menu">
      <li role="menuitem">
        <a routerLink="/documentation" class="dropdown-item">{{siteName}} documentation</a>
      </li>
      <li role="menuitem" *ngIf="cantoDocumentationURL">
        <a href="{{cantoDocumentationURL}}" class="dropdown-item">Canto documentation</a></li>
      <li role="menuitem">
        <a routerLink="/faq" class="dropdown-item">Frequently Asked Questions (FAQ)</a>
      </li>
      <li role="menuitem">
        <a routerLink="/about" class="dropdown-item">Contact curators</a>
      </li>
    </ul>
  </span>
</div>
