<div>
  <div *ngIf="!results">
    Loading ...
  </div>
  <div *ngIf="results">
    <div *ngIf="results.getRowCount() == 0" class="description">
      <span class="description-prefix">No results for:</span>
      <app-description-display *ngIf="description || descriptionParts.length != 0"
                                     [description]="description" [descriptionParts]="descriptionParts">
      </app-description-display>
    </div>

    <app-genes-table *ngIf="results.getRowCount() != 0"
                     [mode]="mode"
                     [genesOrResults]="results"
                     [description]="description"
                     [descriptionParts]="descriptionParts"></app-genes-table>
  </div>
</div>
